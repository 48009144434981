// MachineLearningResultsRequest.
//
// Class representing a request to retrieve the machine learning result data and algorithm id
export class MachineLearningResultsRequest {
  // PigRun.RunId.
  public runId: number;

  // Constructor.
  public constructor(runId: number) {
    this.runId = runId;
  }
}
