// AddPigRunToolRequest.
//
// Class representing a post to insert a PigRunTool row
export class AddPigRunToolRequest {
  // runId
  public runId: number;

  // toolId
  public toolId: number;

  // Constructor.
  public constructor(runId: number, toolId: number) {
    this.runId = runId;
    this.toolId = toolId;
  }
}
