import MachineLearningResult from '@/models/MachineLearning/MachineLearningResult';

const orange = 'orange';
const light_green = 'green-4';
const light_red = 'red-3';
const dark_green = 'green';
const dark_red = 'red';

export type MachineLearningResultColors =
  | typeof dark_green
  | typeof dark_red
  | typeof orange
  | typeof light_red
  | typeof light_green;

type ReturnValue = {
  color: MachineLearningResultColors;
  confidence: string;
};

// configuraiton: the unsymmetric non-confidence band
const NEG_RED_THRESHOLD = -0.5;
const NEG_ORANGE_THRESHOLD = -0.25;
const POS_ORANGE_THRESHOLD = 0.75;
const POS_GREEN_THRESHOLD = 0.89;

export default (data: MachineLearningResult[]): ReturnValue => {
  let r = 0;

  data.forEach((item) => {
    r += item.weight * (2 * Number(item.prediction) - 1) * item.confidence;
  });

  const mean = r / data.length;
  const confidence = (50 * mean + 50).toFixed(1);

  // are we in the non-confidence band?
  if (NEG_ORANGE_THRESHOLD < mean && mean < POS_ORANGE_THRESHOLD) {
    return {
      color: orange,
      confidence,
    };
  }

  if (mean < 0 && mean > NEG_RED_THRESHOLD) {
    return {
      color: light_red,
      confidence,
    };
  }

  if (mean > 0 && mean < POS_GREEN_THRESHOLD) {
    return {
      color: light_green,
      confidence,
    };
  }

  // are we in the negative or positive band?
  return {
    color: mean < 0 ? dark_red : dark_green,
    confidence,
  };
};
