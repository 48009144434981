
import L from 'leaflet';
import MapCommon from '../utils/MapCommon';
import PigRunCommon from '../utils/PigRunCommon';
import { Device } from '../models/Device';
import { PigPath } from '../models/PigPath';
import { Pig } from '../models/Pig';
import { UserMgmt } from '../models/UserMgmt';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as turf from '@turf/turf';
import * as turfHelpers from '@turf/helpers';
import { AgmMarker } from '../models/AgmMarker';
import SmsNotifications from '@/components/SmsNotifications.vue';
import AutoSettings from '@/components/AutoSettings.vue';
import { UserNotification } from '@/models/UserNotification';
import Papa from 'papaparse';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import PigRunService from '../services/PigRunService';
import { PigPathBroadcastCommand } from '../models/PigPathBroadcastCommand';

@Component({
  components: {
    SmsNotifications,
    AutoSettings,
  },
})
export default class PigRunEditor extends Vue {
  // Broadcast commands section - start

  // Power mode (active).
  public broadcastCommandPowerModeNowEvent = false;
  public broadcastCommandPowerModeLaunchEvent = false;
  public broadcastCommandPowerModeReceiveEvent = false;

  // Power mode (low power).
  public broadcastCommandPowerModeLowPowerNowEvent = false;
  public broadcastCommandPowerModeLowPowerLaunchEvent = false;
  public broadcastCommandPowerModeLowPowerReceiveEvent = false;

  // Request file download state.
  public broadcastCommandRequestFileDownloadNowEvent = false;
  public broadcastCommandRequestFileDownloadLaunchEvent = false;
  public broadcastCommandRequestFileDownloadReceiveEvent = false;

  // Frequency settings (mag only).
  public broadcastCommandFrequencySettingsMagOnlyNowEvent = false;
  public broadcastCommandFrequencySettingsMagOnlyLaunchEvent = false;
  public broadcastCommandFrequencySettingsMagOnlyReceiveEvent = false;

  // Frequency settings (elf and mag).
  public broadcastCommandFrequencySettingsElfMagNowEvent = false;
  public broadcastCommandFrequencySettingsElfMagLaunchEvent = false;
  public broadcastCommandFrequencySettingsElfMagReceiveEvent = false;

  // Frequency settings (elf only).
  public broadcastCommandFrequencySettingsElfOnlyNowEvent = false;
  public broadcastCommandFrequencySettingsElfOnlyLaunchEvent = false;
  public broadcastCommandFrequencySettingsElfOnlyReceiveEvent = false;

  // Format disk.
  public broadcastCommandFormatDiskNowEvent = false;
  public broadcastCommandFormatDiskLaunchEvent = false;
  public broadcastCommandFormatDiskReceiveEvent = false;

  // Broadcast command dialog.
  public displayManageBroadcastCommandsDialog = false;

  // onManageBroadcastCommands.
  //
  // The @click event handler responsible for displaying the broadcast commands dialog.
  public onManageBroadcastCommands() {
    this.displayManageBroadcastCommandsDialog = true;
  }

  // onManageBroadcastCommandsDialogSave.
  //
  // The @click event handler for the broadcast commands dialog save button.
  public async onManageBroadcastCommandsDialogSave() {
    this.displayManageBroadcastCommandsDialog = false;
  }

  // onInputBroadcastCommandFrequencySettingsMagOnly.
  //
  // The @input handler for the checkboxes corresponding to the mag only broadcast command settings.
  public onInputBroadcastCommandFrequencySettingsMagOnly() {
    if (
      this.broadcastCommandFrequencySettingsMagOnlyNowEvent ||
      this.broadcastCommandFrequencySettingsMagOnlyLaunchEvent
    ) {
      // Elf and mag.
      this.broadcastCommandFrequencySettingsElfMagNowEvent = false;
      this.broadcastCommandFrequencySettingsElfMagLaunchEvent = false;

      // Elf only.
      this.broadcastCommandFrequencySettingsElfOnlyNowEvent = false;
      this.broadcastCommandFrequencySettingsElfOnlyLaunchEvent = false;
    }
  }

  // onInputBroadcastCommandFrequencySettingsElfMag.
  //
  // The @input handler for the checkboxes corresponding to the elf and mag broadcast command settings.
  public onInputBroadcastCommandFrequencySettingsElfMag() {
    if (
      this.broadcastCommandFrequencySettingsElfMagNowEvent ||
      this.broadcastCommandFrequencySettingsElfMagLaunchEvent
    ) {
      // Mag only.
      this.broadcastCommandFrequencySettingsMagOnlyNowEvent = false;
      this.broadcastCommandFrequencySettingsMagOnlyLaunchEvent = false;

      // Elf only.
      this.broadcastCommandFrequencySettingsElfOnlyNowEvent = false;
      this.broadcastCommandFrequencySettingsElfOnlyLaunchEvent = false;
    }
  }

  // onInputBroadcastCommandFrequencySettingsElfOnly.
  //
  // The @input handler for the checkboxes corresponding to the elf only broadcast command settings.
  public onInputBroadcastCommandFrequencySettingsElfOnly() {
    if (
      this.broadcastCommandFrequencySettingsElfOnlyNowEvent ||
      this.broadcastCommandFrequencySettingsElfOnlyLaunchEvent
    ) {
      // Mag only.
      this.broadcastCommandFrequencySettingsMagOnlyNowEvent = false;
      this.broadcastCommandFrequencySettingsMagOnlyLaunchEvent = false;

      // Elf and mag.
      this.broadcastCommandFrequencySettingsElfMagNowEvent = false;
      this.broadcastCommandFrequencySettingsElfMagLaunchEvent = false;
    }
  }

  // onInputBroadcastCommandPowerMode.
  //
  // The @input handler for the checkboxes corresponding to the power mode (active) broadcast command.
  public onInputBroadcastCommandPowerMode() {
    console.log(
      'PigRunEditor.vue. onInputBroadcastCommandPowerMode. Enter method.'
    );

    if (this.broadcastCommandPowerModeNowEvent) {
      this.broadcastCommandPowerModeLowPowerNowEvent = false;
    }

    if (this.broadcastCommandPowerModeLaunchEvent) {
      this.broadcastCommandPowerModeLowPowerLaunchEvent = false;
    }
  }

  // onInputBroadcastCommandPowerModeLowPower.
  //
  // The @input handler for the checkboxes corresponding to the power mode (low power) broadcast command.
  public onInputBroadcastCommandPowerModeLowPower() {
    console.log(
      'PigRunEditor.vue. onInputBroadcastCommandPowerModeLowPower. Enter method.'
    );

    if (this.broadcastCommandPowerModeLowPowerNowEvent) {
      this.broadcastCommandPowerModeNowEvent = false;
    }

    if (this.broadcastCommandPowerModeLowPowerLaunchEvent) {
      this.broadcastCommandPowerModeLaunchEvent = false;
    }
  }

  // Broadcast commands section - end

  public Name_Validation = '';
  public Speed_Validation = '';
  public showInstruction = false;
  public csvHasDistance = false;
  public instructionDetail = '';
  public activeTool: string | null = null;
  public lassoSelect: any = null;
  public addNotifierDialog = false;
  public addAgmsDialog = false;
  public addPigDialog = false;
  public addOfflineDialog = false;
  public agmUidOptions: any = [];
  public tmpSelectedAgms: number[] = [];
  public validationMessage = '';
  public pathId = -1;
  public pid = 1; // TODO generated from API???
  public pigRunName = '';
  public expectedSpeed: number | null = 3;
  public pipelineDiameter: number | null = 8;
  public launchSite: L.Marker | null = null;
  public recieveSite: L.Marker | null = null;
  public offlineUid = '';
  public offlineMarker: L.Marker | null = null;
  public offlineAgms: { [key: string]: L.Marker } = {}; // uid: L.marker
  public csvImported = false;
  public csvFile: File | null = null;
  public pigRunOverlayKml: L.Polyline | null = null;
  public pigRunOverlayGenerated: L.Polyline | null = null;
  public csvValidationMessage = '';
  public csvValidationDetails = false;
  public csvValidationDetailData: any = [];
  public kmlSupportingLayers: L.LayerGroup | null = null;
  public agms: number[] = [];
  public agmMarkers: AgmMarker[] = [];
  public agmMapMarkers: L.Marker[] = [];
  public userNotifications: UserNotification[] = [];
  public distanceUnits = 'feet';
  public agmColumns = [
    { name: 'uid', label: 'UID', field: 'uid', sortable: false },
    { name: 'move-up', field: 'id', label: '' }, // changed to btn in slot
    { name: 'move-down', field: 'id', label: '' }, // changed to btn in slot
    { name: 'delete-agm', field: 'id', label: '' }, // changed to btn in slot
  ];

  public driverIdInput: any = null;

  public infinitePagination = {
    rowsPerPage: 0, // 0==infinite
  };

  public pigRunNotes = '';

  // Add Pig Dialog
  public pigName = '';

  public pigs: string[] = [];

  public pigColumns = [
    { name: 'pigName', label: 'Name', field: 'pigName', sortable: false },
    { name: 'delete-pig', field: 'pigName', label: '' }, // changed to btn in slot
  ];

  get pigTable() {
    const table = [];
    for (const p of this.pigs) {
      table.push({ pigName: p });
    }
    return table;
  }

  get agmTable() {
    const values: any = [];
    this.agms.forEach((uid) => values.push({ uid }));
    return values;
  }

  get map() {
    return this.$store.state.map;
  }

  get showPigRunEditor() {
    return this.$store.state.showPigRunEditor;
  }

  get selectedPigPath() {
    return this.$store.state.pigRun.selectedPigPath;
  }

  get devices() {
    return this.$store.state.devices;
  }

  get leafletLayers() {
    return this.$store.state.leafletLayers;
  }

  get layerControl() {
    return this.$store.state.layerControl;
  }

  get deviceUids() {
    return this.$store.getters.deviceUidNumbers;
  }

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    // if (action.type === 'startPigRun') {
    //     console.log('PigRunManager: startPigRun');
    // }
  });

  public onAutoSettings() {
    console.log('PigRunEditor: onAutoSettings');
    this.$q
      .dialog({
        component: AutoSettings,
        parent: this,
        markers: this.agmMarkers,
      })
      .onOk((markers: AgmMarker[]) => {
        console.log('OK');
        this.agmMarkers = markers;
      })
      .onCancel(() => {
        console.log('Cancel');
      })
      .onDismiss(() => {
        console.log('Called on OK or Cancel');
      });
  }

  @Watch('showPigRunEditor', { immediate: false, deep: false })
  public async initEditMode(
    showPigRunEditorCurrentValue: any,
    showPigRunEditorPreviousValue: any
  ) {
    console.log(
      'PigRunEditor.vue. initEditMode. showPigRunEditorPreviousValue = [' +
        showPigRunEditorPreviousValue +
        ']'
    );
    console.log(
      'PigRunEditor.vue. initEditMode. showPigRunEditorCurrentValue = [' +
        showPigRunEditorCurrentValue +
        ']'
    );

    // The showPigRunEditor boolean value is located in the Vuex state store.
    //
    // If the previous value is false and its current value is true. Initialize the state of all the broadcast command
    // checkboxes to false.
    if (
      showPigRunEditorCurrentValue &&
      showPigRunEditorPreviousValue == false
    ) {
      // Power mode (active).
      this.broadcastCommandPowerModeNowEvent = false;
      this.broadcastCommandPowerModeLaunchEvent = false;
      this.broadcastCommandPowerModeReceiveEvent = false;

      // Power mode (low power).
      this.broadcastCommandPowerModeLowPowerNowEvent = false;
      this.broadcastCommandPowerModeLowPowerLaunchEvent = false;
      this.broadcastCommandPowerModeLowPowerReceiveEvent = false;

      // Request file download.
      this.broadcastCommandRequestFileDownloadNowEvent = false;
      this.broadcastCommandRequestFileDownloadLaunchEvent = false;
      this.broadcastCommandRequestFileDownloadReceiveEvent = false;

      // Frequency settings (mag only).
      this.broadcastCommandFrequencySettingsMagOnlyNowEvent = false;
      this.broadcastCommandFrequencySettingsMagOnlyLaunchEvent = false;
      this.broadcastCommandFrequencySettingsMagOnlyReceiveEvent = false;

      // Frequency settings (elf and mag).
      this.broadcastCommandFrequencySettingsElfMagNowEvent = false;
      this.broadcastCommandFrequencySettingsElfMagLaunchEvent = false;
      this.broadcastCommandFrequencySettingsElfMagReceiveEvent = false;

      // Frequency settings (elf only).
      this.broadcastCommandFrequencySettingsElfOnlyNowEvent = false;
      this.broadcastCommandFrequencySettingsElfOnlyLaunchEvent = false;
      this.broadcastCommandFrequencySettingsElfOnlyReceiveEvent = false;

      // Format disk.
      this.broadcastCommandFormatDiskNowEvent = false;
      this.broadcastCommandFormatDiskLaunchEvent = false;
      this.broadcastCommandFormatDiskReceiveEvent = false;
    }

    if (this.showPigRunEditor) {
      this.$store.dispatch('getLocalUsers');
    }

    if (this.showPigRunEditor) {
      if (this.selectedPigPath == null) {
        this.pathId = -1;
        this.pigRunName = '';
        this.csvFile = null;
        this.userNotifications = [];

        if (this.$refs.Name_Validation) {
          (
            this.$refs.Name_Validation as Vue & {
              resetValidation: () => boolean;
            }
          ).resetValidation();
        }
        if (this.$refs.Speed_Validation) {
          (
            this.$refs.Speed_Validation as Vue & {
              resetValidation: () => boolean;
            }
          ).resetValidation();
        }
      } else {
        this.pathId = this.selectedPigPath.pathId;
        this.pigRunName = this.selectedPigPath.pigRunName;
        // @ts-ignore - input converts this back to number, but validation won't fire unless converted to string
        this.expectedSpeed = PigRunCommon.metersToMiles(
          this.selectedPigPath.expectedSpeed
        ).toString(10);
        this.launchSite = L.marker(this.selectedPigPath.launchSiteToLatLng(), {
          icon: PigRunCommon.launchIcon,
        });
        this.launchSite.addTo(this.map);
        this.recieveSite = L.marker(
          this.selectedPigPath.recieveSiteToLatLng(),
          { icon: PigRunCommon.recieveIcon }
        );
        this.recieveSite.addTo(this.map);
        this.pigs = this.selectedPigPath.pigs;
        this.pid = this.selectedPigPath.pigs.length + 2;
        this.pigRunNotes = this.selectedPigPath.notes;
        this.convertOfflineAgmsToMarkers(this.selectedPigPath.offlineAgms);
        this.agms = this.selectedPigPath.agms;

        // Call the PigRunService.getBroadcastCommandDataForPathId to get the broadcast command data for the specified PigPath.PathId.
        //
        // The response is then used to set the state of the broadcast commmand checkboxes for editing.
        await PigRunService.getBroadcastCommandDataForPathId(this.pathId)
          .then((response) => {
            // Power mode (active).
            this.broadcastCommandPowerModeNowEvent =
              response.data.powerModeBroadcastCommandNowEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandPowerModeLaunchEvent =
              response.data.powerModeBroadcastCommandLaunchEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandPowerModeReceiveEvent =
              response.data.powerModeBroadcastCommandReceiveEvent == 'Y'
                ? true
                : false;

            // Power mode (low power).
            this.broadcastCommandPowerModeLowPowerNowEvent =
              response.data.powerModeLowPowerBroadcastCommandNowEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandPowerModeLowPowerLaunchEvent =
              response.data.powerModeLowPowerBroadcastCommandLaunchEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandPowerModeLowPowerReceiveEvent =
              response.data.powerModeLowPowerBroadcastCommandReceiveEvent == 'Y'
                ? true
                : false;

            // Request file download.
            this.broadcastCommandRequestFileDownloadNowEvent =
              response.data.requestFileDownloadBroadcastCommandNowEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandRequestFileDownloadLaunchEvent =
              response.data.requestFileDownloadBroadcastCommandLaunchEvent ==
              'Y'
                ? true
                : false;
            this.broadcastCommandRequestFileDownloadReceiveEvent =
              response.data.requestFileDownloadBroadcastCommandReceiveEvent ==
              'Y'
                ? true
                : false;

            // Frequency settings (mag only).
            this.broadcastCommandFrequencySettingsMagOnlyNowEvent =
              response.data.frequencySettingsMagOnlyBroadcastCommandNowEvent ==
              'Y'
                ? true
                : false;
            this.broadcastCommandFrequencySettingsMagOnlyLaunchEvent =
              response.data
                .frequencySettingsMagOnlyBroadcastCommandLaunchEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandFrequencySettingsMagOnlyReceiveEvent =
              response.data
                .frequencySettingsMagOnlyBroadcastCommandReceiveEvent == 'Y'
                ? true
                : false;

            // Frequency settings (elf and mag).
            this.broadcastCommandFrequencySettingsElfMagNowEvent =
              response.data.frequencySettingsElfMagBroadcastCommandNowEvent ==
              'Y'
                ? true
                : false;
            this.broadcastCommandFrequencySettingsElfMagLaunchEvent =
              response.data
                .frequencySettingsElfMagBroadcastCommandLaunchEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandFrequencySettingsElfMagReceiveEvent =
              response.data
                .frequencySettingsElfMagBroadcastCommandReceiveEvent == 'Y'
                ? true
                : false;

            // Frequency settings (elf only).
            this.broadcastCommandFrequencySettingsElfOnlyNowEvent =
              response.data.frequencySettingsElfOnlyBroadcastCommandNowEvent ==
              'Y'
                ? true
                : false;
            this.broadcastCommandFrequencySettingsElfOnlyLaunchEvent =
              response.data
                .frequencySettingsElfOnlyBroadcastCommandLaunchEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandFrequencySettingsElfOnlyReceiveEvent =
              response.data
                .frequencySettingsElfOnlyBroadcastCommandReceiveEvent == 'Y'
                ? true
                : false;

            // Format disk.
            this.broadcastCommandFormatDiskNowEvent =
              response.data.formatDiskBroadcastCommandNowEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandFormatDiskLaunchEvent =
              response.data.formatDiskBroadcastCommandLaunchEvent == 'Y'
                ? true
                : false;
            this.broadcastCommandFormatDiskReceiveEvent =
              response.data.formatDiskBroadcastCommandReceiveEvent == 'Y'
                ? true
                : false;

            // Business requirement.
            //
            // When a pig path is edited, the checkboxes associated with the now event for all broadcast commands, will always initialize to unchecked regardless
            // of the state of the database.
            this.broadcastCommandPowerModeNowEvent = false;
            this.broadcastCommandPowerModeLowPowerNowEvent = false;
            this.broadcastCommandRequestFileDownloadNowEvent = false;
            this.broadcastCommandFrequencySettingsMagOnlyNowEvent = false;
            this.broadcastCommandFrequencySettingsElfMagNowEvent = false;
            this.broadcastCommandFrequencySettingsElfOnlyNowEvent = false;
            this.broadcastCommandFormatDiskNowEvent = false;
          })
          .catch((exception) => {
            console.log(
              'PigRunEditor.vue. Edit mode. Exception = [' +
                exception.message +
                '].'
            );
          });

        this.agmMarkers = this.selectedPigPath.markers;
        if (this.selectedPigPath.markers) {
          for (const agmMarker of this.selectedPigPath.markers) {
            if (agmMarker.state === 'SKIP') {
              let icon;
              icon = PigRunCommon.skipIcon;
              if (
                Object.prototype.hasOwnProperty.call(agmMarker, 'description')
              ) {
                if (
                  typeof agmMarker.description !== 'undefined' &&
                  agmMarker.description !== 'AGM'
                ) {
                  icon = PigRunCommon.getIcon(agmMarker.description!);
                }
              }
              const marker = L.marker(L.latLng(agmMarker.lat, agmMarker.lon), {
                icon,
              })
                .bindTooltip(agmMarker.name)
                .addTo(this.map);
              this.agmMapMarkers.push(marker);
            } else {
              // TRACK
              const unassignedMarker = L.marker(
                L.latLng(agmMarker.lat, agmMarker.lon),
                { icon: PigRunCommon.getIcon('TRACK') }
              )
                .bindTooltip(agmMarker.name)
                .addTo(this.map);
              this.agmMapMarkers.push(unassignedMarker);
            }
          }
        }
        if (this.selectedPigPath.csvFileName) {
          this.csvImported = true;
          this.csvFile = new File([], this.selectedPigPath.csvFileName);
          this.redrawPigRunPathFromCsv();
        }
        if (this.selectedPigPath.notifications) {
          console.log(this.selectedPigPath.notifications);
          this.userNotifications = this.selectedPigPath.notifications;
        }
      }
    } else {
      this.pathId = -1;
      this.pigRunName = '';
      this.csvFile = null;
      if (this.$refs.Name_Validation) {
        (
          this.$refs.Name_Validation as Vue & { resetValidation: () => boolean }
        ).resetValidation();
      }
      if (this.$refs.Speed_Validation) {
        (
          this.$refs.Speed_Validation as Vue & {
            resetValidation: () => boolean;
          }
        ).resetValidation();
      }
    }
  }

  public onUpdateNotifications(userNotifications: UserNotification[]) {
    console.log('PigRunEditor: onUpdateNotifications');
    this.userNotifications = userNotifications;
  }

  public redrawPigRunPathFromCsv() {
    console.log('redrawPigRunPathFromCsv()');
    const latLngs: L.LatLng[] = [];
    if (this.launchSite) {
      latLngs.push(this.launchSite.getLatLng());
    }
    this.agmMarkers.forEach((agmMarker: AgmMarker) => {
      latLngs.push(L.latLng(agmMarker.lat, agmMarker.lon));
    });
    if (this.recieveSite) {
      latLngs.push(this.recieveSite.getLatLng());
    }
    if (this.pigRunOverlayGenerated) {
      this.pigRunOverlayGenerated.removeFrom(this.map);
      this.pigRunOverlayGenerated = null;
    }

    // needs 2+ points fot a valid line
    if (latLngs.length >= 2) {
      this.pigRunOverlayGenerated = L.polyline(
        latLngs,
        PigRunCommon.pigRunOptions
      );
      this.pigRunOverlayGenerated.addTo(this.map);
    }
  }

  public parseCsv(csvContent: string) {
    // config
    const headerTranslation = new Map();
    headerTranslation.set('AGM Marker Name', 'markerName');
    headerTranslation.set('Latitude', 'lat');
    headerTranslation.set('Longitude', 'lon');
    headerTranslation.set('State', 'state');
    headerTranslation.set('Description', 'description');
    headerTranslation.set('Distance (m)', 'distanceMetres');
    headerTranslation.set('Distance (ft)', 'distanceFeet');

    const requiredFields = ['markerName', 'lat', 'lon', 'state'];
    const validMarkers = [
      'LAUNCH',
      'RECEIVE',
      'AGM',
      'TAP',
      'MAGNET',
      'BEND',
      'WELD',
      'ATTACHMENT',
      'TEE',
      'PIPE',
      'VALVE',
      'OTHER',
    ];
    const validStates = ['TRACK', 'SKIP'];

    // reverse header lookup
    const getByValue = (map: any, searchValue: string) => {
      for (const [key, value] of map.entries()) {
        if (value === searchValue) {
          return key;
        }
      }
    };

    this.csvValidationDetailData = [];
    const markerNames: string[] = [];
    const distances: number[] = [];

    const parseResults: any = Papa.parse(csvContent, {
      delimiter: ',', // auto-detect
      header: true,
      skipEmptyLines: true,
      transformHeader: (header: string) => {
        // note: version 5.5.1 introduced a brealking change so we locked it down to 5.3.2
        // a temporary fix was to use return headerTranslation.get(header) || header;
        return headerTranslation.get(header) || header;
      },
      transform: (value, header) => {
        if (header === 'state') {
          return value.toUpperCase();
        }
        if (header === 'description') {
          return value.toUpperCase();
        }
        // capture marker names for duplicate comparison
        if (header === 'markerName') {
          markerNames.push(value);
        }
        return value;
      },
      dynamicTyping: (field) => {
        if (field === 'markerName') {
          return false;
        }
        return true;
      },
    });

    // Parse Results
    const parseResultsHeaders = parseResults.meta.fields;
    // console.log(markerNames);

    // validate next
    // 1. present formatting errors detected by papa parse first
    if (parseResults.errors.length) {
      this.csvValidationMessage = 'Unsupported CSV';
      this.csvValidationDetailData = parseResults.errors;
      return [];
    }

    // 2. make sure required fields are present
    const fieldsPresent = requiredFields.filter((value) =>
      parseResultsHeaders.includes(value)
    );
    const missingFields = requiredFields.filter(
      (x) => !fieldsPresent.includes(x)
    );
    if (missingFields.length) {
      this.csvValidationMessage = 'CSV missing required field(s)';
      missingFields.forEach((field) => {
        this.csvValidationDetailData.push({
          row: -1,
          message:
            'Missing required field: ' + getByValue(headerTranslation, field),
        });
      });
      return [];
    }

    // 3. now loop through parsed data
    for (const [index, csvRow] of parseResults.data.entries()) {
      // console.log(csvRow, index);

      // AGM Marker Name
      if (csvRow.markerName.length > 20 || csvRow.markerName.length === 0) {
        this.csvValidationMessage = 'Unsupported Marker Name';
        this.csvValidationDetailData.push({
          row: index,
          message: 'AGM Marker Name must have between 1 and 20 characters',
        });
        return [];
      }
      const duplicateMarkerNames = markerNames.filter(
        (markerName) => markerName === csvRow.markerName
      );
      if (duplicateMarkerNames.length > 1) {
        this.csvValidationMessage = 'AGM Marker Names not unique';
        this.csvValidationDetailData.push({
          row: index,
          message: 'Duplicate marker name: ' + duplicateMarkerNames[0],
        });
        return [];
      }

      // Lat-Long
      if (csvRow.lat == null) {
        this.csvValidationMessage = 'Invalid Latitude';
        this.csvValidationDetailData.push({
          row: index,
          message:
            'Latitude field is Empty at Marker name ' +
            '"' +
            csvRow.markerName +
            '"',
        });
        return [];
      }

      if (csvRow.lat > 90 || csvRow.lat < -90 || isNaN(csvRow.lat)) {
        this.csvValidationMessage = 'Invalid Latitude';
        this.csvValidationDetailData.push({
          row: index,
          message:
            'Latitude ' +
            csvRow.lat.toString() +
            ' is not between 90.0 and -90.0 at Marker Name ' +
            '"' +
            csvRow.markerName +
            '"',
        });
        return [];
      }

      if (csvRow.lon == null) {
        this.csvValidationMessage = 'Invalid Longitude';
        this.csvValidationDetailData.push({
          row: index,
          message:
            'Longitude field is Empty at Marker name ' +
            '"' +
            csvRow.markerName +
            '"',
        });
        return [];
      }

      if (csvRow.lon > 180 || csvRow.lon < -180 || isNaN(csvRow.lon)) {
        this.csvValidationMessage = 'Invalid Longitude';
        this.csvValidationDetailData.push({
          row: index,
          message:
            'Longitude ' +
            csvRow.lon.toString() +
            ' is not between 180.0 and -180.0 at Marker Name ' +
            '"' +
            csvRow.markerName +
            '"',
        });
        return [];
      }

      // Optional Distance Field
      // Feet
      if (Object.prototype.hasOwnProperty.call(csvRow, 'distanceFeet')) {
        // console.log('Distance Feet column present in CSV');
        if (csvRow.distanceFeet !== null) {
          // console.log('distance feet exist' + csvRow.distanceFeet.toString());
          // Convert to metres and set property
          csvRow.distanceMetres = csvRow.distanceFeet / 3.2808;
        }
      }

      // Meters
      if (Object.prototype.hasOwnProperty.call(csvRow, 'distanceMetres')) {
        // console.log('Distance Meters column present in CSV');
        if (csvRow.distanceMetres !== null) {
          // console.log('distance meters exist' + csvRow.distanceMetres.toString());
          distances.push(csvRow.distanceMetres);
        }
      }

      // Optional Description Field
      if (Object.prototype.hasOwnProperty.call(csvRow, 'description')) {
        // console.log('Description column present in CSV');
        if (csvRow.description == null) {
          if (index == 0) {
            csvRow.description = 'Launch';
          } else if (index == parseResults.data.length - 1) {
            csvRow.description = 'Receive';
          } else {
            csvRow.description = 'AGM';
          }
        } else {
          const isMarkerValid = validMarkers.includes(csvRow.description);
          if (isMarkerValid == false) {
            this.csvValidationMessage = 'Invalid Marker Description';
            this.csvValidationDetailData.push({
              row: index,
              message:
                'Description field error at Marker name ' +
                '"' +
                csvRow.markerName +
                '"',
            });
            return [];
          }
        }
      }

      if (Object.prototype.hasOwnProperty.call(csvRow, 'state')) {
        // console.log('Description column present in CSV');
        if (csvRow.state == null) {
          if (index == 0 || index == parseResults.data.length - 1) {
            csvRow.description = 'SKIP';
          } else {
            csvRow.description = 'TRACK';
          }
        } else {
          const isMarkerValid = validStates.includes(csvRow.state);

          if (isMarkerValid == false) {
            this.csvValidationMessage = 'Invalid Marker State';
            this.csvValidationDetailData.push({
              row: index,
              message:
                'State field error at Marker name ' +
                '"' +
                csvRow.markerName +
                '"',
            });
            return [];
          }
        }
      }
    }

    // if distances exist, validate that count matches markers count
    // console.log(distances);
    if (distances.length) {
      if (distances.length !== markerNames.length) {
        this.csvValidationMessage = 'Missing distance value(s)';
        this.csvValidationDetailData.push({
          row: -2,
          message:
            '# of Distance values provided (' +
            distances.length.toString() +
            ') must match AGM Marker Count (' +
            markerNames.length.toString() +
            ')',
        });
        return [];
      }
    }

    console.log(parseResults.data);
    return parseResults.data;
  }

  public onCsvFileAdded() {
    console.log('onCsvFileAdded');

    if (this.csvFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (reader.result) {
          const parsedCsvContent = this.parseCsv(reader.result as string);

          if (parsedCsvContent && parsedCsvContent.length) {
            this.csvImported = true;

            parsedCsvContent[0].state = 'SKIP'; // force skip at start
            const startCsv = parsedCsvContent[0];
            this.launchSite = L.marker(L.latLng(startCsv.lat, startCsv.lon), {
              icon: PigRunCommon.getIcon('LAUNCH'),
            });
            this.launchSite.bindTooltip(startCsv.markerName);
            this.launchSite.addTo(this.map);

            parsedCsvContent[parsedCsvContent.length - 1].state = 'SKIP'; // force skip at end
            const endCsv = parsedCsvContent[parsedCsvContent.length - 1];
            this.recieveSite = L.marker(L.latLng(endCsv.lat, endCsv.lon), {
              icon: PigRunCommon.getIcon('RECEIVE'),
            });
            this.recieveSite.bindTooltip(endCsv.markerName);
            this.recieveSite.addTo(this.map);

            const latLngs: L.LatLng[] = [];
            if (this.launchSite) {
              latLngs.push(this.launchSite.getLatLng());
            }
            for (let i = 0; i < parsedCsvContent.length - 1; i++) {
              if (i === 0) {
                continue;
              }

              this.agmMarkers.push({
                name: parsedCsvContent[i].markerName,
                lat: parsedCsvContent[i].lat,
                lon: parsedCsvContent[i].lon,
                state: parsedCsvContent[i].state,
                distanceMetres: parsedCsvContent[i].distanceMetres,
                description: parsedCsvContent[i].description,
                autoLink: false,
                autoVerify: false,
              } as AgmMarker);

              // show these markers on the map
              if (parsedCsvContent[i].state === 'SKIP') {
                let icon;
                icon = PigRunCommon.skipIcon;
                if (
                  Object.prototype.hasOwnProperty.call(
                    parsedCsvContent[i],
                    'description'
                  )
                ) {
                  if (parsedCsvContent[i].description !== 'AGM') {
                    icon = PigRunCommon.getIcon(
                      parsedCsvContent[i].description
                    );
                  }
                }
                const marker = L.marker(
                  L.latLng(parsedCsvContent[i].lat, parsedCsvContent[i].lon),
                  { icon }
                )
                  .bindTooltip(parsedCsvContent[i].markerName)
                  .addTo(this.map);
                this.agmMapMarkers.push(marker);
              } else {
                // TRACK
                const unassignedMarker = L.marker(
                  L.latLng(parsedCsvContent[i].lat, parsedCsvContent[i].lon),
                  {
                    icon: PigRunCommon.getIcon(parsedCsvContent[i].description),
                  }
                )
                  .bindTooltip(parsedCsvContent[i].markerName)
                  .addTo(this.map);
                this.agmMapMarkers.push(unassignedMarker);
              }

              latLngs.push(
                L.latLng(parsedCsvContent[i].lat, parsedCsvContent[i].lon)
              );
            }
            if (this.recieveSite) {
              latLngs.push(this.recieveSite.getLatLng());
            }

            if (this.pigRunOverlayGenerated) {
              this.pigRunOverlayGenerated.removeFrom(this.map);
            }
            this.pigRunOverlayGenerated = L.polyline(
              latLngs,
              PigRunCommon.pigRunOptions
            );
            this.pigRunOverlayGenerated.addTo(this.map);
            this.map.fitBounds(this.pigRunOverlayGenerated.getBounds());
          }
        }
      };
      reader.readAsText(this.csvFile);
    } else {
      this.onCsvFileRemoved();
    }
  }

  public onCsvFileRemoved() {
    console.log('onCsvFileRemoved');
    this.csvValidationMessage = '';
    this.csvImported = false;
    if (this.launchSite) {
      this.launchSite.removeFrom(this.map);
      this.launchSite = null;
    }
    if (this.recieveSite) {
      this.recieveSite.removeFrom(this.map);
      this.recieveSite = null;
    }
    if (this.pigRunOverlayGenerated) {
      this.pigRunOverlayGenerated.removeFrom(this.map);
      this.pigRunOverlayGenerated = null;
    }
    for (let i = 0; i < this.agmMapMarkers.length; i++) {
      this.agmMapMarkers[i].removeFrom(this.map);
    }
    this.agmMapMarkers = [];
    this.agmMarkers = [];
  }

  public refreshSupportingKmlLayers() {
    console.log('refreshSupportingKmlLayers');
    if (this.pigRunOverlayKml) {
      const newLayers: any[] = [];
      if (this.kmlSupportingLayers) {
        this.kmlSupportingLayers.removeFrom(this.map);
        this.kmlSupportingLayers = null;
      }
      const linestring = turfHelpers.lineString(
        this.pigRunOverlayKml.toGeoJSON().geometry.coordinates as number[][]
      );
      if (this.launchSite) {
        const launchPt = turf.point([
          this.launchSite.getLatLng().lng,
          this.launchSite.getLatLng().lat,
        ]);
        const snapped = turf.nearestPointOnLine(linestring, launchPt, {
          units: 'meters',
        });
        if (snapped && snapped.geometry && snapped.properties) {
          newLayers.push(
            L.marker(
              L.latLng(
                snapped.geometry.coordinates[1],
                snapped.geometry.coordinates[0]
              ),
              { icon: PigRunCommon.kmlSnapMarker }
            )
          );
          newLayers.push(
            L.polyline(
              [
                L.latLng(
                  snapped.geometry.coordinates[1],
                  snapped.geometry.coordinates[0]
                ),
                this.launchSite.getLatLng(),
              ],
              PigRunCommon.kmlSnapLineOptions
            )
          );
        }
      }
      for (const agm of this.agms) {
        let deviceLat;
        let deviceLon;
        if (Object.prototype.hasOwnProperty.call(this.offlineAgms, agm)) {
          console.log('offline agm');
          console.log(this.offlineAgms);
          deviceLat = this.offlineAgms[agm].getLatLng().lat;
          deviceLon = this.offlineAgms[agm].getLatLng().lng;
        } else {
          const deviceInfo = this.devices.filter(
            (d: Device) => agm === d.uid
          )[0];
          if (deviceInfo && deviceInfo.lat && deviceInfo.lon) {
            deviceLat = deviceInfo.lat;
            deviceLon = deviceInfo.lon;
          }
        }
        const agmPt = turf.point([deviceLon, deviceLat]);
        const snapped = turf.nearestPointOnLine(linestring, agmPt, {
          units: 'meters',
        });
        if (snapped && snapped.geometry && snapped.properties) {
          newLayers.push(
            L.marker(
              L.latLng(
                snapped.geometry.coordinates[1],
                snapped.geometry.coordinates[0]
              ),
              { icon: PigRunCommon.kmlSnapMarker }
            )
          );
          newLayers.push(
            L.polyline(
              [
                L.latLng(
                  snapped.geometry.coordinates[1],
                  snapped.geometry.coordinates[0]
                ),
                [deviceLat, deviceLon],
              ],
              PigRunCommon.kmlSnapLineOptions
            )
          );
        }
      }
      if (this.recieveSite) {
        const recievePt = turf.point([
          this.recieveSite.getLatLng().lng,
          this.recieveSite.getLatLng().lat,
        ]);
        const snapped = turf.nearestPointOnLine(linestring, recievePt, {
          units: 'meters',
        });
        if (snapped && snapped.geometry && snapped.properties) {
          newLayers.push(
            L.marker(
              L.latLng(
                snapped.geometry.coordinates[1],
                snapped.geometry.coordinates[0]
              ),
              { icon: PigRunCommon.kmlSnapMarker }
            )
          );
          newLayers.push(
            L.polyline(
              [
                L.latLng(
                  snapped.geometry.coordinates[1],
                  snapped.geometry.coordinates[0]
                ),
                this.recieveSite.getLatLng(),
              ],
              PigRunCommon.kmlSnapLineOptions
            )
          );
        }
      }
      this.kmlSupportingLayers = L.layerGroup(newLayers);
      this.kmlSupportingLayers.addTo(this.map);
    }
  }

  public onAddLaunchSite() {
    console.log('onAddLaunchSite');
    this.activeTool = 'siteSelect';
    this.showInstruction = true;
    this.instructionDetail = 'Click on the map to add pig launch site';
    if (this.launchSite) {
      this.map.removeLayer(this.launchSite);
    }
    this.map.on('click', (e: any) => {
      this.launchSite = L.marker(e.latlng, { icon: PigRunCommon.launchIcon });
      this.launchSite.addTo(this.map);
      this.map.off('click');
      L.DomUtil.removeClass(this.map._container, 'crosshair-cursor-enabled');
      this.showInstruction = false;
    });
    L.DomUtil.addClass(this.map._container, 'crosshair-cursor-enabled');
  }

  public onAddRecieveSite() {
    console.log('onAddRecieveSite');
    this.activeTool = 'siteSelect';
    this.showInstruction = true;
    this.instructionDetail = 'Click on the map to add pig recieve site';
    if (this.recieveSite) {
      this.map.removeLayer(this.recieveSite);
    }
    this.map.on('click', (e: any) => {
      this.recieveSite = L.marker(e.latlng, { icon: PigRunCommon.recieveIcon });
      this.recieveSite.addTo(this.map);
      this.map.off('click');
      L.DomUtil.removeClass(this.map._container, 'crosshair-cursor-enabled');
      this.showInstruction = false;
    });
    L.DomUtil.addClass(this.map._container, 'crosshair-cursor-enabled');
  }

  public offlineClickEvent(e: any) {
    this.offlineMarker = L.marker(e.latlng, { icon: MapCommon.unassignedIcon });
    this.offlineMarker.addTo(this.map);
    this.map.off('click');
    L.DomUtil.removeClass(this.map._container, 'crosshair-cursor-enabled');
    this.showInstruction = false;
    this.addOfflineDialog = true;
  }

  public onAddOfflineAgm() {
    console.log('onAddOfflineAgm');
    this.activeTool = 'siteSelect';
    this.showInstruction = true;
    this.instructionDetail = 'Click on the map to add an offline AGM';
    this.map.on('click', this.offlineClickEvent);
    L.DomUtil.addClass(this.map._container, 'crosshair-cursor-enabled');
  }

  public onAgmSelectMap() {
    console.log('onAgmSelectMap');
    this.activeTool = 'agmSelect';
    this.showInstruction = true;
    this.instructionDetail = 'Draw an area around the AGMs to select';

    this.lassoSelect = L.lasso(this.map, {});
    this.lassoSelect.enable();

    // @ts-ignore - not sure why these types can't be found
    this.map.on('lasso.finished', (event: LassoHandlerFinishedEventData) => {
      console.log(event.layers);
      event.layers.forEach((layer: L.Layer) => {
        const addedUid: number = this.leafletLayers[(layer as any)._leaflet_id];
        if (addedUid) {
          if (!this.agms.includes(addedUid)) {
            this.agms.push(addedUid);
          }
        }
      });
      this.onToolClose();
    });
  }

  public onAgmSelectList() {
    console.log('onAgmSelectList');

    // what is currently selected
    this.tmpSelectedAgms = [...this.agms];

    // populate the available options
    this.agmUidOptions = [];
    this.devices.forEach((device: Device) => {
      this.agmUidOptions.push({
        label: 'AGM UID ' + device.uid,
        value: device.uid,
      });
    });

    this.addAgmsDialog = true;
  }

  public onAgmListToggled() {
    if (this.tmpSelectedAgms && this.tmpSelectedAgms.length === 0) {
      this.tmpSelectedAgms = this.agmUidOptions.map(
        (option: any) => option.value
      );
    } else {
      this.tmpSelectedAgms = [];
    }
  }

  public onAddAllAgms() {
    this.agms = [];
    Object.values(this.leafletLayers).forEach((uid: any) => {
      this.agms.push(uid);
    });
  }

  public onAgmListChanged() {
    // remove changes
    this.agms.forEach((removedUid: number) => {
      if (!this.tmpSelectedAgms.includes(removedUid)) {
        this.agms = this.agms.filter((uid: number) => uid !== removedUid);
      }
    });
    // add changes
    this.tmpSelectedAgms.forEach((changedUid: number) => {
      if (!this.agms.includes(changedUid)) {
        this.agms.push(changedUid);
      }
    });
  }

  public onToolClose() {
    if (this.activeTool) {
      // TODO turn off any event handlers, make sure I got them all
      if (this.activeTool === 'siteSelect') {
        this.map.off('click');
        L.DomUtil.removeClass(this.map._container, 'crosshair-cursor-enabled');
      }
      if (this.activeTool === 'agmSelect') {
        this.lassoSelect.disable();
        this.map.off('lasso.finished');
      }
      this.activeTool = null;
      this.showInstruction = false;
    }
  }

  public onDeleteAgm(props: any) {
    console.log('onDeleteAgm');
    this.agms = this.agms.filter((uid: number) => uid !== props.row.uid);
  }

  public onAddPig() {
    this.addPigDialog = true;
  }

  public onAddPigDetail() {
    this.addPigDialog = false;
    this.pigs.push(this.pigName);

    // reset the addPig form data
    // this.pid++;
    this.pigName = '';
  }

  public onDeletePig(props: any) {
    console.log('onDeletePig');
    this.pigs = this.pigs.filter((pig: any) => pig !== props.row.pigName);
  }

  public onCancelPigRun() {
    this.onResetData();
    this.$store.dispatch('hidePigRunEditor');
  }

  public async onSavePigRun() {
    console.log('PigRunEditor.vue. Enter function onSavePigRun().');

    this.validationMessage = '';

    if (this.pigRunName.length <= 0) {
      this.validationMessage = 'Missing pig run name';

      return;
    }

    if (!this.expectedSpeed) {
      this.validationMessage = 'Missing expected speed';

      return;
    } else if (this.expectedSpeed < 0.1 || this.expectedSpeed > 20) {
      this.validationMessage = 'Expected speed is not between .1 and 20 mph.';

      return;
    }

    // Machine Learning - Pipeline Diamter Validation
    if (!this.pipelineDiameter) {
      this.validationMessage = 'Missing pipeline diameter';

      return;
    } else if (this.pipelineDiameter < 4 || this.pipelineDiameter > 50) {
      this.validationMessage =
        'Pipeline diameter is not between 4 and 50 inches.';

      return;
    }

    // Machine Learning - Sending our pipelineDiameter to the PigRunManager.vue component
    console.log(
      `Sending mutation to set pipelineDiameter ${this.pipelineDiameter} in PigRun Manager.`
    );
    this.$store.commit('SET_PIPELINE_DIAMETER', this.pipelineDiameter);

    // Machine Learning - Sending our expectedSpeed to the PigRunManager.vue component
    console.log(
      `Sending mutation to set expectedSpeed ${this.expectedSpeed} in PigRun Manager.`
    );
    this.$store.commit('SET_EXPECTED_SPEED', this.expectedSpeed);

    if (!this.launchSite) {
      this.validationMessage = 'Missing launch site';

      return;
    }

    if (!this.recieveSite) {
      this.validationMessage = 'Missing receive site';

      return;
    }

    if (this.agms.length === 0 && this.agmMarkers.length === 0) {
      this.validationMessage = 'Need to add at least 1 AGM or Marker';

      return;
    }

    if (!this.pigRunOverlayGenerated) {
      this.validationMessage = 'Missing AGMs or launch/receive sites';

      return;
    }

    if (this.pigs.length === 0) {
      this.validationMessage = 'Need to add at least 1 pig';

      return;
    }

    const newPigPath = new PigPath(
      this.pathId,
      this.pigRunName,
      this.launchSite.getLatLng().lat,
      this.launchSite.getLatLng().lng,
      this.recieveSite.getLatLng().lat,
      this.recieveSite.getLatLng().lng,
      this.agms,
      this.convertOfflineAgmsToLatLng(),
      this.pigs,
      PigRunCommon.milesToMeters(this.expectedSpeed), // stored as m/s, only shown as mph
      this.pigRunOverlayGenerated.toGeoJSON().geometry
        .coordinates as number[][],
      this.pigRunOverlayKml
        ? (this.pigRunOverlayKml.toGeoJSON().geometry.coordinates as number[][])
        : undefined,
      this.pigRunNotes,
      this.agmMarkers,
      this.csvFile ? this.csvFile.name : undefined,
      this.userNotifications ? this.userNotifications : undefined
    );

    this.onResetData();

    // Mitch - broadcast commands modifications (start)
    //
    // At this point, if the newPigPath.pathId value == -1, then this is a new PigPath. Otherwise, an existing PigPath record
    // is being edited.
    let createNewPigPath = false;

    if (newPigPath.pathId == -1) {
      createNewPigPath = true;
    }

    // If creating a new PigPath.
    if (createNewPigPath) {
      // Modify the state of the database.
      await PigRunService.addPigPath(newPigPath)
        .then((response) => {
          // Set the newPigPath.pathId value. This is the PigPath.PathId primary key value generated in the database and returned
          // from the web api.
          newPigPath.pathId = response.data;
        })
        .catch((exception) => {
          console.error(
            'PigRunEditor.vue. onSavePigRun. addPigPath. Create pig path. Exception = [' +
              exception.message +
              '].'
          );
        });

      // At this point a new record has been added to the PigPath table. Next, construct a PigPathBroadcastCommand object to send to the web api
      // containing the associated broadcast command data for this pig path.
      let pigPathBroadcastCommand: PigPathBroadcastCommand =
        new PigPathBroadcastCommand(newPigPath.pathId);

      // Power mode (active).
      pigPathBroadcastCommand.powerModeBroadcastCommandNowEvent = this
        .broadcastCommandPowerModeNowEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.powerModeBroadcastCommandLaunchEvent = this
        .broadcastCommandPowerModeLaunchEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.powerModeBroadcastCommandReceiveEvent = this
        .broadcastCommandPowerModeReceiveEvent
        ? 'Y'
        : 'N';

      // Power mode (low power).
      pigPathBroadcastCommand.powerModeLowPowerBroadcastCommandNowEvent = this
        .broadcastCommandPowerModeLowPowerNowEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.powerModeLowPowerBroadcastCommandLaunchEvent =
        this.broadcastCommandPowerModeLowPowerLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.powerModeLowPowerBroadcastCommandReceiveEvent =
        this.broadcastCommandPowerModeLowPowerReceiveEvent ? 'Y' : 'N';

      // Request file download.
      pigPathBroadcastCommand.requestFileDownloadBroadcastCommandNowEvent = this
        .broadcastCommandRequestFileDownloadNowEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.requestFileDownloadBroadcastCommandLaunchEvent =
        this.broadcastCommandRequestFileDownloadLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.requestFileDownloadBroadcastCommandReceiveEvent =
        this.broadcastCommandRequestFileDownloadReceiveEvent ? 'Y' : 'N';

      // Frequency settings (mag only).
      pigPathBroadcastCommand.frequencySettingsMagOnlyBroadcastCommandNowEvent =
        this.broadcastCommandFrequencySettingsMagOnlyNowEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsMagOnlyBroadcastCommandLaunchEvent =
        this.broadcastCommandFrequencySettingsMagOnlyLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsMagOnlyBroadcastCommandReceiveEvent =
        this.broadcastCommandFrequencySettingsMagOnlyReceiveEvent ? 'Y' : 'N';

      // Frequency settings (elf and mag).
      pigPathBroadcastCommand.frequencySettingsElfMagBroadcastCommandNowEvent =
        this.broadcastCommandFrequencySettingsElfMagNowEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsElfMagBroadcastCommandLaunchEvent =
        this.broadcastCommandFrequencySettingsElfMagLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsElfMagBroadcastCommandReceiveEvent =
        this.broadcastCommandFrequencySettingsElfMagReceiveEvent ? 'Y' : 'N';

      // Frequency settings (elf only).
      pigPathBroadcastCommand.frequencySettingsElfOnlyBroadcastCommandNowEvent =
        this.broadcastCommandFrequencySettingsElfOnlyNowEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsElfOnlyBroadcastCommandLaunchEvent =
        this.broadcastCommandFrequencySettingsElfOnlyLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsElfOnlyBroadcastCommandReceiveEvent =
        this.broadcastCommandFrequencySettingsElfOnlyReceiveEvent ? 'Y' : 'N';

      // Format disk.
      pigPathBroadcastCommand.formatDiskBroadcastCommandNowEvent = this
        .broadcastCommandFormatDiskNowEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.formatDiskBroadcastCommandLaunchEvent = this
        .broadcastCommandFormatDiskLaunchEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.formatDiskBroadcastCommandReceiveEvent = this
        .broadcastCommandFormatDiskReceiveEvent
        ? 'Y'
        : 'N';

      // Call the web api to generate the PigPathBroadcastCommand records.
      await PigRunService.updatePigPathBroadcastCommandData(
        pigPathBroadcastCommand
      ).catch((exception) => {
        console.error(
          'PigRunEditor.vue. onSavePigRun. addPigPath. updatePigPathBroadcastCommandData. Exception = [' +
            exception.message +
            '].'
        );
      });

      // Call the web api to generate any required CommandQueue records for the broadcast commands now event.
      await PigRunService.createBroadcastCommandsForNowEventForPathId(
        newPigPath.pathId
      ).catch((exception) => {
        console.error(
          'PigRunEditor.vue. onSavePigRun. addPigPath. createBroadcastCommandsForNowEventForPathId. Exception = [' +
            exception.message +
            '].'
        );
      });

      // Mitch.
      //
      // Rather than dispatching an action, invoke the Vuex state mutation directly.
      this.$store.commit('ADD_PIG_PATH', newPigPath);

      // Modify the Vuex state. Refer to PigRun.ts.
      // await this.$store.dispatch('addPigPathState', newPigPath);
    } else {
      // If editing an existing PigPath.
      //
      // Modify the state of the database.
      //
      // Note: Currently, adding or editing an existing pig path requires calling the addPigPath web api method.
      await PigRunService.addPigPath(newPigPath).catch((exception) => {
        console.error(
          'PigRunEditor.vue. onSavePigRun. addPigPath. Exception = [' +
            exception.message +
            '].'
        );
      });

      // At this point the existing PigPath table record has been updated. Next, construct a PigPathBroadcastCommand object
      // and call the web api to update the PigPathBroadcastCommand records.
      let pigPathBroadcastCommand: PigPathBroadcastCommand =
        new PigPathBroadcastCommand(newPigPath.pathId);

      // Power mode (active).
      pigPathBroadcastCommand.powerModeBroadcastCommandNowEvent = this
        .broadcastCommandPowerModeNowEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.powerModeBroadcastCommandLaunchEvent = this
        .broadcastCommandPowerModeLaunchEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.powerModeBroadcastCommandReceiveEvent = this
        .broadcastCommandPowerModeReceiveEvent
        ? 'Y'
        : 'N';

      // Power mode (low power).
      pigPathBroadcastCommand.powerModeLowPowerBroadcastCommandNowEvent = this
        .broadcastCommandPowerModeLowPowerNowEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.powerModeLowPowerBroadcastCommandLaunchEvent =
        this.broadcastCommandPowerModeLowPowerLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.powerModeLowPowerBroadcastCommandReceiveEvent =
        this.broadcastCommandPowerModeLowPowerReceiveEvent ? 'Y' : 'N';

      // Request file download.
      pigPathBroadcastCommand.requestFileDownloadBroadcastCommandNowEvent = this
        .broadcastCommandRequestFileDownloadNowEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.requestFileDownloadBroadcastCommandLaunchEvent =
        this.broadcastCommandRequestFileDownloadLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.requestFileDownloadBroadcastCommandReceiveEvent =
        this.broadcastCommandRequestFileDownloadReceiveEvent ? 'Y' : 'N';

      // Frequency settings (mag only).
      pigPathBroadcastCommand.frequencySettingsMagOnlyBroadcastCommandNowEvent =
        this.broadcastCommandFrequencySettingsMagOnlyNowEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsMagOnlyBroadcastCommandLaunchEvent =
        this.broadcastCommandFrequencySettingsMagOnlyLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsMagOnlyBroadcastCommandReceiveEvent =
        this.broadcastCommandFrequencySettingsMagOnlyReceiveEvent ? 'Y' : 'N';

      // Frequency settings (elf and mag).
      pigPathBroadcastCommand.frequencySettingsElfMagBroadcastCommandNowEvent =
        this.broadcastCommandFrequencySettingsElfMagNowEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsElfMagBroadcastCommandLaunchEvent =
        this.broadcastCommandFrequencySettingsElfMagLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsElfMagBroadcastCommandReceiveEvent =
        this.broadcastCommandFrequencySettingsElfMagReceiveEvent ? 'Y' : 'N';

      // Frequency settings (elf only).
      pigPathBroadcastCommand.frequencySettingsElfOnlyBroadcastCommandNowEvent =
        this.broadcastCommandFrequencySettingsElfOnlyNowEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsElfOnlyBroadcastCommandLaunchEvent =
        this.broadcastCommandFrequencySettingsElfOnlyLaunchEvent ? 'Y' : 'N';
      pigPathBroadcastCommand.frequencySettingsElfOnlyBroadcastCommandReceiveEvent =
        this.broadcastCommandFrequencySettingsElfOnlyReceiveEvent ? 'Y' : 'N';

      // Format disk.
      pigPathBroadcastCommand.formatDiskBroadcastCommandNowEvent = this
        .broadcastCommandFormatDiskNowEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.formatDiskBroadcastCommandLaunchEvent = this
        .broadcastCommandFormatDiskLaunchEvent
        ? 'Y'
        : 'N';
      pigPathBroadcastCommand.formatDiskBroadcastCommandReceiveEvent = this
        .broadcastCommandFormatDiskReceiveEvent
        ? 'Y'
        : 'N';

      // Call the web api to update the PigPathBroadcastCommand records.
      await PigRunService.updatePigPathBroadcastCommandData(
        pigPathBroadcastCommand
      ).catch((exception) => {
        console.error(
          'PigRunEditor.vue. onSavePigRun. updatePigPathBroadcastCommandData. Exception = [' +
            exception.message +
            '].'
        );
      });

      // Call the web api to generate any required CommandQueue records for the broadcast commands now event.
      await PigRunService.createBroadcastCommandsForNowEventForPathId(
        newPigPath.pathId
      ).catch((exception) => {
        console.error(
          'PigRunEditor.vue. onSavePigRun. createBroadcastCommandForNowEvent. Exception = [' +
            exception.message +
            '].'
        );
      });

      // --------------------------------------------------------------------------------------------------------------------------------------
      // Mitch - The following snippet illustrates invoking a Vuex store mutation directly from a component.
      //
      // A simplified system design for the whole Vue application would involve the following:
      //     - Wrap web api calls into services as they are now in the Vue layer.
      //     - Invoke web api wrapped service calls directly from the *.vue components as required.
      //     - Invoke Vuex store mutations directly from the *.vue components as required to update shared state members. Note that updating
      //       multiple Vuex state members should require a separate store mutation call.
      //     - Eliminate dispatching actions entirely from any *.vue component.
      //
      // Example: Invoke the mutations.
      // this.$store.commit("DELETE_PIG_PATH", newPigPath.pathId);
      // this.$store.commit("ADD_PIG_PATH", newPigPath);
      // --------------------------------------------------------------------------------------------------------------------------------------

      // Modify the Vuex state. Refer to PigRun.ts.
      // await this.$store.dispatch('updatePigPathState', newPigPath);

      // Mitch.
      //
      // Rather than dispatching an action, invoke the Vuex state mutations directly.
      this.$store.commit('DELETE_PIG_PATH', newPigPath.pathId);
      this.$store.commit('ADD_PIG_PATH', newPigPath);
    }

    // The showPigRunManager action dispatched here is responsible for redrawing the display from PigRunEditor.vue to PigRunManager.vue.
    await this.$store.dispatch('showPigRunManager');

    // Mitch - broadcast commands modifications (end)
    //
    // await this.$store.dispatch('upsertPigPath', newPigPath);
    //
    // Mitch - broadcast commands modification
    //
    // Modified the dispatch of the upsertPigPath action to be awaited.
    //
    // Mitch - previous implementation (start)
    // this.$store.dispatch('upsertPigPath', newPigPath);
    // Mitch - previous implementation (end)
  }

  public convertOfflineAgmsToLatLng() {
    // we can't store leaflet objects so convert to UID: [lat, lng]
    const offline: { [key: string]: [number, number] } = {};
    for (const uid in this.offlineAgms) {
      if (Object.prototype.hasOwnProperty.call(this.offlineAgms, uid)) {
        offline[uid] = [
          this.offlineAgms[uid].getLatLng().lat,
          this.offlineAgms[uid].getLatLng().lng,
        ];
      }
    }
    return offline;
  }

  public convertOfflineAgmsToMarkers(offlineAgmLatLng: any) {
    console.log('PigRunEditor: convertOfflineAgmsToMarkers()');
    for (const uid in offlineAgmLatLng) {
      if (Object.prototype.hasOwnProperty.call(offlineAgmLatLng, uid)) {
        // move the agm if it is on the map
        if (this.deviceUids.includes(parseInt(uid, 10))) {
          console.log('moving uid ' + uid);
          this.map.eachLayer((layer: any) => {
            if (Object.prototype.hasOwnProperty.call(layer, '_leaflet_id')) {
              if (this.leafletLayers[layer._leaflet_id] === parseInt(uid, 10)) {
                layer.setLatLng(
                  L.latLng(offlineAgmLatLng[uid][0], offlineAgmLatLng[uid][1])
                );
                this.offlineAgms[uid] = layer;
              }
            }
          });
        } else {
          this.offlineAgms[uid] = L.marker(
            L.latLng(offlineAgmLatLng[uid][0], offlineAgmLatLng[uid][1]),
            { icon: MapCommon.unassignedIcon }
          ).bindPopup('<b>UID</b>: ' + uid);
          this.offlineAgms[uid].addTo(this.map);
        }
      }
    }
  }

  public onResetData() {
    console.log('PigRunEditor: onResetData');
    this.pigRunName = '';
    this.agms = [];
    if (this.launchSite) {
      this.launchSite.removeFrom(this.map);
      this.launchSite = null;
    }
    if (this.recieveSite) {
      this.recieveSite.removeFrom(this.map);
      this.recieveSite = null;
    }
    for (const uid in this.offlineAgms) {
      if (Object.prototype.hasOwnProperty.call(this.offlineAgms, uid)) {
        const deviceInfo = this.devices.find(
          (d: Device) => d.uid === parseInt(uid, 10)
        );
        if (deviceInfo && deviceInfo.lat && deviceInfo.lon) {
          // restore the AGM to its old location
          this.offlineAgms[uid].setLatLng(
            L.latLng(deviceInfo.lat, deviceInfo.lon)
          );
        } else {
          this.offlineAgms[uid].removeFrom(this.map);
        }
      }
    }
    this.offlineAgms = {};
    this.pigs = [];
    this.expectedSpeed = null;
    if (this.pigRunOverlayGenerated) {
      this.pigRunOverlayGenerated.removeFrom(this.map);
      this.pigRunOverlayGenerated = null;
    }
    if (this.pigRunOverlayKml) {
      this.pigRunOverlayKml.removeFrom(this.map);
      this.pigRunOverlayKml = null;
    }
    if (this.kmlSupportingLayers) {
      this.kmlSupportingLayers.removeFrom(this.map);
      this.kmlSupportingLayers = null;
    }
    this.pigRunNotes = '';
    if (this.agmMapMarkers) {
      for (let i = 0; i < this.agmMapMarkers.length; i++) {
        this.agmMapMarkers[i].removeFrom(this.map);
      }
      this.agmMapMarkers = [];
    }
    this.agmMarkers = [];
  }

  public mounted() {
    console.log('PigRunEditor: mounted()');
  }

  public beforeDestroy() {
    this.unsubscribe();
  }
}
