import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { Device } from '../models/Device';
import { AgmMarker } from '../models/AgmMarker';
import { PowerMode } from '../models/PowerMode';
import { RecordMode } from '../models/RecordMode';
import { RecordModeIridium } from '../models/RecordModeIridium';
import { ConnectionSchedule } from '../models/ConnectionSchedule';
import { Passage } from '../models/Passage';
import { MarkerPassage } from '../models/MarkerPassage';
import { User } from '../models/User';
import { DatabaseServerUtcDate } from '../models/DatabaseServerUtcDate';
import DeviceService from '../services/DeviceService';
import PigRun from './modules/PigRun';
import Hubs from './modules/Hubs';
import CommandStore from './modules/CommandStore';
import AdminStore from './modules/AdminStore';
import DeviceStore from './modules/DeviceStore';
import PigRunService from '@/services/PigRunService';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: any) => ({
    // add any states that need to be persisted beyond the current session
    // leftDrawerOpen: state.leftDrawerOpen,
    user: state.user,
    queryParams: state.queryParams,
    pigRun: {
      // pigPaths: state.pigRun.pigPaths,
      // completedPigRuns: state.pigRun.completedPigRuns,
      // activePigRuns: state.pigRun.activePigRuns,
    },
    notifications: state.notifications,
  }),
  // modules: ['pigRun'],
});

export default new Vuex.Store({
  state: {
    // Machine Learning Addons (Start) - Used by EditorMarkerActions.vue
    machineLearningPassageResultList: [],
    // Machine Learning Addons (End)

    // Login
    user: null,
    queryParams: null,

    // LayoutDefault
    leftDrawerOpen: true,

    showDeviceInfo: true,
    showDeviceTable: true,
    showPigRunManager: false,
    showPigRunEditor: false,
    showReportManager: false,
    showBroadcastCommands: false,

    // NotificationSettings - populated on startup from API (if empty)
    notifications: {},
    soundOptions: [
      'None',
      'Standard',
      'Space Dog',
      'Martian',
      'Magic',
      'Oringzz',
      'Bling',
    ],
    soundFiles: [
      '',
      '/notifications/when.mp3',
      '/notifications/out-of-space-dog.mp3',
      '/notifications/martian-gun.mp3',
      '/notifications/just-like-magic.mp3',
      '/notifications/oringz-w468.mp3',
      '/notifications/get-outta-here.mp3',
    ],

    // LeafletMap
    map: null,
    center: null,
    zoom: null,
    layerControl: null,

    // devices
    selectedDevice: {},
    devices: [],
    leafletLayers: {}, // leafletId => uid

    // markers
    selectedMarker: {},
    selectedMarkerEditor: {},
    markerLayers: {},

    // test MulitPigRuns
    ActiveRuns: [],

    // Real Time options
    RealTimeSettings: {
      MagEn: false,
      ELFEn: false,
      GeoEn: false,
      SendToDB: true,
      Freq1: 22,
      PauseTime: 30,
      SoundEn: false,
    },

    GeoSpeedCalcSettings: {
      Enable: false,
      WeldSpace: 20,
      Metric: false,
      NumAvg: 2,
      SourceRaw: true,
    },

    GraphingSettings: {
      ShowRaw: false,
      ShowMAG: true,
      ShowELF: true,
      EMExtents: 'extents',
      RSSIExtents: 'extents',
      GeoExtents: 'extents',
    },

    // signalR updates
    // Note: these device updates are performed here and in the device array
    // they are kept seperate so we don't have to do a deep watch on all devices
    lastDeviceStatus: {},
    lastDeviceLocation: {},

    // historic data
    passageData: [],
    passageDataPagination: {},

    heartbeatData: [],
    heartbeatDataPagination: {},

    deviceInformationResponseData: [],
    deviceInformationResponseDataPagination: {},

    controlSettingsResponseData: [],
    controlSettingsResponseDataPagination: {},

    // deviceInfoData: [],
    // controlResponseData: [],
    gpsFixData: [],
    errorData: [],
    offlineData: [],

    // selected passage (used in charting)
    // is it always 500 pts?
    // 500 pts
    // 32 s
    // 15.625pts/s
    // 0.064s intervals
    selectedPassageTimestamp: '',
    selectedPassageDetectionCount: 0,
    selectedPassageSamplingRate: undefined,
    selectedPassageData: [],
    selectedPassages: [],

    passageDialogSource: 'selected_passage',
    passageIdsToUpdate: [],
    uidToUpdate: 0,
    RTStreamDialog: false,
    databaseServerUtcDate: {},

    // Used by Default.vue to hold the list of active pig run id values for the logged in user that has notification configured.
    activePigRunArrayWithNotification: [],

    // Used by Default.vue to hold the list of active pig run uid values for the logged in user that has notification configured.
    activePigRunUidArrayWithNotification: [],

    // Used by DeviceInfo.vue component.
    cellularCarrierInfoArray: [],

    // The Vuex state member downloadRecordedPassageDataProgressArray: [] is only used by the ReportManager.vue
    // component for downloading recorded passage data.
    downloadRecordedPassageDataProgressArray: [],

    // The Vuex state member diskUseArray: [] is only used by the DeviceInfo.vue component for displaying disk usage.
    diskUseArray: [],

    // The Vuex state members pipelineDiameter, expectedSpeed, and pigRunAddRunId are only used to send data from PigRunEditor.vue to PigRunManager.vue
    pipelineDiameter: 8,
    expectedSpeed: 3,
    pigRunAddRunId: undefined,
  },

  mutations: {
    SET_MACHINE_LEARNING_PASSAGE_RESULT_LIST(state: any, payload) {
      state.machineLearningPassageResultList = payload;
    },
    SET_EM_RAW(state: any, payload) {
      state.GraphingSettings.ShowRaw = payload;
    },
    SET_EM_MAG(state: any, payload) {
      state.GraphingSettings.ShowMAG = payload;
    },
    SET_EM_ELF(state: any, payload) {
      state.GraphingSettings.ShowELF = payload;
    },
    SET_EM_SCALE_EXTENTS(state: any, payload) {
      state.GraphingSettings.EMExtents = payload;
    },
    SET_RSSI_SCALE_EXTENTS(state: any, payload) {
      state.GraphingSettings.RSSIExtents = payload;
    },
    SET_GEO_SCALE_EXTENTS(state: any, payload) {
      state.GraphingSettings.GeoExtents = payload;
    },
    SET_GEO_SPEED_CALC(state: any, payload: boolean) {
      state.GeoSpeedCalcSettings.Enable = payload;
    },
    SET_GEO_WELD_SPACE(state: any, payload: number) {
      state.GeoSpeedCalcSettings.WeldSpace = payload;
    },
    SET_GEO_SPEED_METRIC(state: any, payload: boolean) {
      state.GeoSpeedCalcSettings.Metric = payload;
    },
    SET_GEO_WELD_SOURCE(state: any, payload: boolean) {
      state.GeoSpeedCalcSettings.SourceRaw = payload;
    },
    SET_GEO_SPEED_NUMAVG(state: any, payload: number) {
      state.GeoSpeedCalcSettings.NumAvg = payload;
    },
    SET_RT_MAG_DETECT(state: any, payload: boolean) {
      state.RealTimeSettings.MagEn = payload;
    },
    SET_RT_ELF_DETECT(state: any, payload: boolean) {
      state.RealTimeSettings.ELFEn = payload;
    },
    SET_RT_GEO_DETECT(state: any, payload: boolean) {
      state.RealTimeSettings.GeoEn = payload;
    },
    SET_RT_SEND_TO_DB(state: any, payload: boolean) {
      state.RealTimeSettings.SendToDB = payload;
    },
    SET_RT_PASSAGE_SOUND(state: any, payload: boolean) {
      state.RealTimeSettings.SoundEn = payload;
    },

    SET_RT_PAUSE_TIME(state: any, payload: number) {
      state.RealTimeSettings.PauseTime = payload;
    },
    SET_RT_FREQ1(state: any, payload: number) {
      state.RealTimeSettings.Freq1 = payload;
    },
    SET_DATABASE_SERVER_UTC_DATE(
      state: any,
      payload: DatabaseServerUtcDate | null
    ) {
      state.databaseServerUtcDate = payload;
    },
    DRAWER_TOGGLE(state: any) {
      state.leftDrawerOpen = !state.leftDrawerOpen;
    },
    LOGIN(state: any, user: User | null) {
      state.user = user;
    },
    SET_QUERY_PARAMS(state: any, params: any) {
      state.queryParams = params;
    },
    SET_SELECTED_DEVICE(state: any, device: Device) {
      state.selectedDevice = device;
    },
    SET_SELECTED_MARKER(state: any, marker: AgmMarker) {
      state.selectedMarker = marker;
    },
    SET_SELECTED_MARKER_Editor(state: any, marker: AgmMarker) {
      state.selectedMarkerEditor = marker;
    },
    SET_MARKER_LAYERS(state: any, markerLayers: any) {
      const markerNames = [];
      const markers = [];
      let prev_exist = false;
      let Run_index = -1;
      const pigrunid = markerLayers[0].runId;
      const runname = markerLayers[0].pigPath.pigRunName;
      const localmarkerLayers = markerLayers[1];

      for (let i = 0; i < markerLayers[0].markers.length; i++) {
        markerNames.push(markerLayers[0].markers[i].name);
        markers.push(markerLayers[0].markers[i]);
      }

      for (let i = 0; i < state.ActiveRuns.length; i++) {
        if (
          state.ActiveRuns[i].pigrunid == pigrunid &&
          state.ActiveRuns[i].runname == runname
        ) {
          prev_exist = true;
          Run_index = i;
          break;
        }
      }

      if (prev_exist == false) {
        state.ActiveRuns.push({
          pigrunid,
          runname,
          markerNames,
          localmarkerLayers,
          markers,
        });
      } else {
        if (localmarkerLayers == null) {
          let index = -1;

          let i;
          for (i = 0; i < state.ActiveRuns.length; i++) {
            if (state.ActiveRuns[i].pigrunid == pigrunid) {
              index = i;
              break;
            }
          }
          if (i != -1) {
            state.ActiveRuns.splice(index, 1);
          }
        } else {
          // In this case we should update the markerlayers with the newest info
          // Need to update state.ActiveRuns @ index = Run_index
          state.ActiveRuns[Run_index] = {
            pigrunid,
            runname,
            markerNames,
            localmarkerLayers,
            markers,
          };
        }
      }
      // state.markerLayers = markerLayers[1];
    },
    SET_ALL_DEVICES(state: any, devices: Device[]) {
      state.devices = devices;
      // console.log(devices);
    },
    SET_FILTERED_HISTORIC_DATA(state: any, payload: any) {
      // bypass any inital processing
      if (payload.type === 'passage') {
        state.passageData = payload.data;
      }
      if (payload.type === 'heartbeat' || payload.type === 'heatbeat') {
        state.heartbeatData = payload.data;
      }
      if (payload.type === 'deviceInfo') {
        state.deviceInfoData = payload.data;
      }
      if (payload.type === 'controlResponse') {
        state.controlResponseData = payload.data;
      }
      if (payload.type === 'gpsFix') {
        state.gpsFixData = payload.data;
      }
      if (payload.type === 'error') {
        state.errorData = payload.data;
      }
      if (payload.type === 'offline') {
        state.offlineData = payload.data;
      }
    },
    SET_HISTORIC_DATA(state: any, payload: any) {
      // The payload data structure has the following properties:
      //     type
      //     data
      //     page
      //     rowsPerPage
      //     sortBy
      //     descending
      if (payload.type === 'passage') {
        // Construct the passageDataPagination object
        const passageDataPagination: any = {
          descending: payload.descending,
          page: payload.page,
          rowsNumber: payload.data.rowsNumber,
          rowsPerPage: payload.rowsPerPage,
          sortBy: payload.sortBy,
        };

        // Set the passageDataPagination state member.
        state.passageDataPagination = passageDataPagination;

        // Refer to ProPipe.WebApi.Models.PassageEvent.
        //
        // Each object of the payload.data.passageEventList array has the following members:
        //
        // passageId
        // uid
        // passageTimeStamp
        // passageTimeEndOfSnip
        // detectionCount
        // confidence
        // isVerified
        // passageDetectedFrequency
        // packetComplexInformation
        // data
        // isOffline
        // isBluetooth
        // lat
        // lon
        // alt
        // PigRunName
        // ToolName
        // MarkerName
        // RunId
        // samplingRate
        // machineLearningResult
        // machineLearningAlgorithmId
        //
        // Note: Attributes with a value of undefined do not display in the console log.

        // Construct an array of unique passageTimeEndOfSnip values.
        const uniquePassageTimeEndOfSnipArray: string[] = [];

        for (let i = 0; i < payload.data.passageEventList.length; i++) {
          const currentPassageTimeEndOfSnipValue: string =
            payload.data.passageEventList[i].passageTimeEndOfSnip;

          const findIndexResult: number =
            uniquePassageTimeEndOfSnipArray.findIndex(
              (element) => element == currentPassageTimeEndOfSnipValue
            );

          if (findIndexResult == -1) {
            uniquePassageTimeEndOfSnipArray.push(
              currentPassageTimeEndOfSnipValue
            );
          }
        }

        const finalPassageArray: any[] = [];
        let finalPassageArrayElement: any;

        // Iterate over each unique passageTimeEndOfSnip value to construct the final array.
        for (let i = 0; i < uniquePassageTimeEndOfSnipArray.length; i++) {
          finalPassageArrayElement = {
            elfId: undefined,
            geoId: undefined,
            magId: undefined,
            passageId: undefined,
            uid: undefined,
            passageTimeStamp: undefined,
            passageTimeEndOfSnip: undefined,
            elfMachineLearningResultList: undefined,
            geoMachineLearningResultList: undefined,
            magMachineLearningResultList: undefined,
            isVerified: undefined,
            detectionCount: undefined,
            passageDetectedFrequency: undefined,
            isOffline: undefined,
            isBluetooth: undefined,
            alt: undefined,
            lat: undefined,
            lon: undefined,
            PigRunName: undefined,
            ToolName: undefined,
            MarkerName: undefined,
            RunId: undefined,
          };

          // passageTimeEndOfSnip.
          finalPassageArrayElement.passageTimeEndOfSnip =
            uniquePassageTimeEndOfSnipArray[i];

          // Apply a filter to the payload.data.passageEventList array specifying the passageTimeEndOfSnip, and packetComplexInformation values.
          //
          // Elf.
          let filteredPassageEventList = payload.data.passageEventList.filter(
            (element: any) =>
              element.passageTimeEndOfSnip ==
                uniquePassageTimeEndOfSnipArray[i] &&
              element.packetComplexInformation == 0
          );

          // If the filteredPassageEventList has an array element.
          if (filteredPassageEventList.length != 0) {
            // elfMachineLearningResultList.
            finalPassageArrayElement.elfMachineLearningResultList =
              filteredPassageEventList[0].elfMachineLearningResultList;

            // elfId.
            finalPassageArrayElement.elfId =
              filteredPassageEventList[0].passageId;

            // passageTimeStamp.
            finalPassageArrayElement.passageTimeStamp =
              filteredPassageEventList[0].passageTimeStamp;

            // uid.
            finalPassageArrayElement.uid = filteredPassageEventList[0].uid;

            // isVerified.
            finalPassageArrayElement.isVerified =
              filteredPassageEventList[0].isVerified;

            // passageId.
            finalPassageArrayElement.passageId =
              filteredPassageEventList[0].passageId;

            // detectionCount.
            finalPassageArrayElement.detectionCount =
              filteredPassageEventList[0].detectionCount;

            // passageDetectedFrequency.
            finalPassageArrayElement.passageDetectedFrequency =
              filteredPassageEventList[0].passageDetectedFrequency;

            // isOffline & isBluetooth
            if (filteredPassageEventList[0].isOffline !== undefined) {
              if (filteredPassageEventList[0].isOffline) {
                finalPassageArrayElement.isOffline = 'Offline';
              } else {
                finalPassageArrayElement.isOffline = 'Online';
              }
            }
            if (
              filteredPassageEventList[0].isBluetooth !== undefined &&
              filteredPassageEventList[0].isBluetooth
            ) {
              finalPassageArrayElement.isOffline = 'Bluetooth';
            }

            // alt. lat. lon.
            finalPassageArrayElement.alt = filteredPassageEventList[0].alt;
            finalPassageArrayElement.lat = filteredPassageEventList[0].lat;
            finalPassageArrayElement.lon = filteredPassageEventList[0].lon;

            // PigRunName. ToolName. MarkerName. RunId
            finalPassageArrayElement.PigRunName =
              filteredPassageEventList[0].PigRunName;
            finalPassageArrayElement.ToolName =
              filteredPassageEventList[0].ToolName;
            finalPassageArrayElement.MarkerName =
              filteredPassageEventList[0].MarkerName;
            finalPassageArrayElement.RunId = filteredPassageEventList[0].RunId;
          }

          // Apply a filter to the payload.data.passageEventList array specifying the passageTimeEndOfSnip, and packetComplexInformation values.
          //
          // Geo.
          filteredPassageEventList = payload.data.passageEventList.filter(
            (element: any) =>
              element.passageTimeEndOfSnip ==
                uniquePassageTimeEndOfSnipArray[i] &&
              element.packetComplexInformation == 1
          );

          // If the filteredPassageEventList has an array element.
          if (filteredPassageEventList.length != 0) {
            // geoMachineLearningResultList.
            finalPassageArrayElement.geoMachineLearningResultList =
              filteredPassageEventList[0].geoMachineLearningResultList;

            // geoId.
            finalPassageArrayElement.geoId =
              filteredPassageEventList[0].passageId;

            // passageTimeStamp.
            finalPassageArrayElement.passageTimeStamp =
              filteredPassageEventList[0].passageTimeStamp;

            // uid.
            finalPassageArrayElement.uid = filteredPassageEventList[0].uid;

            // isVerified.
            finalPassageArrayElement.isVerified =
              filteredPassageEventList[0].isVerified;

            // passageId.
            finalPassageArrayElement.passageId =
              filteredPassageEventList[0].passageId;

            // detectionCount.
            finalPassageArrayElement.detectionCount =
              filteredPassageEventList[0].detectionCount;

            // passageDetectedFrequency.
            finalPassageArrayElement.passageDetectedFrequency =
              filteredPassageEventList[0].passageDetectedFrequency;

            // isOffline & isBluetooth
            if (filteredPassageEventList[0].isOffline !== undefined) {
              if (filteredPassageEventList[0].isOffline) {
                finalPassageArrayElement.isOffline = 'Offline';
              } else {
                finalPassageArrayElement.isOffline = 'Online';
              }
            }
            if (
              filteredPassageEventList[0].isBluetooth !== undefined &&
              filteredPassageEventList[0].isBluetooth
            ) {
              finalPassageArrayElement.isOffline = 'Bluetooth';
            }

            // alt. lat. lon.
            finalPassageArrayElement.alt = filteredPassageEventList[0].alt;
            finalPassageArrayElement.lat = filteredPassageEventList[0].lat;
            finalPassageArrayElement.lon = filteredPassageEventList[0].lon;

            // PigRunName. ToolName. MarkerName. RunId
            finalPassageArrayElement.PigRunName =
              filteredPassageEventList[0].PigRunName;
            finalPassageArrayElement.ToolName =
              filteredPassageEventList[0].ToolName;
            finalPassageArrayElement.MarkerName =
              filteredPassageEventList[0].MarkerName;
            finalPassageArrayElement.RunId = filteredPassageEventList[0].RunId;
          }

          // Apply a filter to the payload.data.passageEventList array specifying the passageTimeEndOfSnip, and packetComplexInformation values.
          //
          // Mag.
          filteredPassageEventList = payload.data.passageEventList.filter(
            (element: any) =>
              element.passageTimeEndOfSnip ==
                uniquePassageTimeEndOfSnipArray[i] &&
              element.packetComplexInformation == 2
          );

          // If the filteredPassageEventList has an array element.
          if (filteredPassageEventList.length != 0) {
            // magMachineLearningResultList.
            finalPassageArrayElement.magMachineLearningResultList =
              filteredPassageEventList[0].magMachineLearningResultList;

            // magId.
            finalPassageArrayElement.magId =
              filteredPassageEventList[0].passageId;

            // passageTimeStamp.
            finalPassageArrayElement.passageTimeStamp =
              filteredPassageEventList[0].passageTimeStamp;

            // uid.
            finalPassageArrayElement.uid = filteredPassageEventList[0].uid;

            // isVerified.
            finalPassageArrayElement.isVerified =
              filteredPassageEventList[0].isVerified;

            // passageId.
            finalPassageArrayElement.passageId =
              filteredPassageEventList[0].passageId;

            // detectionCount.
            finalPassageArrayElement.detectionCount =
              filteredPassageEventList[0].detectionCount;

            // passageDetectedFrequency.
            finalPassageArrayElement.passageDetectedFrequency =
              filteredPassageEventList[0].passageDetectedFrequency;

            // isOffline & isBluetooth
            if (filteredPassageEventList[0].isOffline !== undefined) {
              if (filteredPassageEventList[0].isOffline) {
                finalPassageArrayElement.isOffline = 'Offline';
              } else {
                finalPassageArrayElement.isOffline = 'Online';
              }
            }
            if (
              filteredPassageEventList[0].isBluetooth !== undefined &&
              filteredPassageEventList[0].isBluetooth
            ) {
              finalPassageArrayElement.isOffline = 'Bluetooth';
            }

            // alt. lat. lon.
            finalPassageArrayElement.alt = filteredPassageEventList[0].alt;
            finalPassageArrayElement.lat = filteredPassageEventList[0].lat;
            finalPassageArrayElement.lon = filteredPassageEventList[0].lon;

            // PigRunName. ToolName. MarkerName. RunId
            finalPassageArrayElement.PigRunName =
              filteredPassageEventList[0].PigRunName;
            finalPassageArrayElement.ToolName =
              filteredPassageEventList[0].ToolName;
            finalPassageArrayElement.MarkerName =
              filteredPassageEventList[0].MarkerName;
            finalPassageArrayElement.RunId = filteredPassageEventList[0].RunId;
          }

          // Add the finalPassageArrayElement to the finalPassageArray.
          finalPassageArray.push(finalPassageArrayElement);
        }

        console.log('SET_HISTORIC_DATA - Before logging finalPassageArray');
        console.log(finalPassageArray);

        // Set the passageData state member.
        state.passageData = finalPassageArray;
      } // end if payload.type === 'passage'

      if (payload.type === 'heartbeat') {
        // Construct the heartbeatDataPagination object.
        const heartbeatDataPagination: any = {
          descending: payload.descending,
          page: payload.page,
          rowsNumber: payload.data.rowsNumber,
          rowsPerPage: payload.rowsPerPage,
          sortBy: payload.sortBy,
        };

        // Set the heartbeatDataPagination state member.
        state.heartbeatDataPagination = heartbeatDataPagination;

        // Set the heartbeatData state member.
        state.heartbeatData = payload.data.heartbeatList;
      }

      if (payload.type === 'deviceInfo') {
        // Construct the deviceInformationResponseDataPagination object.
        const deviceInformationResponseDataPagination: any = {
          descending: payload.descending,
          page: payload.page,
          rowsNumber: payload.data.rowsNumber,
          rowsPerPage: payload.rowsPerPage,
          sortBy: payload.sortBy,
        };

        // Set the deviceInformationResponseDataPagination state member.
        state.deviceInformationResponseDataPagination =
          deviceInformationResponseDataPagination;

        // Set the deviceInformationResponseData state member.
        state.deviceInformationResponseData =
          payload.data.deviceInformationResponseList;
      }

      if (payload.type === 'controlResponse') {
        // Construct the controlSettingsResponseDataPagination object.
        const controlSettingsResponseDataPagination: any = {
          descending: payload.descending,
          page: payload.page,
          rowsNumber: payload.data.rowsNumber,
          rowsPerPage: payload.rowsPerPage,
          sortBy: payload.sortBy,
        };

        // Set the controlSettingsResponseDataPagination state member.
        state.controlSettingsResponseDataPagination =
          controlSettingsResponseDataPagination;

        // Set the controlSettingsResponseData state member.
        state.controlSettingsResponseData =
          payload.data.controlSettingsResponseList;
      }

      if (payload.type === 'gpsFix') {
        state.gpsFixData = payload.data;
      }
      if (payload.type === 'error') {
        state.errorData = payload.data;
      }
      if (payload.type === 'offline') {
        state.offlineData = payload.data;
      }
    },
    CLEAR_HISTORIC_DATA(state: any) {
      // Mitch - commented on January 17, 2022
      // state.passageData = [];
      // state.heartbeatData = [];
      // state.deviceInfoData = [];
      // state.controlResponseData = [];
      // state.gpsFixData = [];
      // state.errorData = [];
      // state.offlineData = [];
    },
    ADD_UID_TO_UPDATE(state: any, uid: number) {
      state.uidToUpdate = uid;
    },
    ADD_PASSAGE_IDS_TO_UPDATE(state: any, passageIds: number[]) {
      state.passageIdsToUpdate = passageIds;
    },
    SET_SELECTED_PASSAGE_DATA(state: any, passageData: number[]) {
      state.selectedPassageData = passageData;
    },
    SET_SELECTED_PASSAGE_DETECTION_COUNT(state: any, detectionCount: any) {
      state.selectedPassageDetectionCount = detectionCount;
    },
    SET_SELECTED_PASSAGE_TIMESTAMP(state: any, timestamp: string) {
      state.selectedPassageTimestamp = timestamp;
    },
    SET_SELECTED_PASSAGE_SAMPLING_RATE(
      state: any,
      samplingRate: number | undefined
    ) {
      state.selectedPassageSamplingRate = samplingRate;
    },
    SET_SELECTED_PASSAGE_END_TIMESTAMP(state: any, timestamp: string) {
      state.selectedPassageEndTimestamp = timestamp;
    },
    SET_DEVICE_PASSAGE_DETECTION_COUNT(state: any, detectionCount: any) {
      state.selectedDevice.lastDetectionCount = detectionCount;
    },
    SET_DEVICE_PASSAGE_TIMESTAMP(state: any, timestamp: string) {
      Vue.set(state.selectedDevice, 'lastPassage', timestamp);
    },
    SET_SELECTED_PASSAGES(state: any, passages: Passage[]) {
      state.selectedPassages = passages;
    },
    SET_PASSAGE_DIALOG_SOURCE(state: any, source: string) {
      state.passageDialogSource = source;
    },
    DELETE_HISTORY(state: any, tab: string) {
      if (tab === 'passage') {
        state.passageData = [];
      }
      if (tab === 'heartbeat' || tab === 'heatbeat') {
        state.heartbeatData = [];
      }
      if (tab === 'deviceInfo') {
        state.deviceInfoData = [];
      }
      if (tab === 'controlResponse') {
        state.controlResponseData = [];
      }
      if (tab === 'gpsFix') {
        state.gpsFixData = [];
      }
      if (tab === 'error') {
        state.errorData = [];
      }
      if (tab === 'offline') {
        state.offlineData = [];
      }
    },
    DELETE_PASSAGES(state: any, passageIds: number[]) {
      state.passageData = state.passageData.filter(
        (el: any) => passageIds.indexOf(el.passageId) === -1
      );
    },
    VERIFY_PASSAGE(state: any, passageId: number) {
      const pIdx = state.passageData.findIndex(
        (el: any) => el.passageId === passageId
      );
      if (pIdx !== -1) {
        state.passageData[pIdx].isVerified =
          !state.passageData[pIdx].isVerified;
      }
    },
    SET_PASSAGE_VERIFICATION(state: any, passage: Passage | MarkerPassage) {
      const pIdx = state.passageData.findIndex(
        (el: any) => el.passageId === passage.passageId
      );
      if (pIdx !== -1) {
        state.passageData[pIdx].isVerified = passage.isVerified;
      }
      // make sure to update in case any other ID is used
      if (passage.geoId) {
        const geoIdx = state.passageData.findIndex(
          (el: any) => el.passageId === passage.geoId
        );
        if (geoIdx !== -1) {
          state.passageData[geoIdx].isVerified = passage.isVerified;
        }
      }
      if (passage.elfId) {
        const elfId = state.passageData.findIndex(
          (el: any) => el.passageId === passage.elfId
        );
        if (elfId !== -1) {
          state.passageData[elfId].isVerified = passage.isVerified;
        }
      }
      if (passage.magId) {
        const magId = state.passageData.findIndex(
          (el: any) => el.passageId === passage.magId
        );
        if (magId !== -1) {
          state.passageData[magId].isVerified = passage.isVerified;
        }
      }
    },
    SET_DEVICE_STREAM_MODE(state: any, streamMode: number | null) {
      state.selectedDevice.lastStreamMode = streamMode;
    },
    SET_DEVICE_CONNECTION_STATUS(state: any, isConnected: boolean) {
      Vue.set(state.selectedDevice, 'isConnected', isConnected);
    },
    SET_DEVICE_AUTODOWNLOAD(state: any, payload: any) {
      const index = state.devices.findIndex(
        (item: Device) => item.uid === payload.uid
      );
      state.devices[index].isAutoDownload = payload.isAutoDownload;
      if (state.selectedDevice && state.selectedDevice.uid === payload.uid) {
        state.selectedDevice.isAutoDownload = payload.isAutoDownload;
      }
    },
    SET_DEVICE_RECORD_MODE(state: any, payload: any) {
      const index = state.devices.findIndex(
        (item: Device) => item.uid === payload.uid
      );
      // isAutoDownload is the correct property, this is coming from wacky payload in a signalR message
      state.devices[index].lastRecordMode = payload.isAutoDownload
        ? RecordMode.Downloading
        : RecordMode.Off;
      if (state.selectedDevice && state.selectedDevice.uid === payload.uid) {
        Vue.set(
          state.selectedDevice,
          'lastRecordMode',
          payload.isAutoDownload ? RecordMode.Downloading : RecordMode.Off
        );
      }
    },
    SET_DEVICE_IS_IRIDIUM(state: any, payload: any) {
      const index = state.devices.findIndex(
        (item: Device) => item.uid === payload.uid
      );
      state.devices[index].isIridium = payload.isIridium;
      if (state.selectedDevice && state.selectedDevice.uid === payload.uid) {
        Vue.set(state.selectedDevice, 'isIridium', payload.isIridium);
      }
    },
    UPDATE_STATUS(state: any, payload: any) {
      const index = state.devices.findIndex(
        (item: Device) => item.uid === payload.uid
      );
      state.devices[index].isConnected = payload.isConnected;
      Vue.set(state.lastDeviceStatus, payload.uid, {
        isConnected: payload.isConnected,
      });
    },
    UPDATE_LOCATION(state: any, payload: any) {
      Vue.set(state.lastDeviceLocation, payload.uid, {
        lat: payload.lat,
        lon: payload.lon,
      });
    },
    UPDATE_DEVICE(state: any, d: Device) {
      const index = state.devices.findIndex(
        (item: Device) => item.uid === d.uid
      );

      if (index !== -1) {
        // Vue.set(state.devices, index, d);
        // we don't want this reactive, LeafletMap is watching it
        state.devices[index] = d;
      } else {
        state.devices.push(d);
      }
    },

    SHOW_PIG_RUN_EDITOR(state: any, payload: any) {
      state.showPigRunManager = false;
      state.showPigRunEditor = true;
      state.showDeviceInfo = false;
      state.showDeviceTable = false;
    },

    HIDE_PIG_RUN_EDITOR(state: any, payload: any) {
      state.showPigRunManager = false;
      state.showPigRunEditor = false;
      state.showDeviceInfo = true;
      state.showDeviceTable = true;
    },

    SHOW_PIG_RUN_MANAGER(state: any, payload: any) {
      state.showPigRunManager = true;
      state.showPigRunEditor = false;
      state.showDeviceInfo = false;
      state.showDeviceTable = false;

      // Report manager modification.
      state.showReportManager = false;

      // Broadcast commands modification.
      state.showBroadcastCommands = false;
    },

    HIDE_PIG_RUN_MANAGER(state: any, payload: any) {
      state.showPigRunManager = false;
      state.showPigRunEditor = false;
      state.showDeviceInfo = true;
      state.showDeviceTable = true;
    },

    SHOW_REPORT_MANAGER(state: any, payload: any) {
      state.showPigRunManager = false;
      state.showPigRunEditor = false;
      state.showDeviceInfo = false;
      state.showDeviceTable = false;

      // Report manager modification.
      state.showReportManager = true;

      // Broadcast commands modification.
      state.showBroadcastCommands = false;
    },

    SHOW_BROADCAST_COMMANDS(state: any, payload: any) {
      state.showPigRunManager = false;
      state.showPigRunEditor = false;
      state.showDeviceInfo = false;
      state.showDeviceTable = false;

      // Report manager modification.
      state.showReportManager = false;

      // Broadcast commands modification.
      state.showBroadcastCommands = true;
    },

    SET_LEAFLET_LAYERS(state: any, payload: any) {
      state.leafletLayers[payload.leafletId] = payload.uid;
    },
    SET_LAYER_CONTROL(state: any, payload: any) {
      state.layerControl = payload;
    },
    SAVE_NOTIFICATION_SETTINGS(state: any, payload: any) {
      state.notifications = payload;
    },
    CHANGE_RTSTREAMDIALOG(state: any, payload: any) {
      state.RTStreamDialog = payload;
    },
    SET_ACTIVE_PIG_RUN_ARRAY_WITH_NOTIFICATION(state: any, payload: any) {
      state.activePigRunArrayWithNotification = payload;
    },
    SET_ACTIVE_PIG_RUN_UID_ARRAY_WITH_NOTIFICATION(state: any, payload: any) {
      state.activePigRunUidArrayWithNotification = payload;
    },

    // SET_CELLULAR_CARRIER_INFO mutation.
    //
    // Used by DeviceInfo.vue.
    SET_CELLULAR_CARRIER_INFO(state: any, payload: any) {
      // If the state.cellularCarrierInfoArray member is undefined, create an empty array.
      if (state.cellularCarrierInfoArray == undefined) {
        state.cellularCarrierInfoArray = [];
      }

      const elementIndex: number = state.cellularCarrierInfoArray.findIndex(
        (element: any) => element.uid == payload.uid
      );

      // If the uid value is not found, push the cellular carrier info object into the array. Otherwise, update the
      // element in the array directly, while supporting reactivity.
      if (elementIndex == -1) {
        // Reactive.
        state.cellularCarrierInfoArray.push(payload);
      } else {
        // Reactive.
        Vue.set(state.cellularCarrierInfoArray, elementIndex, payload);
      }
    },

    // INITIALIZE_DOWNLOAD_RECORDED_PASSAGE_DATA_PROGRESS_ARRAY mutation.
    //
    // This mutation is only used by ReportManager.vue via the initializeDownloadRecordedPassageDataProgressArray action.
    INITIALIZE_DOWNLOAD_RECORDED_PASSAGE_DATA_PROGRESS_ARRAY(
      state: any,
      initializeDownloadRecordedPassageDataProgressArray: any
    ) {
      console.log(
        'index.ts. Mutation = [INITIALIZE_DOWNLOAD_RECORDED_PASSAGE_DATA_PROGRESS_ARRAY]. Enter method.'
      );

      // Ensure the array is empty.
      state.downloadRecordedPassageDataProgressArray = [];

      // Push each element of the initializeDownloadRecordedPassageDataProgressArray payload into the state array.
      for (
        let i = 0;
        i < initializeDownloadRecordedPassageDataProgressArray.length;
        i++
      ) {
        state.downloadRecordedPassageDataProgressArray.push(
          initializeDownloadRecordedPassageDataProgressArray[i]
        );
      }
    },

    // SET_DOWNLOAD_RECORDED_PASSAGE_DATA_DOWNLOADING_STATE mutation.
    //
    // This mutation is related to downloading recorded passage data.
    //
    // This mutation is responsible for finding the correct element of the state.downloadRecordedPassageDataProgressArray member
    // and modifying its downloadState property value.
    SET_DOWNLOAD_RECORDED_PASSAGE_DATA_DOWNLOADING_STATE(
      state: any,
      downloadRecordedPassageDataDownloadingState: any
    ) {
      console.log(
        'index.ts. Mutation = [SET_DOWNLOAD_RECORDED_PASSAGE_DATA_DOWNLOADING_STATE]. Enter method.'
      );

      const elementIndex: number =
        state.downloadRecordedPassageDataProgressArray.findIndex(
          (element: any) =>
            element.uid == downloadRecordedPassageDataDownloadingState.uid
        );

      // If the element with the corresponding uid is found, update the value of the downloadState member.
      if (elementIndex != -1) {
        // Get the current element.
        const elementToBeUpdated =
          state.downloadRecordedPassageDataProgressArray[elementIndex];

        // Modify the downloadState member value of the current element.
        elementToBeUpdated.downloadState =
          downloadRecordedPassageDataDownloadingState.downloadState;

        // Reactive.
        Vue.set(
          state.downloadRecordedPassageDataProgressArray,
          elementIndex,
          elementToBeUpdated
        );
      }
    },

    // SET_DOWNLOAD_RECORDED_PASSAGE_DATA_PASSAGE_RECEIVED_STATE mutation.
    //
    // This mutation is related to downloading recorded passage data.
    //
    // This mutation is responsible for finding the correct element of the state.downloadRecordedPassageDataProgressArray member
    // and modifying its packetsReceived property value.
    SET_DOWNLOAD_RECORDED_PASSAGE_DATA_PASSAGE_RECEIVED_STATE(
      state: any,
      downloadRecordedPassageDataPassageReceivedState: any
    ) {
      console.log(
        'index.ts. Mutation = [SET_DOWNLOAD_RECORDED_PASSAGE_DATA_PASSAGE_RECEIVED_STATE]. Enter method.'
      );

      const elementIndex: number =
        state.downloadRecordedPassageDataProgressArray.findIndex(
          (element: any) =>
            element.uid == downloadRecordedPassageDataPassageReceivedState.uid
        );

      // If the element with the corresponding uid is found, update the value of the packetsReceived member.
      if (elementIndex != -1) {
        // Get the current element.
        const elementToBeUpdated =
          state.downloadRecordedPassageDataProgressArray[elementIndex];

        // Modify the packetsReceived member value of the current element.
        elementToBeUpdated.packetsReceived =
          downloadRecordedPassageDataPassageReceivedState.packetsReceived;
        elementToBeUpdated.latestPassageTimeStamp =
          downloadRecordedPassageDataPassageReceivedState.latestPassageTimeStamp;

        // Reactive.
        Vue.set(
          state.downloadRecordedPassageDataProgressArray,
          elementIndex,
          elementToBeUpdated
        );
      }
    },

    // SET_DOWNLOAD_RECORDED_PASSAGE_DATA_DOWNLOAD_COMPLETE_STATE mutation.
    //
    // This mutation is related to downloading recorded passage data.
    //
    // This mutation is responsible for finding the correct element of the state.downloadRecordedPassageDataProgressArray member
    // and modifying its downloadState and downloadCompleteTimeStamp property values.
    SET_DOWNLOAD_RECORDED_PASSAGE_DATA_DOWNLOAD_COMPLETE_STATE(
      state: any,
      downloadRecordedPassageDataDownloadCompleteState: any
    ) {
      console.log(
        'index.ts. Mutation = [SET_DOWNLOAD_RECORDED_PASSAGE_DATA_DOWNLOAD_COMPLETE_STATE]. Enter method.'
      );

      const elementIndex: number =
        state.downloadRecordedPassageDataProgressArray.findIndex(
          (element: any) =>
            element.uid == downloadRecordedPassageDataDownloadCompleteState.uid
        );

      // If the element with the corresponding uid is found, update the value of the downloadState and downloadCompleteTimeStamp members.
      if (elementIndex != -1) {
        // Get the current element.
        const elementToBeUpdated =
          state.downloadRecordedPassageDataProgressArray[elementIndex];

        // Modify the downloadState and downloadCompleteTimeStamp member values of the current element.
        elementToBeUpdated.downloadState =
          downloadRecordedPassageDataDownloadCompleteState.downloadState;
        elementToBeUpdated.downloadCompleteTimeStamp =
          downloadRecordedPassageDataDownloadCompleteState.downloadCompleteTimeStamp;

        // Reactive.
        Vue.set(
          state.downloadRecordedPassageDataProgressArray,
          elementIndex,
          elementToBeUpdated
        );
      }
    },

    // SET_DISK_USE mutation.
    //
    // This mutation is related to displaying device disk usage on the DeviceInfo.vue component.
    SET_DISK_USE(state: any, diskUseMsg: any) {
      console.log('index.ts. Mutation = [SET_DISK_USE]. Enter method.');

      const elementIndex: number = state.diskUseArray.findIndex(
        (element: any) => element.uid == diskUseMsg.uid
      );

      // If the uid value is not found, push the disk use object into the array. Otherwise, update the
      // element in the array directly, while supporting reactivity.
      if (elementIndex == -1) {
        // Reactive.
        state.diskUseArray.push(diskUseMsg);
      } else {
        // Reactive.
        Vue.set(state.diskUseArray, elementIndex, diskUseMsg);
      }
    },
    SET_PIPELINE_DIAMETER(state: any, payload) {
      state.pipelineDiameter = payload;
    },
    SET_EXPECTED_SPEED(state: any, payload) {
      state.expectedSpeed = payload;
    },
    SET_PIGRUNADD_RUNID(state: any, payload) {
      state.pigRunAddRunId = payload;
    },
  },
  actions: {
    drawerToggle(context) {
      context.commit('DRAWER_TOGGLE');
    },
    getDatabaseServerUtcDate(context) {
      console.log('Dispatch action = [getDatabaseServerUtcDate]');

      // Invoke Web API method.
      PigRunService.getDatabaseServerUtcDate()
        .then((response) => {
          console.log(
            'Dispatch action = [getDatabaseServerUtcDate]. Then response.'
          );

          // Construct an instance of the DatabaseServerUtcDate class.
          const databaseServerUtcDate: DatabaseServerUtcDate =
            new DatabaseServerUtcDate(response.data);
          console.log(
            'From object = [' +
              databaseServerUtcDate.currentDatabaseServerUtcDate +
              ']'
          );

          context.commit('SET_DATABASE_SERVER_UTC_DATE', databaseServerUtcDate);
        })
        .catch((error) => {
          console.log(
            'Dispatch action = [getDatabaseServerUtcDate]. Error response.'
          );
        });
    },

    // Action dispatched from Default.vue to get the list of active pig run id values for the logged in user that has notification configured.
    getActivePigRunListForUserWithNotification(context, userId: string) {
      console.log(
        'Dispatch action = [getActivePigRunListForUserWithNotification]'
      );

      // Call the web api method.
      PigRunService.getActivePigRunListForUserWithNotification(userId)
        .then((response) => {
          // debugger

          // Call mutation to set the store state.
          context.commit(
            'SET_ACTIVE_PIG_RUN_ARRAY_WITH_NOTIFICATION',
            response.data.pigRunIdList
          );
        })
        .catch((exception) => {
          console.log(
            'Exception caught in action dispatch = [getActivePigRunListForUserWithNotification]'
          );
        });
    },

    // Action dispatched from Default.vue to get the list of uid values participating in an active pig run for the logged in user
    // that has notification configured.
    getActivePigRunUidListForUserWithNotification(context, userId: string) {
      console.log(
        'Dispatch action = [getActivePigRunUidListForUserWithNotification]'
      );

      // Call the web api method.
      PigRunService.getActivePigRunUidListForUserWithNotification(userId)
        .then((response) => {
          // console.log('Dispatch action = [getActivePigRunUidListForUserWithNotification]. response.data.uidList = ' + response.data.uidList);
          // console.log('Dispatch action = [getActivePigRunUidListForUserWithNotification]. response.data.uidList.length = ' + response.data.uidList.length);

          context.commit(
            'SET_ACTIVE_PIG_RUN_UID_ARRAY_WITH_NOTIFICATION',
            response.data.uidList
          );
        })
        .catch((exception) => {
          console.log(
            'Exception caught in action dispatch = [getActivePigRunUidListForUserWithNotification]. exception = ' +
              exception
          );
        });
    },

    actionOne(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    actionTwo(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    addDevice(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    removeDevice(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    changePassword(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    completeMachineLearningAddPigRunRequest(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    updateLastPassage(context) {
      DeviceService.getLastPassageData(this.state.selectedDevice.uid)
        .then((response) => {
          if (response.data && response.data.length) {
            // all last passages will have idential DC and times
            context.commit(
              'SET_DEVICE_PASSAGE_DETECTION_COUNT',
              response.data[0].detectionCount
            );
            context.commit(
              'SET_DEVICE_PASSAGE_TIMESTAMP',
              response.data[0].passageTimeStamp
            );
            const newSelectedPassages = [];
            for (const passage of response.data) {
              if (passage.packetComplexInformation === 0) {
                passage.elfId = passage.passageId;
              }
              if (passage.packetComplexInformation === 1) {
                passage.geoId = passage.passageId;
              }
              if (passage.packetComplexInformation === 2) {
                passage.magId = passage.passageId;
              }
              newSelectedPassages.push(passage);
            }
            // context.commit('SET_LAST_PASSAGES', newSelectedPassages); // \todo: throws error
          }
        })
        .catch((error) => {
          console.error(error);
          this.dispatch('notify', error);
        });
    },
    updateLastStreamMode(context) {
      // TODO this should go away when lastStreamMode starts getting returned in devices
      DeviceService.getDeviceHistory(
        this.state.selectedDevice.uid,
        'controlResponse'
      )
        .then((response) => {
          if (response.data.length > 0) {
            context.commit(
              'SET_DEVICE_STREAM_MODE',
              response.data[response.data.length - 1].streamEnable
            );
          } else {
            context.commit('SET_DEVICE_STREAM_MODE', null);
          }
        })
        .catch((error) => {
          console.error(error);
          this.dispatch('notify', error);
        });
    },

    // Mitch - March 21, 2023.
    //
    // The passageChartShow action signature was modified during the ReportManager.vue component development.
    //
    // Original signature => passageChartShow(context, passage: Passage)
    //
    // Updated signature  => passageChartShow(context, passage: Passage | any)
    passageChartShow(context, passage: Passage | any) {
      if (passage.passageId) {
        context.commit(
          'SET_SELECTED_PASSAGE_DETECTION_COUNT',
          passage.detectionCount
        );
        context.commit(
          'SET_SELECTED_PASSAGE_TIMESTAMP',
          passage.passageTimeStamp
        );

        DeviceService.getPassageData(passage.uid, passage.passageId)
          .then((response) => {
            if (response.data) {
              context.commit(
                'SET_SELECTED_PASSAGE_SAMPLING_RATE',
                response.data.samplingRate
              );
              context.commit('SET_SELECTED_PASSAGE_DATA', response.data.data);
              context.commit('SET_SELECTED_PASSAGES', [passage]);
              context.commit(
                'SET_SELECTED_PASSAGE_END_TIMESTAMP',
                response.data.passageTimeEndOfSnip
              );
            }
          })
          .catch((error) => {
            console.error(error);

            this.dispatch('notify', error);

            context.commit('SET_SELECTED_PASSAGE_DATA', []);
          });
      }
    },

    passageDownload(context, passage) {
      if (passage.passageId) {
        context.commit(
          'SET_SELECTED_PASSAGE_DETECTION_COUNT',
          passage.detectionCount
        );
        context.commit(
          'SET_SELECTED_PASSAGE_TIMESTAMP',
          passage.passageTimeStamp
        );
        DeviceService.getPassageData(passage.uid, passage.passageId)
          .then((response) => {
            if (response.data) {
              context.commit('SET_SELECTED_PASSAGE_DATA', response.data.data);
              context.commit(
                'SET_SELECTED_PASSAGE_END_TIMESTAMP',
                response.data.passageTimeEndOfSnip
              );
              this.dispatch('passageExportCsv');
            }
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
            context.commit('SET_SELECTED_PASSAGE_DATA', []);
          });
      }
    },
    passageExportCsv(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    deleteHistory(context, tab: string) {
      console.log('DELETE ' + tab);

      if (tab === 'passage') {
        DeviceService.deletePassages(this.state.selectedDevice.uid)
          .then((response) => {
            context.commit('DELETE_HISTORY', tab);
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      }
      if (tab === 'heartbeat' || tab === 'heatbeat') {
        DeviceService.deleteHeartbeats(this.state.selectedDevice.uid)
          .then((response) => {
            context.commit('DELETE_HISTORY', tab);
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      }
      if (tab === 'deviceInfo') {
        DeviceService.deleteDeviceInfo(this.state.selectedDevice.uid)
          .then((response) => {
            context.commit('DELETE_HISTORY', tab);
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      }
      if (tab === 'controlResponse') {
        DeviceService.deleteControlResponse(this.state.selectedDevice.uid)
          .then((response) => {
            context.commit('DELETE_HISTORY', tab);
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      }
      if (tab === 'gpsFix') {
        DeviceService.deleteGpsFix(this.state.selectedDevice.uid)
          .then((response) => {
            context.commit('DELETE_HISTORY', tab);
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      }
      if (tab === 'error') {
        DeviceService.deleteErrors(this.state.selectedDevice.uid)
          .then((response) => {
            context.commit('DELETE_HISTORY', tab);
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      }
      // Mitch. This does not appear to be used. November 25, 2022.
      // if (tab === 'offline') {
      //  DeviceService.deleteOffline(this.state.selectedDevice.uid)
      //  .then((response) => {
      //    context.commit('DELETE_HISTORY', tab);
      //  })
      //  .catch((error) => {
      //    console.error(error);
      //    this.dispatch('notify', error);
      //  });
      // }
    },
    deletePassage(context, passageIds: number[]) {
      passageIds.forEach((passageId: number) => {
        DeviceService.deletePassage(this.state.selectedDevice.uid, passageId);
      });
      context.commit('DELETE_PASSAGES', passageIds);
    },
    verifyPassage(context, payload: Passage | MarkerPassage) {
      context.dispatch('togglePassageVerification', payload);
    },
    togglePassageVerification(context, payload: Passage | MarkerPassage) {
      if (payload.passageId && payload.uid) {
        payload.isVerified = !payload.isVerified;
        DeviceService.setPassageVerification(
          payload.uid,
          payload.passageId,
          payload.isVerified
        )
          .then((response) => {
            context.commit('SET_PASSAGE_VERIFICATION', payload);
            if ((payload as MarkerPassage).markerName !== undefined) {
              context.dispatch('verifyMarkerPassageComplete', payload);
            } else {
              context.dispatch('verifyPassageComplete', payload);
              if (context.state.passageDialogSource == 'report_manager') {
                return;
              }

              // ensure the most up-to-date last passage is reflected
              // Note: this ensures the DeviceInfo component shows the most up-to-date state of the last passage
              context.dispatch('updateLastPassage');
            }
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      } else {
        console.error('Missing passageId');
      }
    },
    setPassageVerification(context, payload: Passage | MarkerPassage) {
      if (payload.passageId && payload.uid) {
        DeviceService.setPassageVerification(
          payload.uid,
          payload.passageId,
          payload.isVerified!
        )
          .then((response) => {
            context.commit('SET_PASSAGE_VERIFICATION', payload);
            if ((payload as MarkerPassage).markerName !== undefined) {
              context.dispatch('verifyMarkerPassageComplete', payload);
            } else {
              context.dispatch('verifyPassageComplete', payload);
            }
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      } else {
        console.error('Missing passageId');
      }
    },
    verifyMarkerPassageComplete(context, payload: MarkerPassage) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    verifyPassageComplete(context, payload: Passage) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    EditPassageRunInfo(context, payload: any) {
      if (payload[0].passageId && payload[0].uid) {
        DeviceService.EditPassageRunInfo(payload)
          .then((response) => {
            if (response.data == true) {
              // We need to check if payload[1] is an active run first
              console.log(this.state.pigRun.activePigRuns);
              for (let i = 0; i < this.state.pigRun.activePigRuns.length; i++) {
                if (this.state.pigRun.activePigRuns[i].runId == payload[1]) {
                  context.dispatch('sendPigRunUpdateMsg', payload[1]);
                }
              }

              context.dispatch('restorePigData');
              context.dispatch('historicDataRefresh');
            }
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      } else {
        console.error('Missing passageId');
      }
    },
    ClearPassageRunInfo(context, payload: any) {
      if (payload.passageId && payload.uid) {
        DeviceService.ClearPassageRunInfo(payload)
          .then((response) => {
            if (response.data == true) {
              let index = -1;
              index = this.state.pigRun.activePigRuns.findIndex(
                (run: any) => run.runId === payload.RunId
              );
              if (index != -1) {
                context.dispatch('sendPigRunUpdateMsg', payload.RunId);
              } else {
                index = this.state.pigRun.completedPigRuns.findIndex(
                  (run: any) => run.runId === payload.RunId
                );
                if (index != -1) {
                  // context.dispatch('restorePigData');
                  // context.dispatch('upsertFinishedPigRun', this.state.pigRun.completedPigRuns[index]);
                }
              }

              context.dispatch('restorePigData');
              context.dispatch('historicDataRefresh');
            }
          })
          .catch((error) => {
            console.error(error);
            this.dispatch('notify', error);
          });
      } else {
        console.error('Missing passageId');
      }
    },
    broadcastShow(context, agms) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    broadcastClose(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    historicDataShow(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    historicDataRefresh(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    historicDataClose(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    RealTimeOptionsShow(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    UpdateGeoSpeedParams(context) {
      // Implement the method or remove it if not needed
    },
    UpdateGraphing(context) {
      // Implement the method or remove it if not needed
    },

    login(context, user) {
      context.commit('LOGIN', user);
    },
    setSelectedDevice(context, uid: number) {
      this.state.devices.forEach((device: Device) => {
        if (device.uid === uid) {
          context.commit('SET_SELECTED_DEVICE', device);
        }
      });
    },
    setSelectedDevicePlusZoom(context, uid: number) {
      this.state.devices.forEach((device: Device) => {
        if (device.uid === uid) {
          context.commit('SET_SELECTED_DEVICE', device);
        }
      });
      this.dispatch('zoomToSelected');
    },
    setSelectedMarker(context, agmMarker: AgmMarker) {
      context.commit('SET_SELECTED_MARKER', agmMarker);
    },
    setSelectedMarkerEditor(context, agmMarker: AgmMarker) {
      context.commit('SET_SELECTED_MARKER_Editor', agmMarker);
    },
    setMarkerLayers(context, markerLayers: any) {
      context.commit('SET_MARKER_LAYERS', markerLayers);
    },
    clearSelection(context) {
      context.commit('SET_SELECTED_DEVICE', {});
    },
    zoomToSelected(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    setPassageDialogSource(context, payload: string) {
      context.commit('SET_PASSAGE_DIALOG_SOURCE', payload);
    },
    clearPassageIdsToUpdate(context) {
      context.commit('CLEAR_PASSAGE_IDS_TO_UPDATE');
    },
    addPassageIdsToUpdate(context, passageIds: number[]) {
      context.commit('ADD_PASSAGE_IDS_TO_UPDATE', passageIds);
    },
    addUIDToUpdate(context, uid: number) {
      context.commit('ADD_UID_TO_UPDATE', uid);
    },
    getDevices(context) {
      console.log('Action: getDevices()');
      DeviceService.getDevices()
        .then((response) => {
          const devices: Device[] = [];
          response.data.forEach((d: any) => {
            // console.log(d);
            devices.push(
              new Device(
                d.uId,
                d.lat,
                d.lon,
                d.lastHeartbeat,
                d.lastPassage,
                d.lastDetectionCount,
                PowerMode.byIdx(d.lastPowerMode),
                d.lastStreamMode,
                d.lastSignalStrength,
                d.lastBattery,
                d.lastPassageId,
                d.lastFrequency0Setting,
                d.lastFrequency1Setting,
                d.lastFrequency2Setting,
                d.lastFrequency3Setting,
                d.lastSensitivity0,
                d.lastSensitivity1,
                d.lastSensitivity2,
                d.lastSensitivity3,
                d.lastEnable0,
                d.lastEnable1,
                d.lastEnable2,
                d.lastEnable3,
                d.isIridium
                  ? RecordModeIridium.intToString(d.lastRecordMode)
                  : RecordMode.intToString(d.lastRecordMode),
                ConnectionSchedule.byIdx(d.lastSchedule),
                d.bluetooth,
                d.isConnected === 1 ? true : false,
                d.isIridium,
                d.psgState,
                d.psgSize,
                d.imei,
                d.isAutodownload ? true : false,
                d.lastExpectedWakeupTime,
                d.lastShutdownTime,
                d.lastGeoMode,
                d.firmwareVersion,
                d.groupId
              )
            );
          });
          // console.log(devices);
          context.commit('SET_ALL_DEVICES', devices);
        })
        .catch((error) => {
          console.error(error);
          this.dispatch('notify', error);
        });
    },
    getDevice(context, uid: number) {
      console.log('Action: getDevice() ' + uid);
      DeviceService.getDevices() // TODO this should change when a single device API endpoint is available
        .then((response) => {
          const devices: Device[] = [];
          response.data.forEach((d: any) => {
            if (d.uId === uid) {
              const updatedDevice = new Device(
                d.uId,
                d.lat,
                d.lon,
                d.lastHeartbeat,
                d.lastPassage,
                d.lastDetectionCount,
                PowerMode.byIdx(d.lastPowerMode),
                d.lastStreamMode,
                d.lastSignalStrength,
                d.lastBattery,
                d.lastPassageId,
                d.lastFrequency0Setting,
                d.lastFrequency1Setting,
                d.lastFrequency2Setting,
                d.lastFrequency3Setting,
                d.lastSensitivity0,
                d.lastSensitivity1,
                d.lastSensitivity2,
                d.lastSensitivity3,
                d.lastEnable0,
                d.lastEnable1,
                d.lastEnable2,
                d.lastEnable3,
                d.isIridium
                  ? RecordModeIridium.intToString(d.lastRecordMode)
                  : RecordMode.intToString(d.lastRecordMode),
                ConnectionSchedule.byIdx(d.lastSchedule),
                d.bluetooth,
                d.isConnected === 1 ? true : false,
                d.isIridium,
                d.psgState,
                d.psgSize,
                d.imei,
                d.isAutodownload ? true : false,
                d.lastExpectedWakeupTime,
                d.lastShutdownTime,
                d.lastGeoMode,
                d.firmwareVersion
              );
              // check if device has swithed isIridium status
              const oldDevice = context.state.devices.find(
                (old: Device) => old.uid === uid
              );
              if (oldDevice && oldDevice.isIridium !== d.isIridium) {
                context.dispatch('updateDeviceIcon', {
                  uid: updatedDevice.uid,
                  isConnected: updatedDevice.isConnected,
                  isIridium: updatedDevice.isIridium,
                });
              }
              context.commit('UPDATE_DEVICE', updatedDevice);
              // if the device is currently selected device update that too
              if (uid === this.state.selectedDevice.uid) {
                context.commit('SET_SELECTED_DEVICE', updatedDevice);
                context.dispatch('updateLastPassage');
              }
            }
          });
        })
        .catch((error) => {
          console.error(error);
          this.dispatch('notify', error);
        });
    },
    getControlSettingsResponseHistoricData(context, payload: any) {
      DeviceService.getControlSettingsResponseHistoricData(
        payload.uid,
        payload.page,
        payload.rowsPerPage,
        payload.sortBy,
        payload.descending
      )
        .then((response) => {
          const mutationPayload: any = {
            type: 'controlResponse',
            data: response.data,
            page: payload.page,
            rowsPerPage: payload.rowsPerPage,
            sortBy: payload.sortBy,
            descending: payload.descending,
          };

          context.commit('SET_HISTORIC_DATA', mutationPayload);
        })
        .catch((error) => {
          console.error(error);
          this.dispatch('notify', error);
        });
    },
    getDeviceInformationResponseHistoricData(context, payload: any) {
      DeviceService.getDeviceInformationResponseHistoricData(
        payload.uid,
        payload.page,
        payload.rowsPerPage,
        payload.sortBy,
        payload.descending
      )
        .then((response) => {
          const mutationPayload: any = {
            type: 'deviceInfo',
            data: response.data,
            page: payload.page,
            rowsPerPage: payload.rowsPerPage,
            sortBy: payload.sortBy,
            descending: payload.descending,
          };

          context.commit('SET_HISTORIC_DATA', mutationPayload);
        })
        .catch((error) => {
          console.error(error);
          this.dispatch('notify', error);
        });
    },
    getHeartbeatHistoricData(context, payload: any) {
      DeviceService.getHeartbeatHistoricData(
        payload.uid,
        payload.page,
        payload.rowsPerPage,
        payload.sortBy,
        payload.descending
      )
        .then((response) => {
          const mutationPayload: any = {
            type: 'heartbeat',
            data: response.data,
            page: payload.page,
            rowsPerPage: payload.rowsPerPage,
            sortBy: payload.sortBy,
            descending: payload.descending,
          };

          context.commit('SET_HISTORIC_DATA', mutationPayload);
        })
        .catch((error) => {
          console.error(error);
          this.dispatch('notify', error);
        });
    },
    getPassageHistoricData(context, payload: any) {
      DeviceService.getPassageHistoricData(
        payload.uid,
        payload.page,
        payload.rowsPerPage,
        payload.sortBy,
        payload.descending
      )
        .then((response) => {
          const mutationPayload: any = {
            type: 'passage',
            data: response.data,
            page: payload.page,
            rowsPerPage: payload.rowsPerPage,
            sortBy: payload.sortBy,
            descending: payload.descending,
          };
          context.commit('SET_HISTORIC_DATA', mutationPayload);
        })
        .catch((error) => {
          console.error(error);
          this.dispatch('notify', error);
        });
    },

    getHistory(context, payload: any) {
      if (!payload.uid) {
        console.error('getHistory(): Bad request');

        return;
      }

      if (!payload.type) {
        console.error('getHistory(): Bad request');

        return;
      }

      console.log(
        'index.ts. Action = [getHistory]. payload.uid = [' + payload.uid + ']'
      );
      console.log(
        'index.ts. Action = [getHistory]. payload.type = [' + payload.type + ']'
      );

      DeviceService.getDeviceHistory(payload.uid, payload.type)
        .then((response) => {
          // const history = [];
          // response.data.forEach((d: any) => {
          //   devices.push(new Device(d.uid, d.lat, d.lon, d.heartbeat, PowerMode.byIdx(d.powerMode), ConnectionSchedule.byIdx(d.schedule)));
          // });

          context.commit('SET_HISTORIC_DATA', {
            type: payload.type,
            data: response.data,
          });
        })
        .catch((error) => {
          console.error(error);
          this.dispatch('notify', error);
        });
    },

    setHistory(context, payload: any) {
      context.commit('SET_HISTORIC_DATA', {
        type: payload.type,
        data: payload.data,
      });
    },
    setFilteredHistory(context, payload: any) {
      context.commit('SET_FILTERED_HISTORIC_DATA', {
        type: payload.type,
        data: payload.data,
      });
    },
    clearHistory(context) {
      context.commit('CLEAR_HISTORIC_DATA');
    },
    notify(context, message) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    notifySound(context, soundOption) {
      const idx = this.state.soundOptions.indexOf(soundOption);
      if (idx !== -1 && idx !== 0 && idx < this.state.soundFiles.length) {
        new Audio(this.state.soundFiles[idx]).play();
      }
    },
    updateStatus(context, payload) {
      context.commit('UPDATE_STATUS', payload);
      if (payload.uid === this.state.selectedDevice.uid) {
        context.commit('SET_DEVICE_CONNECTION_STATUS', payload.isConnected);
      }
    },
    updateLocation(context, payload) {
      context.commit('UPDATE_LOCATION', payload);
    },
    passageRecieved(context, payload) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    showHeartbeat(context, payload) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    updateDetectionCount(context, payload) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    setLeafletLayer(context, payload) {
      context.commit('SET_LEAFLET_LAYERS', payload);
    },
    setLayerControl(context, payload) {
      context.commit('SET_LAYER_CONTROL', payload);
    },
    notificationSettings(context, payload) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    saveNotificationSettings(context, payload) {
      context.commit('SAVE_NOTIFICATION_SETTINGS', payload);
      this.dispatch('notify', 'Notification preferences saved');
    },
    getDefaultNotificationSettings(context, payload) {
      // TODO this should hit an API for admin configured settings when available
      if (Object.keys(this.state.notifications).length === 0) {
        const mockApiResult = {
          heartbeat: false,
          heartbeatSound: '',
          passage: false,
          passageSound: '',
          support: false,
          supportSound: '',
          powerMode: false,
          powerModeSound: '',
          recordMode: false,
          recordModeSound: '',
          frequencySelect: false,
          frequencySelectSound: '',
          streamEnable: false,
          streamEnableSound: '',
          detectionCount: false,
          detectionCountSound: '',
          deviceInfo: false,
          deviceInfoSound: '',
          gpsFix: false,
          gpsFixSound: '',
          controlSetting: false,
          controlSettingSound: '',
          error: false,
          errorSound: '',
          offline: false,
          offlineSound: '',
          shutdown: false,
          shutdownSound: '',
          sleepReport: false,
          sleepReportSound: '',
        };
        context.commit('SAVE_NOTIFICATION_SETTINGS', mockApiResult);
      }
    },
    // audioStreamerShow(context, payload) {
    //   // no state/mutations, this is only used to subscribe to actions in other components
    // },
    realtimeStreamerShow(context, payload) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    localUserManagerShow(context, payload) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    setQueryParams(context, payload) {
      context.commit('SET_QUERY_PARAMS', payload);
    },
    handleQueryParams(context, payload) {
      console.log('handleQueryParams');
      console.log(payload);
      if (
        Object.prototype.hasOwnProperty.call(payload, 'lat') &&
        Object.prototype.hasOwnProperty.call(payload, 'lon')
      ) {
        console.log(payload.lat);
        console.log(payload.lat);
        context.dispatch('goToLatLon', payload);
      }
      context.commit('SET_QUERY_PARAMS', null);
    },
    goToLatLon(context, payload) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    updateDeviceIcon(context, payload) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },
    update_RTStreamDialog(context, payload) {
      context.commit('CHANGE_RTSTREAMDIALOG', payload);
    },
    ClearCompletedRun(context) {
      // no state/mutations, this is only used to subscribe to actions in other components
    },

    // setCellularCarrierInfo action.
    //
    // Dispatched from Hubs.ts on signalR ReceiveCellularCarrierInfo event.
    setCellularCarrierInfo(context, payload) {
      context.commit('SET_CELLULAR_CARRIER_INFO', payload);
    },

    // initializeDownloadRecordedPassageDataProgressArray action.
    //
    // Dispatched from ReportManager.vue when creating a recorded passage data download session.
    initializeDownloadRecordedPassageDataProgressArray(context, payload: any) {
      console.log(
        'index.ts. Action = [initializeDownloadRecordedPassageDataProgressArray]. Enter method.'
      );

      context.commit(
        'INITIALIZE_DOWNLOAD_RECORDED_PASSAGE_DATA_PROGRESS_ARRAY',
        payload
      );
    },

    // setDownloadRecordedPassageDataDownloadingState action.
    //
    // Dispatched from Hubs.ts when a ReceiveDownloadRecordedPassageDataDownloadingState signalR event is raised.
    setDownloadRecordedPassageDataDownloadingState(
      context,
      downloadRecordedPassageDataDownloadingState: any
    ) {
      console.log(
        'index.ts. Action = [setDownloadRecordedPassageDataDownloadingState]. Enter method.'
      );

      context.commit(
        'SET_DOWNLOAD_RECORDED_PASSAGE_DATA_DOWNLOADING_STATE',
        downloadRecordedPassageDataDownloadingState
      );
    },

    // setDownloadRecordedPassageDataPassageReceivedState action.
    //
    // Dispatched from Hubs.ts when a ReceiveDownloadRecordedPassageDataPassageReceivedState signalR event is raised.
    setDownloadRecordedPassageDataPassageReceivedState(
      context,
      downloadRecordedPassageDataPassageReceivedState: any
    ) {
      console.log(
        'index.ts. Action = [setDownloadRecordedPassageDataPassageReceivedState]. Enter method.'
      );

      context.commit(
        'SET_DOWNLOAD_RECORDED_PASSAGE_DATA_PASSAGE_RECEIVED_STATE',
        downloadRecordedPassageDataPassageReceivedState
      );
    },

    // setDownloadRecordedPassageDataDownloadCompleteState action.
    //
    // Dispatched from Hubs.ts when a ReceiveDownloadRecordedPassageDataDownloadCompleteState signalR event is raised.
    setDownloadRecordedPassageDataDownloadCompleteState(
      context,
      downloadRecordedPassageDataDownloadCompleteState: any
    ) {
      console.log(
        'index.ts. Action = [setDownloadRecordedPassageDataDownloadCompleteState]. Enter method.'
      );

      context.commit(
        'SET_DOWNLOAD_RECORDED_PASSAGE_DATA_DOWNLOAD_COMPLETE_STATE',
        downloadRecordedPassageDataDownloadCompleteState
      );
    },

    // setDiskUse action.
    //
    // Dispatched from Hubs.ts when a ReceiveDiskUse signalR event is raised.
    setDiskUse(context, diskUseMsg: any) {
      console.log('index.ts. Action = [setDiskUse]. Enter method.');

      context.commit('SET_DISK_USE', diskUseMsg);
    },
  },

  getters: {
    deviceUids: (state) => {
      // We need to cast these to string for search
      return state.devices.map((d: Device) => String(d.uid));
    },
    deviceUidNumbers: (state) => {
      return state.devices.map((d: Device) => d.uid);
    },
    token: (state) => {
      if (state.user && state.user.token) {
        return state.user.token;
      }
      return '';
    },
    user: (state) => {
      return state.user;
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {
    pigRun: PigRun,
    hubs: Hubs,
    command: CommandStore,
    admin: AdminStore,
    device: DeviceStore,
  },
});
