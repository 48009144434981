// GetToolRequest.
//
// Class representing a request to retrieve a tool
export class GetToolRequest {
  // expectedSpeed
  public expectedSpeed: number;

  // size
  public size: number;

  // toolTypeId
  public toolTypeId: number;

  // Constructor.
  public constructor(expectedSpeed: number, size: number, toolTypeId: number) {
    this.expectedSpeed = expectedSpeed;
    this.size = size;
    this.toolTypeId = toolTypeId;
  }
}
