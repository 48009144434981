
import DateCommon from '../utils/DateCommon';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Passage } from '@/models/Passage';
import { EventBus } from '@/utils/EventBus';
import DisplayMLResult, {
  MachineLearningResultColors,
} from '@/utils/DisplayMLResult';

@Component
export default class PassageDialog extends Vue {
  public editUserDialog = false;
  public newPassageTime: any = null;
  public newPassageTimeDisplay: any = null;
  public millisecondsExtra = '';
  public isPassageTimeEditSessionActive = false;
  public passageDialog = false;
  public maximizedToggle = false;
  public chart: any = null;
  public cursorEvt: any = null;
  public showDependentButtons = false;
  public userRole = '';

  // The target passage time stamp value to use for the edit operation.
  public updatePassageTimeStampTargetValue: Date | undefined;

  // getters
  get currentType() {
    const p = this.$store.state.selectedPassages[0] as Passage;

    if (p.elfId === p.passageId) return 'ELF';
    if (p.geoId === p.passageId) return 'GEO';
    if (p.magId === p.passageId) return 'MAG';

    console.log(
      'PassageDialog.vue. currentType. No match found: Returning null.'
    );
    return null;
  }

  get dropdownOptions() {
    const p = this.$store.state.selectedPassages[0] as Passage;

    return [
      p.elfId !== null
        ? {
            id: 'elf',
            label: 'ELF',
            type: 'ELF',
            visible: p.elfId === p.passageId,
          }
        : null,
      p.geoId !== null
        ? {
            id: 'geo',
            label: 'GEO',
            type: 'GEO',
            visible: p.geoId === p.passageId,
          }
        : null,
      p.magId !== null
        ? {
            id: 'mag',
            label: 'MAG',
            type: 'MAG',
            visible: p.magId === p.passageId,
          }
        : null,
    ].filter((item) => item !== null);
  }

  get wafeformType(): string {
    const selectedPassages = this.$store.state.selectedPassages;
    if (selectedPassages && selectedPassages.length > 0) {
      const firstPassage = selectedPassages[0];
      if (firstPassage.elfMachineLearningResultList?.length > 0) {
        return 'ELF';
      } else if (firstPassage.geoMachineLearningResultList?.length > 0) {
        return 'GEO';
      } else if (firstPassage.magMachineLearningResultList?.length > 0) {
        return 'MAG';
      }
    }
    return 'N/A';
  }

  get machineLearningResultList() {
    const selectedPassages = this.$store.state.selectedPassages;
    if (selectedPassages && selectedPassages.length > 0) {
      const firstPassage = selectedPassages[0];
      return {
        elf: firstPassage.elfMachineLearningResultList || [],
        geo: firstPassage.geoMachineLearningResultList || [],
        mag: firstPassage.magMachineLearningResultList || [],
      };
    }
    return {
      elf: [],
      geo: [],
      mag: [],
    };
  }

  get isVerified() {
    for (let i = 0; i < this.$store.state.selectedPassages.length; i++) {
      for (let j = 0; j < this.$store.state.passageIdsToUpdate.length; j++) {
        if (
          this.$store.state.selectedPassages[i].passageId ==
          this.$store.state.passageIdsToUpdate[j]
        ) {
          return this.$store.state.selectedPassages[i].isVerified;
        }
      }
    }

    console.log(
      'PassageDialog.vue. isVerified. No match found: Returning false.'
    );
    return false;
  }

  get detectionCount() {
    return 'Detection Count ' + this.$store.state.selectedPassageDetectionCount;
  }

  get passageTimeStamp() {
    return this.$store.state.selectedPassageTimestamp;
  }

  get passageData() {
    return this.$store.state.selectedPassageData;
  }

  get selectedPassages() {
    return this.$store.state.selectedPassages;
  }

  public mounted() {
    console.log('PassageDialog: mounted()');
  }

  public beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  public getuser() {
    return this.$store.state.user.role;
  }

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'passageChartShow') {
      this.showDialog();
    }
  });

  public navigate(direction: string) {
    // clear the chart
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }

    EventBus.$emit('switch-passage', {
      passage: this.$store.state.selectedPassages[0],
      direction,
      type: this.currentType,
    });
  }

  public getMLResultColor(data: any): MachineLearningResultColors {
    for (const key of Object.keys(data)) {
      if (data[key]?.length > 0) {
        return DisplayMLResult(data[key]).color;
      }
    }
    return 'orange';
  }

  public getMLIcon(color: MachineLearningResultColors): string {
    if (color.includes('red')) {
      return 'block';
    } else if (color.includes('green')) {
      return 'check_circle_outline';
    } else {
      return 'help';
    }
  }

  public getMLResultConfidence(data: any): string {
    for (const key of Object.keys(data)) {
      if (data[key]?.length > 0) {
        return ' ' + DisplayMLResult(data[key]).confidence + '%';
      }
    }
    return 'N/A';
  }

  public showChart(type: string) {
    // wipe the chart and hide the buttons
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }

    this.showDependentButtons = false;

    // prepare to show the next chart
    const psg = this.$store.state.selectedPassages[0] as Passage;

    const typeMap: Record<string, number | null | undefined> = {
      ELF: psg.elfId,
      GEO: psg.geoId,
      MAG: psg.magId,
    };

    this.$store.dispatch('passageChartShow', {
      ...psg,
      passageId: typeMap[type],
    });

    // Ensure chart-dependent elements are reset
    this.$nextTick(() => {
      this.createChart();
      this.showDependentButtons = true;
    });
  }

  public verifyPassage() {
    console.log('PassageDialog.vue. verifyPassage. Enter method.');

    if (
      this.$store.state.passageIdsToUpdate.length == 0 ||
      this.$store.state.passageIdsToUpdate[0] < 0
    ) {
      console.log(
        'PassageDialog.vue. verifyPassage. No passage IDs to update.'
      );
      return;
    }

    let passageToVerify: Passage | null = null;

    for (let i = 0; i < this.$store.state.selectedPassages.length; i++) {
      for (let j = 0; j < this.$store.state.passageIdsToUpdate.length; j++) {
        if (
          this.$store.state.selectedPassages[i].passageId ==
          this.$store.state.passageIdsToUpdate[j]
        ) {
          passageToVerify = this.$store.state.selectedPassages[i];
          break;
        }
      }
    }

    if (passageToVerify == null) {
      console.log(
        'PassageDialog.vue. verifyPassage. No passage found to verify.'
      );
      return;
    }

    console.log(
      'PassageDialog.vue. verifyPassage. Passage.Aid = [' +
        passageToVerify.passageId +
        '].'
    );

    this.$store.dispatch('verifyPassage', passageToVerify);
    EventBus.$emit('passage-verified', { passage: passageToVerify });
    this.passageDialog = false;
  }

  // Mitch. June 28, 2023.
  //
  // Updated implementation of the onSavePassageTime component method where the PassageDialog.vue component is also invoked from the ReportManager.vue component.
  public onSavePassageTime() {
    console.log('PassageDialog.vue. onSavePassageTime. Enter method.');
    console.log(
      'PassageDialog.vue. onSavePassageTime. this.$store.state.passageDialogSource = [' +
        this.$store.state.passageDialogSource +
        '].'
    );
    console.log(
      'PassageDialog.vue. onSavePassageTime. Before this.$store.state.passageIdsToUpdate'
    );

    // If this component (i.e., PassageDialog.vue) has been invoked from either ReportManager.vue or DeviceInfo.vue.
    if (
      this.$store.state.passageDialogSource == 'report_manager' ||
      this.$store.state.passageDialogSource == 'selected_passage'
    ) {
      // Get at least one Passage.Aid value stored in the Vuex store state member passageIdsToUpdate.
      if (
        this.$store.state.passageIdsToUpdate != undefined &&
        this.$store.state.passageIdsToUpdate.length > 0
      ) {
        let passageId = -1;

        for (let i = 0; i < this.$store.state.passageIdsToUpdate.length; i++) {
          passageId = this.$store.state.passageIdsToUpdate[i];

          if (passageId != -1) {
            break;
          }
        }

        // If a Passage.Aid value was not found, return.
        if (passageId == -1) {
          return;
        }

        console.log(
          'PassageDialog.vue. onSavePassageTime. Passage.Aid = [' +
            passageId +
            '].'
        );

        this.$store.dispatch(
          'updatePassageTimeStampFromPassageDialogComponent',
          {
            passageId: passageId,
            updatePassageTimeStampTargetValue:
              this.updatePassageTimeStampTargetValue,
          }
        );
        this.editUserDialog = false;
      }
    } else {
      const data = {
        newPassageTime: this.newPassageTime,
        newPassageTimeDisplay: this.newPassageTimeDisplay,
      };

      this.$store.dispatch('updatePassageTimeStamps', data);
      this.editUserDialog = false;
    }
  }

  public showDialog() {
    this.passageDialog = true;
    this.showDependentButtons = false;
  }

  public toggleMaxMin() {
    this.maximizedToggle = !this.maximizedToggle;
    // if we remove the width style the chartCard the chart will auto expand when maxed
    if (this.maximizedToggle) {
      // @ts-ignore
      this.$refs.chartCard.$el.style = '';
    } else {
      // @ts-ignore
      this.$refs.chartCard.$el.style = 'width: 700px; max-width: 80vw;';
    }
  }

  public beforeHideDialog() {
    // dispose the chart first!
    console.log('run: beforeHideDialog');
    if (this.chart) {
      this.chart.dispose();
    }
    // disable passage time edit session
    this.isPassageTimeEditSessionActive = false;
  }

  public beforeHideConfirmDialog() {
    console.log('run: beforeHideConfirmDialog');
    // disable passage time edit session
    this.isPassageTimeEditSessionActive = false;
  }

  @Watch('passageData', { immediate: false, deep: true })
  public passageDataChanged(value: any, oldValue: any) {
    // html div element doesn't exist when dialog not shown, it is needed to create the chart!
    if (this.passageDialog) {
      this.createChart();
      this.showDependentButtons = true;
    }
  }

  public passageEditSessionChanged() {
    console.log('PassageDialog.vue. passageEditSessionChanged. Enter method.');
    console.log(
      'PassageDialog.vue. passageEditSessionChanged. isPassageTimeEditSessionActive = [' +
        this.isPassageTimeEditSessionActive +
        ']'
    );

    if (this.isPassageTimeEditSessionActive) {
      const cursorPosition = {
        x: null as unknown as Date,
        tooltip: '',
      };

      this.cursorEvt = this.chart.cursor.events.on(
        'cursorpositionchanged',
        (ev: any) => {
          const xAxis = ev.target.chart.xAxes.getIndex(0);
          cursorPosition.x = xAxis.positionToDate(
            xAxis.toAxisPosition(ev.target.xPosition)
          );
          cursorPosition.tooltip =
            ev.target.chart.xAxes.values[0].tooltip.label.currentText;
        }
      );

      // add zero before single digit minutes for newPassageTimeDisplay
      const pad = (n: number) => {
        return n < 10 ? '0' + n : n;
      };

      this.chart.plotContainer.events.once('hit', (ev: any) => {
        console.log(
          'PassageDialog.vue. passageEditSessionChanged. In hit event handler.'
        );

        const chartValue = cursorPosition.x;
        const millisecondsFromLabel = cursorPosition.tooltip.substr(
          cursorPosition.tooltip.lastIndexOf('.') + 1,
          cursorPosition.tooltip.length
        );
        const milliseconds = millisecondsFromLabel.replace(
          this.millisecondsExtra,
          ''
        );
        const utcDate = new Date(
          Date.UTC(
            chartValue.getFullYear(),
            chartValue.getMonth(),
            chartValue.getDate(),
            chartValue.getHours(),
            chartValue.getMinutes(),
            chartValue.getSeconds(),
            parseInt(milliseconds, 10)
          )
        );

        this.newPassageTimeDisplay =
          chartValue.getFullYear() +
          '-' +
          pad(chartValue.getMonth() + 1) +
          '-' +
          pad(chartValue.getDate()) +
          ' ' +
          pad(chartValue.getHours()) +
          ':' +
          pad(chartValue.getMinutes()) +
          ':' +
          pad(chartValue.getSeconds()) +
          '.' +
          milliseconds +
          this.millisecondsExtra;
        this.newPassageTime = utcDate
          .toISOString()
          .replace('Z', `${this.millisecondsExtra}Z`);
        this.editUserDialog = true;

        // Mitch - test (start)
        console.log(
          'PassageDialog.vue. passageEditSessionChanged. Year = [' +
            utcDate.getUTCFullYear() +
            '].'
        );
        console.log(
          'PassageDialog.vue. passageEditSessionChanged. Month = [' +
            utcDate.getUTCMonth() +
            '].'
        );
        console.log(
          'PassageDialog.vue. passageEditSessionChanged. Day = [' +
            utcDate.getUTCDate() +
            '].'
        );
        console.log(
          'PassageDialog.vue. passageEditSessionChanged. Hour = [' +
            utcDate.getUTCHours() +
            '].'
        );
        console.log(
          'PassageDialog.vue. passageEditSessionChanged. Minute = [' +
            utcDate.getUTCMinutes() +
            '].'
        );
        console.log(
          'PassageDialog.vue. passageEditSessionChanged. Second = [' +
            utcDate.getUTCSeconds() +
            '].'
        );
        console.log(
          'PassageDialog.vue. passageEditSessionChanged. Milliseconds = [' +
            utcDate.getUTCMilliseconds() +
            '].'
        );

        this.updatePassageTimeStampTargetValue = utcDate;
        // Mitch - test (end)
      });
    } else {
      if (this.cursorEvt !== null) {
        this.cursorEvt.dispose();
      }
    }
  }

  public createChart() {
    const chart = am4core.create('chartdiv', am4charts.XYChart);

    let numberOfSamples = this.passageData.length;
    // A web passage has the sampling rate stored as part of the Passage table record in the database. If the sampling rate
    // exists, it is used. Otherwise, the default sampling rate of 15.625 is used.
    let samplingRate = this.$store.state.selectedPassageSamplingRate;

    console.log(
      'PassageDialog.vue. createChart. samplingRate = [' + samplingRate + ']'
    );

    // Calculate the dateOffset.
    let dateOffset = (1 / samplingRate) * 1000 * numberOfSamples;
    let datedelta = 1000 / samplingRate;

    const data = [];
    // let dateOffset = 64 * numberOfSamples;    // Mitch - commented this out.

    const passageEndTime = this.$store.state.selectedPassageEndTimestamp;

    this.millisecondsExtra = passageEndTime.slice(-3);

    const endDate = DateCommon.sqlServerToDate(passageEndTime);

    if (endDate) {
      for (let i = 0; i < numberOfSamples; i++) {
        data.push({
          date: DateCommon.utcTime(endDate) - dateOffset,
          name: 'name' + i,
          value: this.passageData[i],
        });
        dateOffset -= datedelta; //64;
      }
    }
    chart.data = data;
    chart.numberFormatter.numberFormat = '#';

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // @ts-ignore
    dateAxis.tooltip.disabled = false;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.baseInterval = { timeUnit: 'millisecond', count: datedelta };
    dateAxis.dateFormats.setKey(
      'millisecond',
      'HH:mm:ss.SSS' + this.millisecondsExtra
    );

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // @ts-ignore
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';

    series.tooltipText = '{valueY.value}';
    chart.cursor = new am4charts.XYCursor();

    const scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;

    this.chart = chart;
  }
}
