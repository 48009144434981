// DeleteAllPigRunToolsForPigRunRequest.
//
// Class representing a request to delete all of the rows with a matching runId in the PigRunTools table.
export class DeleteAllPigRunToolsForPigRunRequest {
  // runId
  public runId: number;

  // Constructor.
  public constructor(runId: number) {
    this.runId = runId;
  }
}
