
import L from 'leaflet';
import MapCommon from '../utils/MapCommon';
import PigRunCommon from '../utils/PigRunCommon';
import { Device } from '../models/Device';
import { AgmMarker } from '../models/AgmMarker';
import { PigPath } from '../models/PigPath';
import { PigRun } from '../models/PigRun';
import { CompletedPigRunBroadcastCommand } from '../models/CompletedPigRunBroadcastCommand';
import { Component, Vue } from 'vue-property-decorator';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as turf from '@turf/turf';
import * as turfHelpers from '@turf/helpers';
import { MarkerPassage } from '../models/MarkerPassage';
import PigRunService from '@/services/PigRunService';
import { GetToolRequest } from '@/models/MachineLearning/GetToolRequest';
import { UpdatePigRunJobNumberRequest } from '@/models/UpdatePigRunJobNumberRequest';
import { AddPigRunToolRequest } from '@/models/AddPigRunToolRequest';
import { MachineLearningResultsRequest } from '../models/MachineLearning/MachineLearningResultsRequest';
dayjs.extend(utc);

@Component
export default class PigRunManager extends Vue {
  public selectedPigRun: any = '';
  public pigRunPreview: L.Polyline | null = null;
  public kmlPreview: L.Polyline | null = null;
  public launchPreview: L.Marker | null = null;
  public recievePreview: L.Marker | null = null;
  public offlinePreviews: { [key: string]: L.Marker } = {};
  public offlineAgms: Record<string, [number, number]> = {};
  public agmMapMarkers: L.Marker[] = [];

  public launchPigDialog = false;
  public disableLaunch = true;
  public selectedPig = '';
  public pigOptions = [];
  public selectedPigReport: any = '';
  public Launch_at_TimeDialog = false;
  public LaunchTimestamp = '';

  public launchPigJobNumber: any = '';
  public launchPigToolName: any = '';
  public launchPigToolType: any = '';
  public launchPigToolTypeArray: any[] = [];

  public Report_Dialog = false;
  public Report_Types = ['Default', 'Summary'];
  public Report_Extension = ['csv', 'pro'];
  public selectedReport_Type: any = '';
  public selectedReport_Extension: any = '';
  public disableReportDwn = true;
  public dir_isBackward = false;

  // Completed pig run broadcast command section - start
  public completedPigRunBroadcastCommandButtonDisable = true;
  public displayCompletedPigRunBroadcastCommandDialog = false;

  public completedPigRunBroadcastCommandDownloadBetweenLaunchAndReceive = false;

  // A reactive property to hold filtered completed run options.
  public filteredCompletedRunOptions: any[] = [];
  public filteredPigRunOptions: any[] = [];

  // completedPigRunBroadcastCommandButtonOnClick event handler.
  //
  // Event handler that executes for the completed pig run broadcast command button click event.
  public completedPigRunBroadcastCommandButtonOnClick() {
    console.log(
      'PigRunManager.vue. completedPigRunBroadcastCommandButtonOnClick event handler.'
    );

    this.displayCompletedPigRunBroadcastCommandDialog = true;
  }

  // completedPigRunBroadcastCommandBroadcastNowOnClick event handler.
  //
  // Event handler that executes for the completed pig run broadcast command now button click event.
  public async completedPigRunBroadcastCommandBroadcastNowOnClick() {
    console.log(
      'PigRunManager.vue. completedPigRunBroadcastCommandBroadcastNowOnClick event handler.'
    );

    // Construct an array to hold each command to broadcast.
    let commandsToBroadcast: Array<string> = new Array<string>();

    if (this.completedPigRunBroadcastCommandDownloadBetweenLaunchAndReceive) {
      commandsToBroadcast.push('RFD');
    }

    // Construct a CompletedPigRunBroadcastCommand object.
    let completedPigRunBroadcastCommand: CompletedPigRunBroadcastCommand =
      new CompletedPigRunBroadcastCommand(
        this.selectedPigReport.runData.runId,
        commandsToBroadcast
      );

    // Call web api here.
    await PigRunService.createCompletedPigRunBroadcastCommands(
      completedPigRunBroadcastCommand
    );

    // Close the dialog.
    this.displayCompletedPigRunBroadcastCommandDialog = false;
  }

  // Complete pig run broadcast command section - end

  public onSelectExtension() {
    if (this.selectedReport_Extension && this.selectedReport_Type) {
      this.disableReportDwn = false;
    } else {
      this.disableReportDwn = true;
    }
  }

  public onDir_Checked() {
    // If there is a selected pigPath then we need to swap launch/receive and possibly the order of Markers
    if (this.selectedPigPath && this.launchPreview && this.recievePreview) {
      this.launchPreview.removeFrom(this.map);
      this.recievePreview.removeFrom(this.map);

      var old_launch_lat = this.selectedPigPath.launchSiteLat;
      var old_launch_lon = this.selectedPigPath.launchSiteLon;
      var old_receive_lat = this.selectedPigPath.recieveSiteLat;
      var old_receive_lon = this.selectedPigPath.recieveSiteLon;

      this.selectedPigPath.launchSiteLat = old_receive_lat;
      this.selectedPigPath.launchSiteLon = old_receive_lon;
      this.selectedPigPath.recieveSiteLat = old_launch_lat;
      this.selectedPigPath.recieveSiteLon = old_launch_lon;

      this.launchPreview = L.marker(
        L.latLng(
          this.selectedPigPath.launchSiteLat,
          this.selectedPigPath.launchSiteLon
        ),
        { icon: PigRunCommon.getIcon('LAUNCH') }
      );
      this.launchPreview.addTo(this.map);
      this.recievePreview = L.marker(
        L.latLng(
          this.selectedPigPath.recieveSiteLat,
          this.selectedPigPath.recieveSiteLon
        ),
        { icon: PigRunCommon.getIcon('RECEIVE') }
      );
      this.recievePreview.addTo(this.map);

      const latLngs: L.LatLng[] = [];
      latLngs.push(this.launchPreview.getLatLng());

      this.selectedPigPath.markers
        ?.slice()
        .reverse()
        .forEach((Marker: AgmMarker) => {
          if (Marker) {
            latLngs.push(L.latLng(Marker.lat, Marker.lon));
          }
        });

      latLngs.push(this.recievePreview.getLatLng());
      var pigRunOverlayGenerated: L.Polyline | null = null;
      pigRunOverlayGenerated = L.polyline(latLngs, PigRunCommon.pigRunOptions);
      this.selectedPigPath.pigLineGenerated = pigRunOverlayGenerated.toGeoJSON()
        .geometry.coordinates as number[][];

      if (this.selectedPigPath.markers) {
        this.selectedPigPath.markers.reverse();
      }
    } else {
      this.dir_isBackward = false;
    }
  }

  get map() {
    return this.$store.state.map;
  }

  get selectedPigPath(): PigPath | null {
    const selectedPigPaths = this.$store.state.pigRun.pigPaths.filter(
      (pigPath: PigPath) => pigPath.pathId === this.selectedPigRun.value
    );
    if (selectedPigPaths.length > 0) {
      return selectedPigPaths[0];
    }
    return null;
  }

  get pigRunOptions() {
    return this.$store.state.pigRun.pigPaths.map((pigPath: PigPath) => {
      return { label: pigPath.pigRunName, value: pigPath.pathId };
    });
  }

  // Machine Learning - Added getter for launch pig tool type options
  get launchPigToolTypeOptions() {
    return this.launchPigToolTypeArray;
  }

  get showPigRunManager() {
    return this.$store.state.showPigRunManager;
  }

  get completedPigRuns() {
    return this.$store.state.pigRun.completedPigRuns;
  }

  get completedRunOptions() {
    return this.$store.state.pigRun.completedPigRuns.map((pigRun: PigRun) => {
      return {
        value: pigRun.runId,
        runData: pigRun,
        label:
          dayjs.default(pigRun.recieveTime).utc().format('YYYY-MM-DD HH:mm') +
          ' / ' +
          pigRun.pigPath.pigRunName,
      };
    });
  }

  get isReportLoading() {
    return this.$store.state.pigRun.isReportLoading;
  }
  get isRCompRunSynching() {
    return this.$store.state.pigRun.isRCompRunSynching;
  }

  get activePigRuns() {
    return this.$store.state.pigRun.activePigRuns;
  }

  public unsubscribe = this.$store.subscribeAction((action, state) => {
    if (action.type === 'ClearCompletedRun') {
      this.ClearCompletedRun();
    } else if (action.type == 'updateCompRun') {
      this.onRefreshcompRun(action.payload);
    }
  });

  public ClearCompletedRun() {
    this.selectedPigReport = '';
    this.removePreview();
  }

  public onReadyLaunch() {
    console.log('PigRunManager.vue: onReadyLaunch()');

    // Previous Implementation Prior to Machine Learning
    if (
      this.selectedPigRun.value &&
      this.selectedPig.length > 0 &&
      this.launchPigToolType.length > 0
    ) {
      // Don't allow relaunch of active pig
      const activeRuns = this.activePigRuns.filter(
        (activeRun: PigRun) =>
          activeRun.pigPath.pathId === this.selectedPigRun.value
      );
      const activePigs = activeRuns.map((run: PigRun) => run.pig);
      // TEMPORARY disable launching multiple pig runs until it can be properly tested
      if (activeRuns.length > 0 || activePigs.includes(this.selectedPig)) {
        this.disableLaunch = true;
      } else {
        this.disableLaunch = false;
      }

      return;
    }

    // Disabling launch if checks don't go through
    this.disableLaunch = true;
  }

  public showAvailablePigs() {
    this.selectedPig = '';
    this.disableLaunch = true;

    if (this.selectedReport_Extension && this.selectedReport_Type) {
      this.disableReportDwn = false;
    } else {
      this.disableReportDwn = true;
    }

    const pigRun = this.$store.state.pigRun.pigPaths.filter(
      (pigPath: PigPath) => pigPath.pathId === this.selectedPigRun.value
    )[0];
    if (pigRun == undefined || pigRun == null) {
      return;
    }
    this.pigOptions = pigRun.pigs; // .map( (pig: Pig) => pig.pigName);
  }

  public onLaunchPig() {
    console.log('PigRunManager.vue. onLaunchPig. Enter method.');

    const path = this.selectedPigPath;
    const pig = this.selectedPig;

    if (path && pig) {
      const markerCopy: AgmMarker[] = [];

      if (path.markers) {
        path.markers.forEach((marker: AgmMarker) => {
          markerCopy.push(marker);
        });
      }

      const pr = new PigRun(
        -1, // ID will be created from DB
        path,
        pig,
        new Date(), // dayjs.utc().toDate(), // new Date(), // launchTime
        undefined, // recieveTime
        undefined, // calculatedSpeed
        undefined, // passages
        markerCopy // copy of the path markers
      );

      this.$store.dispatch('upsertPigRun', pr);

      // PigProgress component manages its own map data so remove all previews/selections
      this.selectedPigRun = '';
      this.selectedPigReport = '';
      this.selectedPig = '';
      this.dir_isBackward = false;

      this.removePreview();
    }
  }

  public onPreviewPigRun() {
    console.log('PigRunManager: OnPreviewPigRun');
    console.log(this.selectedPigPath);

    this.selectedPigReport = '';
    this.removePreview();
    if (this.selectedPigPath) {
      this.pigRunPreview = L.polyline(
        this.selectedPigPath.generatedLineToLatLngs(),
        PigRunCommon.pigRunOptions
      );
      this.launchPreview = L.marker(
        L.latLng(
          this.selectedPigPath.launchSiteLat,
          this.selectedPigPath.launchSiteLon
        ),
        { icon: PigRunCommon.getIcon('LAUNCH') }
      );
      this.launchPreview.addTo(this.map);
      this.recievePreview = L.marker(
        L.latLng(
          this.selectedPigPath.recieveSiteLat,
          this.selectedPigPath.recieveSiteLon
        ),
        { icon: PigRunCommon.getIcon('RECEIVE') }
      );
      this.recievePreview.addTo(this.map);
      for (const uid in this.selectedPigPath.offlineAgms) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.selectedPigPath.offlineAgms,
            uid
          )
        ) {
          this.offlinePreviews[uid] = L.marker(
            L.latLng(
              this.selectedPigPath.offlineAgms[uid][0],
              this.selectedPigPath.offlineAgms[uid][1]
            ),
            { icon: MapCommon.agmIconOffline }
          ).bindPopup('<b>UID</b>: ' + uid);
          this.offlinePreviews[uid].addTo(this.map);
        }
      }

      if (this.selectedPigPath.markers) {
        for (const agmMarker of this.selectedPigPath.markers) {
          if (agmMarker.state === 'SKIP') {
            let icon;
            icon = PigRunCommon.skipIcon;
            if (
              Object.prototype.hasOwnProperty.call(agmMarker, 'description')
            ) {
              if (
                typeof agmMarker.description !== 'undefined' &&
                agmMarker.description !== 'AGM'
              ) {
                icon = PigRunCommon.getIcon(agmMarker.description!);
              }
            }
            const marker = L.marker(L.latLng(agmMarker.lat, agmMarker.lon), {
              icon,
              opacity: 0.0,
            })
              .bindTooltip(agmMarker.name)
              .addTo(this.map);
            this.agmMapMarkers.push(marker);
          } else {
            // TRACK
            const unassignedMarker = L.marker(
              L.latLng(agmMarker.lat, agmMarker.lon),
              { icon: PigRunCommon.getIcon('TRACK') }
            )
              .bindTooltip(agmMarker.name)
              .addTo(this.map);
            this.agmMapMarkers.push(unassignedMarker);
          }
        }
      }

      this.pigRunPreview.addTo(this.map);
      this.map.fitBounds(this.pigRunPreview.getBounds());
      if (this.selectedPigPath.pigLineKml) {
        this.kmlPreview = L.polyline(
          this.selectedPigPath.kmlLineToLatLngs(),
          PigRunCommon.kmlOptions
        );
        this.kmlPreview.addTo(this.map);
      }
    }

    this.showAvailablePigs();
  }

  public async onPreviewCompletedPigRun() {
    console.log('PigRunManager: OnPreviewCompletedPigRun');

    // Completed pig run broadcast command section - start.

    this.completedPigRunBroadcastCommandButtonDisable = false;

    // Completed pig run broadcast command section - end.

    const markerLayers: { [key: string]: L.Marker } = {};

    // Need a method to remove any completed run preview from a previous session
    // debugger
    this.removePreview();
    if (this.selectedPigReport) {
      let machineLearningResultsRequest: MachineLearningResultsRequest =
        new MachineLearningResultsRequest(this.selectedPigReport.runData.runId);

      await PigRunService.getMachineLearningResultsRequest(
        machineLearningResultsRequest
      )
        .then((response) => {
          this.$store.commit(
            'SET_MACHINE_LEARNING_PASSAGE_RESULT_LIST',
            response.data.machineLearningPassageResultList
          );
        })
        .catch((exception) => {
          console.error(exception);
        });

      this.pigRunPreview = L.polyline(
        this.selectedPigReport.runData.pigPath.generatedLineToLatLngs(),
        PigRunCommon.pigRunOptions
      );
      this.launchPreview = L.marker(
        L.latLng(
          this.selectedPigReport.runData.pigPath.launchSiteLat,
          this.selectedPigReport.runData.pigPath.launchSiteLon
        ),
        { icon: PigRunCommon.getIcon('LAUNCH') }
      ).bindTooltip(
        dayjs
          .default(this.selectedPigReport.runData.launchTime)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      );
      this.launchPreview.addTo(this.map);

      this.recievePreview = L.marker(
        L.latLng(
          this.selectedPigReport.runData.pigPath.recieveSiteLat,
          this.selectedPigReport.runData.pigPath.recieveSiteLon
        ),
        { icon: PigRunCommon.getIcon('RECEIVE') }
      ).bindTooltip(
        dayjs
          .default(this.selectedPigReport.runData.recieveTime)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')
      );

      this.recievePreview.addTo(this.map);

      if (this.selectedPigReport.runData.markers) {
        var local_psgs = this.selectedPigReport.runData.passages;

        for (const agmMarker of this.selectedPigReport.runData.markers) {
          if (agmMarker.state === 'SKIP') {
            let icon;
            icon = PigRunCommon.skipIcon;
            if (
              Object.prototype.hasOwnProperty.call(agmMarker, 'description')
            ) {
              if (
                typeof agmMarker.description !== 'undefined' &&
                agmMarker.description !== 'AGM'
              ) {
                icon = PigRunCommon.getIcon(agmMarker.description!);
              }
            }

            if (
              Object.prototype.hasOwnProperty.call(agmMarker, 'description')
            ) {
              if (agmMarker.description === 'AGM') {
                icon = PigRunCommon.getIcon(agmMarker.description!);
                const marker = L.marker(
                  L.latLng(agmMarker.lat, agmMarker.lon),
                  { icon: PigRunCommon.getIcon('TRACK_NotVerified') }
                )
                  .bindTooltip(agmMarker.name)
                  .addTo(this.map)

                  .on('click', (evt) => {
                    this.$store.dispatch('setSelectedMarkerEditor', agmMarker);
                  });
                this.agmMapMarkers.push(marker);
                markerLayers[agmMarker.name] = marker;
              } else {
                const marker = L.marker(
                  L.latLng(agmMarker.lat, agmMarker.lon),
                  { icon, opacity: 0.0 }
                )
                  .bindTooltip(agmMarker.name)
                  .addTo(this.map)

                  .on('click', (evt) => {
                    this.$store.dispatch('setSelectedMarkerEditor', agmMarker);
                  });
                this.agmMapMarkers.push(marker);
                markerLayers[agmMarker.name] = marker;
              }
            } else {
              const marker = L.marker(L.latLng(agmMarker.lat, agmMarker.lon), {
                icon,
                opacity: 0.0,
              });
              this.agmMapMarkers.push(marker);
              markerLayers[agmMarker.name] = marker;
            }
          } else {
            // TRACK
            // Check if marker has a verified passage

            const mrkerpsg_Idx = local_psgs.findIndex(
              (passage: MarkerPassage) =>
                passage.markerName === agmMarker.name &&
                passage.isVerified == true
            );
            var unassignedMarker;
            if (mrkerpsg_Idx > -1) {
              unassignedMarker = L.marker(
                L.latLng(agmMarker.lat, agmMarker.lon),
                { icon: PigRunCommon.getIcon('TRACK') }
              )
                .bindTooltip(agmMarker.name)
                .addTo(this.map)
                //.bindPopup(this.$children[2].$children[0].$el, {minWidth: 300})
                .on('click', (evt) => {
                  this.$store.dispatch('setSelectedMarkerEditor', agmMarker);
                });
            } else {
              unassignedMarker = L.marker(
                L.latLng(agmMarker.lat, agmMarker.lon),
                { icon: PigRunCommon.getIcon('TRACK_NotVerified') }
              )
                .bindTooltip(agmMarker.name)
                .addTo(this.map)
                //.bindPopup(this.$children[2].$children[0].$el, {minWidth: 300})
                .on('click', (evt) => {
                  this.$store.dispatch('setSelectedMarkerEditor', agmMarker);
                });
            }

            // const unassignedMarker = L.marker(L.latLng(agmMarker.lat, agmMarker.lon), {icon: PigRunCommon.getIcon('TRACK')})
            //     .bindTooltip(agmMarker.name)
            //     .addTo(this.map)
            //     //.bindPopup(this.$children[2].$children[0].$el, {minWidth: 300})
            //      .on('click', (evt) => {
            //      this.$store.dispatch('setSelectedMarkerEditor', agmMarker);

            //  });
            this.agmMapMarkers.push(unassignedMarker);
            markerLayers[agmMarker.name] = unassignedMarker;
            //unassignedMarker.bindPopup(this.$children[0].$children[0].$el, {minWidth: 300}).openPopup();
          }
        }
        var h = [];
        h.push(this.selectedPigReport.runData);
        h.push(markerLayers);
        this.$store.dispatch('setMarkerLayers', h);
      }

      this.pigRunPreview.addTo(this.map);
      this.map.fitBounds(this.pigRunPreview.getBounds());
      //this.showAvailablePigs();
    }
  }

  public onNewPigRun() {
    console.log('onNewPigRun()');
    this.removePreview();
    this.$store.dispatch('showPigRunEditor');
  }

  // onDeletePigRun.
  //
  // This event handler is poorly named, it is actually linked to the delete pig path event.
  //
  // This event handler is responsible for deleting PigPathBroadcastCommand records for a particular pathId, then
  // deleting the associated PigPath record.
  //
  // This event handler was modified during the broadcast commands development work.
  public async onDeletePigRun() {
    console.log('onDeletePigRun()');

    let aLocalStateVariable = 'Mitch localstateVariableValue';

    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Confirm delete of pig run',
        persistent: true,
        ok: {
          color: 'grey-8',
        },
        cancel: {
          color: 'grey-8',
        },
      })
      .onOk(() => {
        // Awaiting a web api service call is not permitted here. However, a callback function can be specified
        // here and invoked, wrapping the web api service call that needs to be awaited.
        //
        // This snippet also illustrates passing a local variable value as additional state to the callback if required. In this case,
        // the callback function ignores the local state variable value.
        this.onDeletePigPathDialogOkCallback(aLocalStateVariable);
      })
      .onCancel(() => {
        this.onDeletePigPathDialogCancelCallback(aLocalStateVariable);
      })
      .onDismiss(() => {
        // Triggered on both event and cancel.
      });
  }

  // onDeletePigPathDialogOkCallback.
  //
  // The event handler for the delete pig path dialog ok selected event.
  private async onDeletePigPathDialogOkCallback(anInput: string) {
    console.log('Mitch - onDeletePigPathDialogOkCallback executed.');
    console.log(
      'Mitch - onDeletePigPathDialogOkCallback. anInput = [' + anInput + ']'
    );

    this.removePreview();

    // Call the web api to delete the pig path and associated PigPathBroadcastCommand records.
    await PigRunService.deletePigPath(this.selectedPigRun.value)
      .then((response) => {
        console.log(
          'Mitch - onDeletePigPathDialogOkCallback. Before calling DELETE_PIG_PATH mutation.'
        );

        // Call the required Vuex mutation directly to update the relevant shared state member.
        this.$store.commit('DELETE_PIG_PATH', this.selectedPigRun.value);

        this.selectedPigRun = '';
        this.dir_isBackward = false;
      })
      .catch((exception) => {
        console.error(
          'onDeletePigPathDialogOkCallback. Exception = [' + exception + ']'
        );
      });
  }

  // onDeletePigPathDialogCancelCallback.
  //
  // The event handler for the delete pig path dialog cancel selected event.
  private async onDeletePigPathDialogCancelCallback(anInput: string) {
    console.log('Mitch - onDeletePigPathDialogCancelCallback executed.');
    console.log(
      'Mitch - onDeletePigPathDialogCancelCallback. anInput = [' + anInput + ']'
    );
  }

  // Broadcast commands - previous implementation (start).
  /*
    public onDeletePigRun() {
         console.log('onDeletePigRun()');
         this.$q.dialog({
             title: 'Confirm',
             message: 'Confirm delete of pig run',
             persistent: true,
             ok: {
                 color: 'grey-8',
             },
             cancel: {
                 color: 'grey-8',
             },
         }).onOk(() => {
             this.removePreview();
             this.$store.dispatch('deletePigPath', this.selectedPigRun.value);
             this.selectedPigRun = '';
             this.dir_isBackward = false;
         }).onCancel(() => {
             // console.log('>>>> Cancel')
         }).onDismiss(() => {
             // console.log('I am triggered on both OK and Cancel')
         });
     }
    */
  // Broadcast commands - previous implementation (end).

  public csvFile: File | null = null;

  public onExportPigRun() {
    if (
      this.selectedPigPath &&
      this.selectedPigPath.csvFileName &&
      this.selectedPigPath?.launchSiteLat &&
      this.selectedPigPath.launchSiteLon &&
      this.selectedPigPath.recieveSiteLat &&
      this.selectedPigPath.recieveSiteLon
    ) {
      // Add Launch
      let csvContent =
        'AGM Marker Name' +
        ',' +
        'Latitude' +
        ',' +
        'Longitude' +
        ',' +
        'State' +
        ',' +
        'Description' +
        '\r\n';
      csvContent =
        csvContent +
        'Launch' +
        ',' +
        this.selectedPigPath?.launchSiteLat +
        ',' +
        this.selectedPigPath?.launchSiteLon +
        ',' +
        'SKIP' +
        ',' +
        'Launch' +
        '\r\n';
      // Add Markers

      // Add Recieve

      this.selectedPigPath?.markers?.forEach(function (marker) {
        if (marker.description == undefined) {
          marker.description = 'AGM';
        }

        csvContent =
          csvContent +
          marker.name +
          ',' +
          marker.lat +
          ',' +
          marker.lon +
          ',' +
          marker.state +
          ',' +
          marker.description +
          '\r\n';

        // use this to export only AGM locations
        /*if(marker.description != "AGM")
                {

                }
                else
                {
                    csvContent = csvContent + marker.name + "," + marker.lat + "," + marker.lon+ "," + marker.state + "," + marker.description + "\r\n";
                }*/
        // use this to export only AGM locations
      });
      csvContent =
        csvContent +
        'Receive' +
        ',' +
        this.selectedPigPath?.recieveSiteLat +
        ',' +
        this.selectedPigPath?.recieveSiteLon +
        ',' +
        'SKIP' +
        ',' +
        'Receive';

      var pom = document.createElement('a');

      var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      var url = URL.createObjectURL(blob);
      pom.href = url;

      pom.setAttribute('download', this.selectedPigPath?.csvFileName);
      pom.click();
    }
  }

  public onEditPigRun() {
    console.log('onEditPigRun()');
    this.removePreview();
    this.$store.dispatch('showPigRunEditor', this.selectedPigPath);
    this.selectedPigRun = '';
    this.dir_isBackward = false;
  }

  public async onLaunchPigRun() {
    console.log('PigRunManager.vue. onLaunchPigRun. Enter method.');

    // Machine Learning - Populating the tool types from our ToolType table
    await PigRunService.getAllToolTypes()
      .then((response) => {
        // console.log("------------PigRunService.getAllToolTypes() RESPONSE-------------");
        // console.log(response);
        // console.log("----------------------------------------------------------------------");

        let toolNames = [];
        for (let i = 0; i < response.data.length; i++) {
          toolNames[i] = response.data[i].name;
        }

        this.launchPigToolTypeArray = toolNames;
      })
      .catch((exception) => {
        // console.log("------------PigRunService.getAllToolTypes() EXCEPTION-------------");
        // console.log(exception);
        // console.log("----------------------------------------------------------------------");
        console.error(exception);
        this.launchPigToolTypeArray = [];
      });

    // Display the dialog to select which pig.
    this.launchPigDialog = true;
  }

  public onDownloadReport() {
    console.log('onDownloadReport()');
    // open dialog box
    this.Report_Dialog = true;
    //this.$store.dispatch('downloadReport', this.selectedPigReport.runData);
  }

  public DownloadReportSelected() {
    if (this.selectedReport_Type == 'Default') {
      this.$store.dispatch('downloadReport', [
        this.selectedPigReport.runData,
        this.selectedReport_Extension,
      ]);
    } else if (this.selectedReport_Type == 'Summary') {
      this.$store.dispatch('downloadReport_Summary', [
        this.selectedPigReport.runData,
        this.selectedReport_Extension,
      ]);
    }
    this.Report_Dialog = false;
  }

  public Download_SummaryReport() {
    // empty for now
  }

  public onSynchRun() {
    console.log('onSynchRun()');
    console.log(this.selectedPigReport.value); // Run Id
    this.$store.dispatch('SynchFinishedPigRun', this.selectedPigReport.value);
    //this.$store.dispatch('deletePigRun', this.selectedPigReport.value);
  }

  public onDeleteReport() {
    console.log('onDeleteReport()');

    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Confirm delete of pig report',
        persistent: true,
        ok: {
          color: 'grey-8',
        },
        cancel: {
          color: 'grey-8',
        },
      })
      .onOk(() => {
        this.$store.dispatch('deletePigRun', this.selectedPigReport.value);
        this.selectedPigReport = '';
        this.ClearCompletedRun();
      })
      .onCancel(() => {
        // console.log('>>>> Cancel')
      })
      .onDismiss(() => {
        // console.log('I am triggered on both OK and Cancel')
      });
  }

  public EditTimeDialog = false;
  public ReceiveCompTimestamp = '';
  public LaunchCompTimestamp = '';

  public onEditTime() {
    this.EditTimeDialog = true;
    this.ErrorMsg = false;

    var year = this.selectedPigReport.runData.launchTime.getUTCFullYear();
    var month = this.selectedPigReport.runData.launchTime.getUTCMonth() + 1;
    var day = this.selectedPigReport.runData.launchTime.getUTCDate();
    var hour = this.selectedPigReport.runData.launchTime.getUTCHours();
    var min = this.selectedPigReport.runData.launchTime.getUTCMinutes();
    var sec = this.selectedPigReport.runData.launchTime.getUTCSeconds();
    var time_string = year + '-';
    if (month < 10) {
      time_string = time_string + '0' + month + '-';
    } else {
      time_string = time_string + month + '-';
    }
    if (day < 10) {
      time_string = time_string + '0' + day + ' ';
    } else {
      time_string = time_string + day + ' ';
    }
    if (hour < 10) {
      time_string = time_string + '0' + hour + ':';
    } else {
      time_string = time_string + hour + ':';
    }
    if (min < 10) {
      time_string = time_string + '0' + min + ':';
    } else {
      time_string = time_string + min + ':';
    }
    if (sec < 10) {
      time_string = time_string + '0' + sec;
    } else {
      time_string = time_string + sec;
    }
    this.LaunchCompTimestamp = time_string;

    year = this.selectedPigReport.runData.recieveTime.getUTCFullYear();
    month = this.selectedPigReport.runData.recieveTime.getUTCMonth() + 1;
    day = this.selectedPigReport.runData.recieveTime.getUTCDate();
    hour = this.selectedPigReport.runData.recieveTime.getUTCHours();
    min = this.selectedPigReport.runData.recieveTime.getUTCMinutes();
    sec = this.selectedPigReport.runData.recieveTime.getUTCSeconds();
    time_string = year + '-';
    if (month < 10) {
      time_string = time_string + '0' + month + '-';
    } else {
      time_string = time_string + month + '-';
    }
    if (day < 10) {
      time_string = time_string + '0' + day + ' ';
    } else {
      time_string = time_string + day + ' ';
    }
    if (hour < 10) {
      time_string = time_string + '0' + hour + ':';
    } else {
      time_string = time_string + hour + ':';
    }
    if (min < 10) {
      time_string = time_string + '0' + min + ':';
    } else {
      time_string = time_string + min + ':';
    }
    if (sec < 10) {
      time_string = time_string + '0' + sec;
    } else {
      time_string = time_string + sec;
    }
    this.ReceiveCompTimestamp = time_string;
  }

  public onEditCompTime() {
    console.log('PigRunManager.vue. onEditCompTime. Enter method.');

    // There is a problem with launch time. Currently the expected pigrun launch time input should be in local and not in UTC
    // Because Pig Progress Marker is using time elapsed which uses local time of the machine.
    // A work around is to allow the user to choose the launch time in UTC then convert the time back to local.
    // Then use the local time as input to PigRun Launch Time
    // Or we need to change time calculations in PigProgress Marker so it is based on utc
    var hour, min, sec, year, month, day;
    var LaunchDate: Date;
    var ReceiveDate: Date;
    var acttime;

    if (
      this.LaunchCompTimestamp == null ||
      this.LaunchCompTimestamp == undefined ||
      this.LaunchCompTimestamp == ''
    ) {
      this.ErrorMsg = true;
      this.ErrorMsgString = 'Error ! Launch Time is Empty.';

      console.log('PigRunManager.vue. onEditCompTime. Launch Time is Empty.');

      return;
    }

    if (
      this.ReceiveCompTimestamp == null ||
      this.ReceiveCompTimestamp == undefined ||
      this.ReceiveCompTimestamp == ''
    ) {
      this.ErrorMsg = true;
      this.ErrorMsgString = 'Error ! Receive Time is Empty.';

      console.log('PigRunManager.vue. onEditCompTime. Launch Time is Empty.');

      return;
    }

    acttime = this.LaunchCompTimestamp;

    // UTC Time
    year = acttime[0] + acttime[1] + acttime[2] + acttime[3];
    month = acttime[5] + acttime[6];
    day = acttime[8] + acttime[9];
    hour = acttime[11] + acttime[12];
    min = acttime[14] + acttime[15];
    sec = acttime[17] + acttime[18];

    //UTC Time
    LaunchDate = new Date();
    LaunchDate.setUTCFullYear(parseInt(year));
    LaunchDate.setUTCMonth(parseInt(month) - 1);
    LaunchDate.setUTCDate(parseInt(day));
    LaunchDate.setUTCHours(parseInt(hour));
    LaunchDate.setUTCMinutes(parseInt(min));
    LaunchDate.setUTCSeconds(parseInt(sec));
    LaunchDate.setUTCMilliseconds(0);

    if (isNaN(LaunchDate.getTime())) {
      console.log(
        'PigRunManager.vue. onEditCompTime. Launch Time format Error.'
      );

      this.ErrorMsgString =
        'Please enter valid Launch Time {YYYY-MM-DD HH:MM:SS}';
      this.ErrorMsg = true;

      return;
    }

    acttime = this.ReceiveCompTimestamp;

    //UTC Time
    year = acttime[0] + acttime[1] + acttime[2] + acttime[3];
    month = acttime[5] + acttime[6];
    day = acttime[8] + acttime[9];
    hour = acttime[11] + acttime[12];
    min = acttime[14] + acttime[15];
    sec = acttime[17] + acttime[18];

    //UTC Time
    ReceiveDate = new Date();
    ReceiveDate.setUTCFullYear(parseInt(year));
    ReceiveDate.setUTCMonth(parseInt(month) - 1);
    ReceiveDate.setUTCDate(parseInt(day));
    ReceiveDate.setUTCHours(parseInt(hour));
    ReceiveDate.setUTCMinutes(parseInt(min));
    ReceiveDate.setUTCSeconds(parseInt(sec));
    ReceiveDate.setUTCMilliseconds(0);

    if (isNaN(ReceiveDate.getTime())) {
      console.log(
        'PigRunManager.vue. onEditCompTime. Receive Time format Error.'
      );

      this.ErrorMsgString =
        'Please enter valid Receive Time {YYYY-MM-DD HH:MM:SS}';
      this.ErrorMsg = true;

      return;
    }

    //TDO. Need to validate Launch Time is not in future
    var NowDate: Date;
    NowDate = new Date();
    var diff = LaunchDate.getTime() - NowDate.getTime();

    if (diff > 0) {
      // Error Passage Time is before Launch Time
      console.log(
        'PigRunManager.vue. onEditCompTime. Launch Time entered is in future.'
      );

      this.ErrorMsg = true;
      this.ErrorMsgString = 'Error ! Launch Time entered is in the Future.';

      return;
    }

    //TDO. Need to validate Receive Time is not in future
    diff = ReceiveDate.getTime() - NowDate.getTime();

    if (diff > 0) {
      // Error Passage Time is before Launch Time
      console.log(
        'PigRunManager.vue. onEditCompTime. Receive Time entered is in future.'
      );

      this.ErrorMsg = true;
      this.ErrorMsgString = 'Error ! Receive Time entered is in the Future.';

      return;
    }

    //TDO. Need to validate Receive Time is not before launch time
    diff = LaunchDate.getTime() - ReceiveDate.getTime();

    if (diff > 0) {
      // Error Passage Time is before Launch Time
      console.log(
        'PigRunManager.vue. onEditCompTime. Receive Time entered is before Launch Time.'
      );

      this.ErrorMsg = true;
      this.ErrorMsgString =
        'Error ! Receive Time entered is before Launch Time.';

      return;
    }

    //TDO. Need to validate launch time is not after first verified passage time
    if (this.ComparetoVerifiedPassages(LaunchDate, true) == false) {
      // Error Launch Time is after a Verified passage time
      console.log(
        'PigRunManager.vue. onEditCompTime. Entered Launch time is ahead of Verified Passage Time.'
      );

      this.ErrorMsg = true;
      this.ErrorMsgString =
        'Entered Launch time is ahead of Verified Passage Time.';

      return;
    }

    //TDO. Need to validate Receive Time is not before last verified passage time
    if (this.ComparetoVerifiedPassages(ReceiveDate, false) == false) {
      // Error Launch Time is after a Verified passage time
      console.log(
        'PigRunManager.vue. onEditCompTime. Entered Receive time is before of Verified Passage Time.'
      );

      this.ErrorMsg = true;
      this.ErrorMsgString =
        'Entered Receive time is before of Verified Passage Time.';

      return;
    }

    //TDO. Send update for completed run
    this.selectedPigReport.runData.launchTime = LaunchDate;
    this.selectedPigReport.runData.recieveTime = ReceiveDate;

    console.log(
      'PigRunManager.vue. onEditCompTime. Before dispatching action upsertFinishedPigRun.'
    );

    this.$store.dispatch(
      'upsertFinishedPigRun',
      this.selectedPigReport.runData
    );

    this.EditTimeDialog = false;
  }

  public ComparetoVerifiedPassages(time: Date, islaunch: boolean) {
    if (
      this.selectedPigReport.runData != null &&
      this.selectedPigReport.runData != undefined &&
      this.selectedPigReport.runData.passages != null &&
      this.selectedPigReport.runData.passages != undefined
    ) {
      var verified_passages = this.selectedPigReport.runData.passages.filter(
        (el: any) => el.isVerified == true
      );

      for (var i = 0; i < verified_passages.length; i++) {
        var psg_time: Date = new Date(
          verified_passages[i].passageTimeStamp + ' GMT'
        );
        var diff = 0;
        if (islaunch == true) {
          diff = time.getTime() - psg_time.getTime();
        } else {
          diff = psg_time.getTime() - time.getTime();
        }

        if (diff > 0) {
          return false;
        }
      }
    }
    return true;
  }

  public onCancelPigRun() {
    this.selectedPigRun = '';
    this.selectedPigReport = '';
    this.dir_isBackward = false;
    this.removePreview();
    this.$store.dispatch('hidePigRunManager');
  }

  public removePreview() {
    if (this.pigRunPreview) {
      this.pigRunPreview.removeFrom(this.map);
      this.pigRunPreview = null;
    }
    if (this.kmlPreview) {
      this.kmlPreview.removeFrom(this.map);
      this.kmlPreview = null;
    }
    if (this.launchPreview) {
      this.launchPreview.removeFrom(this.map);
      this.launchPreview = null;
    }
    if (this.recievePreview) {
      this.recievePreview.removeFrom(this.map);
      this.recievePreview = null;
    }
    if (this.offlinePreviews) {
      for (const uid in this.offlinePreviews) {
        if (Object.prototype.hasOwnProperty.call(this.offlinePreviews, uid)) {
          this.offlinePreviews[uid].removeFrom(this.map);
        }
      }
    }
    for (let i = 0; i < this.agmMapMarkers.length; i++) {
      this.agmMapMarkers[i].removeFrom(this.map);
    }
    this.agmMapMarkers = [];
  }

  public onaddTimeLaunchDialog() {
    this.Launch_at_TimeDialog = true;
    this.LaunchTimestamp = dayjs.default().utc().format('YYYY-MM-DD HH:mm:ss'); // Displays the time right now in UTC
    //this.LaunchTimestamp = dayjs.default().format('YYYY-MM-DD HH:mm:ss');
    this.ErrorMsg = false;
  }

  public ErrorMsg = false;
  public ErrorMsgString = 'Error ! Launch can not be set to future time.';

  public async onAddLaunchTime() {
    console.log('PigRunManager.vue. onAddLaunchTime. Enter method.');

    // There is a problem with launch time. Currently the expected pigrun launch time input should be in local and not in UTC
    // Because Pig Progress Marker is using time elapsed which uses local time of the machine.
    // A work around is to allow the user to choose the launch time in UTC then convert the time back to local.
    // Then use the local time as input to PigRun Launch Time
    // Or we need to change time calculations in PigProgress Marker so it is based on utc
    var hour, min, sec, year, month, day, milisec;
    var LaunchDate: Date;
    var acttime;

    if (
      this.LaunchTimestamp == null ||
      this.LaunchTimestamp == undefined ||
      this.LaunchTimestamp == ''
    ) {
      this.ErrorMsg = true;
      this.Launch_at_TimeDialog = true;
      this.launchPigDialog = true;
      this.ErrorMsgString = 'Error ! Invalid format.';

      return;
    }

    acttime = this.LaunchTimestamp;

    // UTC Time.
    year = acttime[0] + acttime[1] + acttime[2] + acttime[3];
    month = acttime[5] + acttime[6];
    day = acttime[8] + acttime[9];
    hour = acttime[11] + acttime[12];
    min = acttime[14] + acttime[15];
    sec = acttime[17] + acttime[18];
    milisec = '000';

    //UTC Time.
    LaunchDate = new Date();
    LaunchDate.setUTCFullYear(parseInt(year));
    LaunchDate.setUTCMonth(parseInt(month) - 1);
    LaunchDate.setUTCDate(parseInt(day));
    LaunchDate.setUTCHours(parseInt(hour));
    LaunchDate.setUTCMinutes(parseInt(min));
    LaunchDate.setUTCSeconds(parseInt(sec));
    LaunchDate.setUTCMilliseconds(0);

    // Need to compare entered time to Now.
    var NowDate = new Date();
    var diff;
    diff = NowDate.getTime() - LaunchDate.getTime();

    if (diff < 0) {
      // Error.
      this.ErrorMsg = true;
      this.Launch_at_TimeDialog = true;
      this.launchPigDialog = true;
      this.ErrorMsgString = 'Error ! Launch can not be set to future time.';
    } else {
      this.Launch_at_TimeDialog = false;
      this.launchPigDialog = false;

      const path = this.selectedPigPath;
      const pig = this.selectedPig;

      if (path && pig) {
        const markerCopy: AgmMarker[] = [];

        if (path.markers) {
          path.markers.forEach((marker: AgmMarker) => {
            // Set the marker autoLink property to true by default for all markers with a state of TRACK when a pig run is launched.
            if (marker.state != undefined && marker.state == 'TRACK') {
              marker.autoLink = true;
            }

            // December 5, 2024.
            //
            // Previous implementation.
            //
            // Set the marker autoLink property to true by default when a pig run is launched.
            // marker.autoLink = true;

            markerCopy.push(marker);
          });
        }

        // Calculate Distance between launch and each marker location
        const line = turfHelpers.lineString(path.pigLineGenerated);

        markerCopy.forEach((marker: AgmMarker) => {
          marker.DistanceFromLaunch = turf.nearestPointOnLine(
            line,
            turf.point([marker.lon, marker.lat]),
            { units: 'meters' }
          ).properties.location;

          if (marker.DistanceFromLaunch) {
            marker.DistanceFromLaunch =
              Math.floor(marker.DistanceFromLaunch * 1000) / 1000;
          }
        });

        const pr = new PigRun(
          -1, // ID will be created from DB
          path,
          pig,
          LaunchDate, // dayjs.utc().toDate(), // new Date(), // launchTime
          undefined, // recieveTime
          undefined, // calculatedSpeed
          undefined, // passages
          markerCopy // copy of the path markers
        );

        console.log(
          'PigRunManager.vue. onAddLaunchTime. Before dispatching action upsertPigRun.'
        );

        this.$store.dispatch('upsertPigRun', pr);

        // PigProgress component manages its own map data so remove all previews/selections
        this.selectedPigRun = '';
        this.selectedPig = '';
        this.dir_isBackward = false;

        this.removePreview();
      }

      // If expected speed is undefined we cannot continue
      if (
        this.$store.state.expectedSpeed == undefined ||
        this.$store.state.expectedSpeed == null
      ) {
        console.log(
          'PigRunManager.vue onAddLaunchTime(). Aborting machine learning toolId check due to expectedSpeed being null.'
        );
        return;
      }

      // If pipeline diameter is undefined we cannot continue
      if (
        this.$store.state.pipelineDiameter == undefined ||
        this.$store.state.pipelineDiameter == null
      ) {
        console.log(
          'PigRunManager.vue onAddLaunchTime(). Aborting machine learning toolId check due to pipeline diameter being null.'
        );
        return;
      }

      // Finding our toolTypeId
      let toolTypeId = -1;
      await PigRunService.getAllToolTypes()
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            if (this.launchPigToolType == response.data[i].name) {
              toolTypeId = response.data[i].toolTypeId;
              console.log(
                `PigRunManager.vue PigRunService.getAllToolTypes(). Found toolTypeId = ${toolTypeId} with name ${this.launchPigToolType}.`
              );
              break;
            }
          }
        })
        .catch((exception) => {
          console.error(exception);
          return;
        });

      // Getting a toolId
      let toolId = -1;
      // The vue store state expected speed is stored in miles per hour and must be converted to meters per second.
      // The vue store state pipeline diameter is in inches and must be converted to meters.
      let getToolRequest: GetToolRequest = new GetToolRequest(
        this.$store.state.expectedSpeed / 2.237,
        this.$store.state.pipelineDiameter / 39.37,
        toolTypeId
      );
      await PigRunService.getTool(getToolRequest)
        .then((response) => {
          toolId = response.data.toolId;
          console.log(
            `PigRunManager.vue PigRunService.getTool(). Set toolId to ${toolId} using expectedSpeed: ${this.$store.state.expectedSpeed}, pipelineDiameter: ${this.$store.state.pipelineDiameter} and toolTypeId: ${toolTypeId}.`
          );
        })
        .catch((exception) => {
          console.error(exception);
          return;
        });

      // If we don't have a valid runId, return
      if (
        this.$store.state.pigRunAddRunId == undefined ||
        this.$store.state.pigRunAddRunId == null
      ) {
        console.log(
          `PigRunManager.vue pigRunAddRunId is ${this.$store.state.pigRunAddRunId}, returning.`
        );
        return;
      }

      // Inserting a row into the PigRunTool table using toolId and runId
      let addPigRunToolRequest: AddPigRunToolRequest = new AddPigRunToolRequest(
        this.$store.state.pigRunAddRunId,
        toolId
      );
      await PigRunService.addPigRunTool(addPigRunToolRequest)
        .then((response) => {
          console.log(response);
          console.log(
            `PigRunManager.vue PigRunService.addPigRunTool(). Set toolId to ${toolId} using pigRunAddRunId: ${this.$store.state.pigRunAddRunId} and toolId: ${toolId}. Generated PK PigRunToolId ${response.data}.`
          );
        })
        .catch((exception) => {
          console.error(exception);
          return;
        });

      // If this.launchPigJobNumber is an empty string, we set it to null
      if (this.launchPigJobNumber == '') {
        this.launchPigJobNumber = null;
      }

      // Updating Job Number
      let updatePigRunJobNumberRequest: UpdatePigRunJobNumberRequest =
        new UpdatePigRunJobNumberRequest(
          this.launchPigJobNumber,
          this.$store.state.pigRunAddRunId
        );
      await PigRunService.updateJobNumber(updatePigRunJobNumberRequest)
        .then((response) => {
          console.log(
            `PigRunManager.vue PigRunService.updateJobNumber(). Set job number to ${this.launchPigJobNumber} for run id ${this.$store.state.pigRunAddRunId}.`
          );
        })
        .catch((exception) => {
          console.error(exception);
          return;
        });
    }

    this.$store.dispatch('hidePigRunManager');
  }

  public onRefreshcompRun(payload: PigRun) {
    if (this.selectedPigReport) {
      console.log('PigRunManager: refreshPigRun - DO UPDATE');
      if (payload.runId === this.selectedPigReport.runData.runId) {
        console.log(
          'PigRunManager: Run Updated at Run Id = ' +
            this.selectedPigReport.runData.runId
        );
        this.selectedPigReport.runData = payload;
      }
    }
  }

  public addAgmsDialog = false;
  public tmpSelectedAgms: number[] = [];
  public agmUidOptions: any = [];
  public agms: number[] = [];

  public onAddAgmsDialog() {
    this.addAgmsDialog = true;

    if (this.selectedPigReport) {
      this.agms = this.selectedPigReport.runData.pigPath.agms;
      this.tmpSelectedAgms = [...this.agms];
    }

    this.$store.state.devices.forEach((device: Device) => {
      this.agmUidOptions.push({
        label: 'AGM UID ' + device.uid,
        value: device.uid,
      });
    });
  }

  public onAgmListChanged() {
    this.addAgmsDialog = false;
    // remove changes
    this.agms.forEach((removedUid: number) => {
      if (!this.tmpSelectedAgms.includes(removedUid)) {
        this.agms = this.agms.filter((uid: number) => uid !== removedUid);
      }
    });
    // add changes
    this.tmpSelectedAgms.forEach((changedUid: number) => {
      if (!this.agms.includes(changedUid)) {
        this.agms.push(changedUid);
      }
    });

    this.selectedPigReport.runData.pigPath.agms = [...this.tmpSelectedAgms];
    this.$store.dispatch(
      'upsertFinishedPigRun',
      this.selectedPigReport.runData
    );

    this.agms = [];
    this.tmpSelectedAgms = [];
    this.agmUidOptions = [];
  }

  public onAgmListCancelled() {
    this.agms = [];
    this.tmpSelectedAgms = [];
    this.agmUidOptions = [];
  }

  public filterCompletedRuns(
    val: string,
    update: (options: any[]) => void,
    abort: () => void
  ) {
    if (val === '') {
      this.filteredCompletedRunOptions = this.completedRunOptions;
      update(this.filteredCompletedRunOptions);
      return;
    }
    const needle = val.toLowerCase();
    this.filteredCompletedRunOptions = this.completedRunOptions.filter(
      (option: any) => option.label.toLowerCase().includes(needle)
    );
    update(this.filteredCompletedRunOptions);
  }

  public filterPigRuns(
    val: string,
    update: (options: any[]) => void,
    abort: () => void
  ) {
    if (val === '') {
      this.filteredPigRunOptions = this.pigRunOptions;
      update(this.filteredPigRunOptions);
      return;
    }
    const needle = val.toLowerCase();
    this.filteredPigRunOptions = this.pigRunOptions.filter((option: any) =>
      option.label.toLowerCase().includes(needle)
    );
    update(this.filteredPigRunOptions);
  }

  public mounted() {
    this.filteredCompletedRunOptions = this.completedRunOptions;
    this.filteredPigRunOptions = this.pigRunOptions;
    console.log('PigRunManager: mounted()');
  }
}
