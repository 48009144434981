// UpdatePigRunJobNumberRequest.
//
// Class representing a request to update a job number from a runId
export class UpdatePigRunJobNumberRequest {
  // jobNumber
  public jobNumber: string | undefined;

  // runId
  public runId: number;

  // Constructor.
  public constructor(jobNumber: string | undefined, runId: number) {
    this.jobNumber = jobNumber;
    this.runId = runId;
  }
}
