
import { Component, Vue } from 'vue-property-decorator';
import { PigRun } from '../models/PigRun';
import { ReportDataForCompletedPigRunRequest } from '@/models/ReportManager/ReportDataForCompletedPigRunRequest';
import PigRunService from '@/services/PigRunService';
import { Device } from '../models/Device';
import DateCommon from '../utils/DateCommon';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { ReportDataForDevicesRequest } from '@/models/ReportManager/ReportDataForDevicesRequest';
import { PassageVerificationRequest } from '@/models/ReportManager/PassageVerificationRequest';
import { DeleteManualPassageRequest } from '@/models/ReportManager/DeleteManualPassageRequest';
import { DownloadRecordedPassageDataRequest } from '@/models/ReportManager/DownloadRecordedPassageDataRequest';
import { DownloadRecordedPassageDataFirmwareValidationRequest } from '@/models/ReportManager/DownloadRecordedPassageDataFirmwareValidationRequest';
import { EventBus } from '@/utils/EventBus';
import { IPassageVerified } from '@/models/EventBus/PassageVerified';
import DisplayMLResult, {
  MachineLearningResultColors,
} from '@/utils/DisplayMLResult';

@Component
export default class ReportManager extends Vue {
  // ----------------------------------------------------------------------------------------------------------------------------------------------
  // Component wide section.
  // ----------------------------------------------------------------------------------------------------------------------------------------------

  // Validation dialog properties.
  public showValidationMessageDialog = false;
  public validationDialogMessage = '';
  public validationDialogTitle = '';

  // Property for the report type v-model.
  public selectedReportType: any = '';

  // Property corresponding to the display of the completed pig run report query controls.
  public showReportTypeCompletedPigRun = false;

  // Property corresponding to the display of the device report query controls.
  public showReportTypeDevice = false;

  // Component method to format an altitude for display.
  public formatAltitude(altitude: any, prefixComma: boolean): string {
    let altitudeReturnValue = '';

    if (altitude != undefined) {
      let altitudeAsNumber: number = parseInt(altitude, 10);

      if (altitudeAsNumber > -1000) {
        if (prefixComma) {
          altitudeReturnValue =
            ', ' + (altitudeAsNumber / 1000).toFixed(1).toString();
        } else {
          altitudeReturnValue = (altitudeAsNumber / 1000).toFixed(1).toString();
        }
      }
    }

    return altitudeReturnValue;
  }

  // Component method to format a coordinate for display.
  public formatCoordinate(coordinate: any, prefixComma: boolean): string {
    let coordinateReturnValue = '';

    if (coordinate != undefined) {
      let coordinateAsNumber: number = parseFloat(coordinate);

      if (prefixComma) {
        coordinateReturnValue = ', ' + coordinateAsNumber.toFixed(6).toString();
      } else {
        coordinateReturnValue = coordinateAsNumber.toFixed(6).toString();
      }
    }

    return coordinateReturnValue;
  }

  // Component method to determine how many passage types (elf, geo, and mag) are available. This is used in rendering the body-cell-graph-data slot.
  public isOnlySingleTypeOfPassage(props: any) {
    let passageTypeCount = 0;

    // props.row.elfId.
    if (props.row.elfId != undefined) {
      passageTypeCount++;
    }

    // props.row.geoId.
    if (props.row.geoId != undefined) {
      passageTypeCount++;
    }

    // props.row.magId.
    if (props.row.magId != undefined) {
      passageTypeCount++;
    }

    if (passageTypeCount != 1) {
      return false;
    }

    return true;
  }

  // Lifecycle hook.
  public mounted() {
    console.log('ReportManager.vue. mounted.');
    EventBus.$on('passage-verified', this.handlePassageVerified);
  }

  beforeDestroy() {
    EventBus.$off('passage-verified', this.handlePassageVerified);
  }

  handlePassageVerified({ passage }: IPassageVerified) {
    console.log('ReportManager.vue. passage-verified event received.');
    const { passageId, isVerified } = passage;

    this.devicesReportDataArray.forEach((p) => {
      if (
        p.elfId === passageId ||
        p.geoId === passageId ||
        p.magId === passageId
      ) {
        p.isVerified = isVerified;
      }
    });
  }

  // Component method for the report manager close handler.
  public onReportManagerCloseClick() {
    console.log('ReportManager.vue. onReportManagerCloseClick.');

    this.$store.dispatch('showPigRunManager');
  }

  public getMLResultColor(data: any): MachineLearningResultColors {
    return DisplayMLResult(data).color;
  }

  public getMLResultConfidence(data: any): string {
    return ' ' + DisplayMLResult(data).confidence + '%';
  }

  public getMLIcon(color: MachineLearningResultColors): string {
    if (color.includes('red')) {
      return 'block';
    } else if (color.includes('green')) {
      return 'check_circle_outline';
    } else {
      return 'help';
    }
  }

  // Component method used to show a passage chart. This is called from the rendered body-cell-graph-data slot.
  //
  // Note: This component method dispatches the action named 'passageChartShow'. The PassageDialog.vue component
  //       subscribes to receive event notification of this action, causing it to be displayed. Refer
  //       to index.ts (action definition) and PassageDialog.vue (action subscription).
  public showChart(props: any, passageType: string) {
    console.log('ReportManager.vue. showChart().');

    // Create an array of the passage ID values.
    let passageIdsToUpdate = [];

    // props.row.elfId.
    if (props.row.elfId != undefined) {
      console.log(
        'ReportManager.vue. showChart. props.row.elfId = [' +
          props.row.elfId +
          ']'
      );

      passageIdsToUpdate.push(props.row.elfId);
    }

    // props.row.geoId.
    if (props.row.geoId != undefined) {
      console.log(
        'ReportManager.vue. showChart. props.row.geoId = [' +
          props.row.geoId +
          ']'
      );

      passageIdsToUpdate.push(props.row.geoId);
    }

    // props.row.magId.
    if (props.row.magId != undefined) {
      console.log(
        'ReportManager.vue. showChart. props.row.magId = [' +
          props.row.magId +
          ']'
      );

      passageIdsToUpdate.push(props.row.magId);
    }

    // Dispatch the action addPassageIdsToUpdate.
    this.$store.dispatch('addPassageIdsToUpdate', passageIdsToUpdate);

    // Dispatch the action setPassageDialogSource.
    this.$store.dispatch('setPassageDialogSource', 'report_manager');

    console.log('markus', props.row);

    const commonFields = {
      uid: props.row.uId,
      detectionCount: props.row.detectionCount,
      passageTimeStamp: props.row.passageTimeStamp,
      isVerified: props.row.isVerified,
      elfMachineLearningResultList: props.row.elfMachineLearningResultList,
      geoMachineLearningResultList: props.row.geoMachineLearningResultList,
      magMachineLearningResultList: props.row.magMachineLearningResultList,
    };

    if (passageType == 'ELF') {
      // At this point, prepare an input object to dispatch the passageChartShow action.
      let passageChartShowInputParameters = {
        ...commonFields,
        passageId: props.row.elfId,
      };

      // Dispatch the passageChartShow action.
      //
      // Note: The PassageDialog.vue component has an action subscription to passageChartShow.
      this.$store.dispatch('passageChartShow', passageChartShowInputParameters);
    }

    if (passageType == 'GEO') {
      // At this point, prepare an input object to dispatch the passageChartShow action.
      let passageChartShowInputParameters = {
        ...commonFields,
        passageId: props.row.geoId,
      };

      // Dispatch the passageChartShow action.
      //
      // Note: The PassageDialog.vue component has an action subscription to passageChartShow.
      this.$store.dispatch('passageChartShow', passageChartShowInputParameters);
    }

    if (passageType == 'MAG') {
      // At this point, prepare an input object to dispatch the passageChartShow action.
      let passageChartShowInputParameters = {
        ...commonFields,
        passageId: props.row.magId,
      };

      // Dispatch the passageChartShow action.
      //
      // Note: The PassageDialog.vue component has an action subscription to passageChartShow.
      this.$store.dispatch('passageChartShow', passageChartShowInputParameters);
    }
  }

  // Computed property getter corresponding to the display of the report manager component.
  get showReportManager() {
    return this.$store.state.showReportManager;
  }

  // Computed property getter corresponding to the options for the report type q-select control.
  get reportTypeOptions() {
    return [
      { label: 'By Completed Pig Run', value: 'CompletedPigRun' },
      { label: 'By Agm(s)', value: 'Device' },
    ];
  }

  // Component method corresponding to the report type q-select control v-model class property.
  public onReportTypeSelect() {
    console.log('ReportManager.vue. onReportTypeSelect.');

    // If the selected report type is device.
    if (this.selectedReportType.value == 'Device') {
      console.log(
        'ReportManager.vue. onReportTypeSelect. Selected report type = [Device].'
      );

      this.showReportTypeCompletedPigRun = false;
      this.showReportTypeDevice = true;
    }

    // If the selected report type is completed pig run.
    if (this.selectedReportType.value == 'CompletedPigRun') {
      console.log(
        'ReportManager.vue. onReportTypeSelect. Selected report type = [CompletedPigRun].'
      );

      this.showReportTypeCompletedPigRun = true;
      this.showReportTypeDevice = false;
    }
  }

  // --------------------------------------------------------------------------------------------------------------------------------------------------------
  // Device(s) report section.
  // --------------------------------------------------------------------------------------------------------------------------------------------------------

  // Property for the display of the enter parameters for the device report dialog.
  public showEnterParametersForDevicesReportDialog = false;

  // Property for the start date of the devices report parameters (as string and as native Date).
  public startDateDevicesReport = '';
  public startDateDevicesReportAsDate: Date | undefined;

  // Property for the end date of the devices report parameters (as string and as native Date).
  public endDateDevicesReport = '';
  public endDateDevicesReportAsDate: Date | undefined;

  // Property for the selected uid value checkboxes of the devices report parameters.
  public selectedDevicesForDevicesReport: number[] = [];

  // Property for the devices report data dialog.
  public showDevicesReportDialog = false;

  // Property for the pagination.sync binding of the devices report q-table control.
  public devicesReportDataPagination: Record<string, unknown> = {};

  // Property for the underlying data array referenced by the q-table control via the devicesReportData getter.
  private devicesReportDataArray: any[] = [];

  // Property for displaying the export options for the devices report.
  public exportOptionsVisibleDevicesReport = false;

  // Property that maps to the include verified passages checkbox of the devices report.
  public includeVerifiedPassagesOnlyDevicesReport = false;

  // Property for the devices report generate report data loading.
  public devicesReportGenerateReportLoading = false;

  // Computed property getter for the data binding of the q-table control.
  get devicesReportData(): any {
    return this.devicesReportDataArray;
  }

  // Computed property getter for the device(s) report columns.
  get devicesReportColumns(): any {
    // Note:
    //
    // The name attribute must match the slot name noted above in the markup section.
    //
    //     name: Unique identifier for column (used by pagination.sortBy, "body-cell-[name]" slot, ...).
    //     required: (optional) if visible-columns, this column will always be visible.
    let devicesReportColumns = [
      {
        align: 'left',
        name: 'passageTimeStamp',
        label: 'Passage',
        field: 'passageTimeStamp',
        sortable: true,
        required: true,
      },
      { align: 'left', name: 'pigRunName', label: 'Pig Run', sortable: false },
      {
        align: 'left',
        name: 'markerName',
        label: 'Marker Name',
        sortable: true,
      },
      { align: 'left', name: 'toolName', label: 'Tool Name', sortable: false },
      {
        align: 'left',
        name: 'coordinates',
        label: 'Coordinates - Lat, Lon, Altitude (m)',
        sortable: false,
      },
      {
        align: 'left',
        name: 'UId',
        label: 'UID',
        field: 'uId',
        sortable: true,
      },
      {
        align: 'left',
        name: 'detection-count',
        label: 'Count',
        sortable: false,
      },
      { align: 'left', name: 'passage-detected-frequency', label: 'Frequency' },
      { align: 'left', name: 'graph-data', label: 'Data' },
      { align: 'left', name: 'verify-passage', label: 'Verify' },
      {
        align: 'center',
        field: 'passageId',
        name: 'machine-learning-data',
        label: 'ML Verified',
      },
    ];

    return devicesReportColumns;
  }

  // Component method used to get the devices report data file name.
  public getDevicesReportDataFilename(): string {
    let now: Date = new Date();
    let devicesReportDataFilename = '';

    // Year.
    devicesReportDataFilename = devicesReportDataFilename.concat(
      now.getUTCFullYear().toString()
    );
    devicesReportDataFilename = devicesReportDataFilename.concat('-');

    // Month.
    if (now.getUTCMonth() + 1 < 10) {
      devicesReportDataFilename = devicesReportDataFilename.concat(
        '0' + (now.getUTCMonth() + 1).toString()
      );
    } else {
      devicesReportDataFilename = devicesReportDataFilename.concat(
        (now.getUTCMonth() + 1).toString()
      );
    }

    devicesReportDataFilename = devicesReportDataFilename.concat('-');

    // Day.
    if (now.getUTCDate() < 10) {
      devicesReportDataFilename = devicesReportDataFilename.concat(
        '0' + now.getUTCDate().toString()
      );
    } else {
      devicesReportDataFilename = devicesReportDataFilename.concat(
        now.getUTCDate().toString()
      );
    }

    devicesReportDataFilename = devicesReportDataFilename.concat('-');
    devicesReportDataFilename = devicesReportDataFilename.concat(
      'ReportData-ByAgm.csv'
    );

    return devicesReportDataFilename;
  }

  // onDevicesReportRequest.
  //
  // The @request event handler implementation for the device(s) report q-table control.
  //
  // Note:
  // If rowsPerPage is changed using the GUI, page gets reset to 1.
  //
  // Note:
  // The pagination properties must be reset each time this event handler is invoked.
  public async onDevicesReportRequest(props: any) {
    console.log('ReportManager.vue. onDevicesReportRequest.');

    // Construct a ReportDataForDevicesRequest object.
    let reportDataForDevicesRequest: ReportDataForDevicesRequest =
      new ReportDataForDevicesRequest();
    reportDataForDevicesRequest.page = props.pagination.page;
    reportDataForDevicesRequest.rowsPerPage = props.pagination.rowsPerPage;
    reportDataForDevicesRequest.sortBy = props.pagination.sortBy;
    reportDataForDevicesRequest.descending = props.pagination.descending;

    // Set the additional request values.
    reportDataForDevicesRequest.startDate = this.startDateDevicesReportAsDate;
    reportDataForDevicesRequest.endDate = this.endDateDevicesReportAsDate;
    reportDataForDevicesRequest.includeVerifiedPassagesOnly =
      this.includeVerifiedPassagesOnlyDevicesReport;

    if (reportDataForDevicesRequest.uidList == undefined) {
      reportDataForDevicesRequest.uidList = [];
    }

    for (let i = 0; i < this.selectedDevicesForDevicesReport.length; i++) {
      reportDataForDevicesRequest.uidList.push(
        this.selectedDevicesForDevicesReport[i]
      );
    }

    // Call the web api service method.
    await PigRunService.getReportDataForDevices(reportDataForDevicesRequest)
      .then((response) => {
        // Set the private devicesReportDataArray class member value.
        this.devicesReportDataArray =
          response.data.reportDataResponseRecordList;

        // Set the pagination properties.
        this.devicesReportDataPagination = {
          descending: reportDataForDevicesRequest.descending,
          page: reportDataForDevicesRequest.page,
          rowsNumber: response.data.rowsNumber,
          rowsPerPage: reportDataForDevicesRequest.rowsPerPage,
          sortBy: reportDataForDevicesRequest.sortBy,
        };

        // Show the device(s) report data dialog.
        this.showDevicesReportDialog = true;
      })
      .catch((exception) => {
        console.error(exception);

        // A web api service exception has occurred.
        //
        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          // Display a web api service exception message with its associated handling instance id.
          this.validationDialogMessage =
            'A report manager service exception has occurred. LogId = [' +
            exception.response.data +
            ']';
          this.validationDialogTitle = 'Report Manager Service Exception';
          this.showValidationMessageDialog = true;
        }

        return;
      });
  }

  // Component method for downloading the devices report data as a csv file.
  public async onDownloadDevicesReportData() {
    console.log('ReportManager.vue. onDownloadDevicesReportData().');

    // Construct a ReportDataForDevicesRequest object.
    let reportDataForDevicesRequest: ReportDataForDevicesRequest =
      new ReportDataForDevicesRequest();
    reportDataForDevicesRequest.page = 1;
    reportDataForDevicesRequest.rowsPerPage = 0; // Request all available rows.
    reportDataForDevicesRequest.sortBy = 'passageTimeStamp';
    reportDataForDevicesRequest.descending = false;

    // Set the additional request values.
    reportDataForDevicesRequest.startDate = this.startDateDevicesReportAsDate;
    reportDataForDevicesRequest.endDate = this.endDateDevicesReportAsDate;
    reportDataForDevicesRequest.includeVerifiedPassagesOnly =
      this.includeVerifiedPassagesOnlyDevicesReport;

    // Set the selected uid value(s).
    if (reportDataForDevicesRequest.uidList == undefined) {
      reportDataForDevicesRequest.uidList = [];
    }

    for (let i = 0; i < this.selectedDevicesForDevicesReport.length; i++) {
      reportDataForDevicesRequest.uidList.push(
        this.selectedDevicesForDevicesReport[i]
      );
    }

    // Call the web api service method.
    await PigRunService.getReportDataForDevices(reportDataForDevicesRequest)
      .then((response) => {
        // Set the private devicesReportDataArray class member value.
        this.devicesReportDataArray =
          response.data.reportDataResponseRecordList;

        // Set the pagination properties.
        this.devicesReportDataPagination = {
          descending: reportDataForDevicesRequest.descending,
          page: reportDataForDevicesRequest.page,
          rowsNumber: response.data.rowsNumber,
          rowsPerPage: reportDataForDevicesRequest.rowsPerPage,
          sortBy: reportDataForDevicesRequest.sortBy,
        };
      })
      .catch((exception) => {
        console.error(exception);

        // A web api service exception has occurred.
        //
        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          // Display a web api service exception message with its associated handling instance id.
          this.validationDialogMessage =
            'A report manager service exception has occurred. LogId = [' +
            exception.response.data +
            ']';
          this.validationDialogTitle = 'Report Manager Service Exception';
          this.showValidationMessageDialog = true;
        }

        return;
      });

    // If a valid response has been received from the web api.
    //
    // Export the data in csv format.
    if (this.devicesReportDataArray != undefined) {
      let reportContent =
        'AGM Survey Marker,AGM UID,AGM FW,Survey Marker Latitude,Survey Marker Longitude,AGM Latitude,AGM Longitude,AGM Altitude (m),Passage Type,Time Stamp (UTC),Notes,';
      reportContent = reportContent.concat('\n');

      for (let i = 0; i < this.devicesReportDataArray.length; i++) {
        // Marker name.
        //
        // May 31, 2023 - updated implementation for marker name. This version removes all the double quote characters.
        if (this.devicesReportDataArray[i].markerName != undefined) {
          let markerName: string = this.devicesReportDataArray[i].markerName;

          // Replace all occurrences of a double quote character, with an empty space character.
          // Replace all occurrences of a newline character, with an empty space character.
          if (markerName.length > 0) {
            markerName = markerName.replaceAll('"', '');
            markerName = markerName.replaceAll('\n', '');
          }

          reportContent = reportContent.concat(markerName);
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // uId.
        if (this.devicesReportDataArray[i].uId != undefined) {
          reportContent = reportContent.concat(
            this.devicesReportDataArray[i].uId
          );
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // Agm firmware.
        if (this.devicesReportDataArray[i].firmwareVersion != undefined) {
          reportContent = reportContent.concat(
            this.devicesReportDataArray[i].firmwareVersion
          );
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // Survey marker latitude.
        if (this.devicesReportDataArray[i].markerLatitude != undefined) {
          reportContent = reportContent.concat(
            this.devicesReportDataArray[i].markerLatitude
          );
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // Survey marker longitude.
        if (this.devicesReportDataArray[i].markerLongitude != undefined) {
          reportContent = reportContent.concat(
            this.devicesReportDataArray[i].markerLongitude
          );
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // Latitude.
        if (this.devicesReportDataArray[i].latitude != undefined) {
          reportContent = reportContent.concat(
            this.devicesReportDataArray[i].latitude
          );
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // Longitude.
        if (this.devicesReportDataArray[i].longitude != undefined) {
          reportContent = reportContent.concat(
            this.devicesReportDataArray[i].longitude
          );
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // Altitude.
        if (this.devicesReportDataArray[i].altitude != undefined) {
          reportContent = reportContent.concat(
            this.formatAltitude(this.devicesReportDataArray[i].altitude, false)
          );
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // Passage type.
        if (this.devicesReportDataArray[i].passageType != undefined) {
          reportContent = reportContent.concat(
            this.devicesReportDataArray[i].passageType
          );
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // Passage time stamp.
        //
        // Note: An empty space character prefix has been applied here to prevent Microsoft Excel from applying its default date formatting to this field.
        if (this.devicesReportDataArray[i].passageTimeStamp != undefined) {
          // Remove the prefix for now.
          reportContent = reportContent.concat(' ');
          reportContent = reportContent.concat(
            this.devicesReportDataArray[i].passageTimeStamp
          );
          reportContent = reportContent.concat(',');
        } else {
          reportContent = reportContent.concat(',');
        }

        // Marker note.
        //
        // May 31, 2023 - updated implementation for note. This version removes all the double quote characters.
        if (this.devicesReportDataArray[i].markerNote != undefined) {
          let note: string = this.devicesReportDataArray[i].markerNote;

          // Replace all occurrences of a double quote character, with an empty space character.
          // Replace all occurrences of a newline character, with an empty space character.
          if (note.length > 0) {
            note = note.replaceAll('"', '');
            note = note.replaceAll('\n', '');
          }

          reportContent = reportContent.concat(note);
          reportContent = reportContent.concat('\n');
        } else {
          reportContent = reportContent.concat('\n');
        }
      } // for (let i: number = 0; i < this.devicesReportDataArray.length; i++)

      var anchorElement = document.createElement('a');
      var blob = new Blob([reportContent], { type: 'text/csv;charset=utf-8' });
      var url = URL.createObjectURL(blob);

      // Get the devices report data file nanme.
      let devicesReportDataFilename: string =
        this.getDevicesReportDataFilename();

      anchorElement.href = url;
      anchorElement.setAttribute('download', devicesReportDataFilename);
      anchorElement.click();
    }
  }

  // Component method.
  //
  // The enter parameters for devices report q-btn click handler.
  public async onEnterReportParametersForDevicesReportClick() {
    console.log(
      'ReportManager.vue. onEnterReportParametersForDevicesReportClick.'
    );

    this.showEnterParametersForDevicesReportDialog = true;
  }

  // Component method.
  //
  // The generate report data button click handler for the device(s) report.
  public async onGenerateReportDataForDevicesReportClick() {
    console.log(
      'ReportManager.vue. onGenerateReportDataForDevicesReportClick.'
    );

    // Validation: If the specified start date for the device report is not valid, display a report parameter validation error.
    if (!DateCommon.IsDateAsStringValid(this.startDateDevicesReport)) {
      this.validationDialogTitle = 'Invalid report parameter.';
      this.validationDialogMessage = 'The start date is not valid.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Validation: If the specified end date for the device report is not valid, display a report parameter validation error.
    if (!DateCommon.IsDateAsStringValid(this.endDateDevicesReport)) {
      this.validationDialogTitle = 'Invalid report parameter.';
      this.validationDialogMessage = 'The end date is not valid.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Convert the specified valid start date for the device report to a native Date object.
    this.startDateDevicesReportAsDate = DateCommon.ConvertDateAsStringToDate(
      this.startDateDevicesReport
    );

    // Convert the specified valid end date for the device report to a native Date object.
    this.endDateDevicesReportAsDate = DateCommon.ConvertDateAsStringToDate(
      this.endDateDevicesReport
    );

    // Validation: If the start date is not before the end date, display a report parameter validation error.
    if (
      this.startDateDevicesReportAsDate.getTime() >=
      this.endDateDevicesReportAsDate.getTime()
    ) {
      this.validationDialogTitle = 'Invalid report parameter.';
      this.validationDialogMessage =
        'The start date must be before the end date.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Validation: If there is not at least a single uid value selected, display a report parameter validation error.
    if (this.selectedDevicesForDevicesReport.length == 0) {
      this.validationDialogTitle = 'Invalid report parameter.';
      this.validationDialogMessage = 'At least one uid value must be selected.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Construct a ReportDataForDevicesRequest object.
    let reportDataForDevicesRequest: ReportDataForDevicesRequest =
      new ReportDataForDevicesRequest();
    reportDataForDevicesRequest.page = 1;
    reportDataForDevicesRequest.rowsPerPage = 15;
    reportDataForDevicesRequest.sortBy = 'passageTimeStamp';
    reportDataForDevicesRequest.descending = false;

    // Set the additional request values.
    reportDataForDevicesRequest.startDate = this.startDateDevicesReportAsDate;
    reportDataForDevicesRequest.endDate = this.endDateDevicesReportAsDate;
    reportDataForDevicesRequest.includeVerifiedPassagesOnly =
      this.includeVerifiedPassagesOnlyDevicesReport;

    // Set the selected uid value(s).
    if (reportDataForDevicesRequest.uidList == undefined) {
      reportDataForDevicesRequest.uidList = [];
    }

    for (let i = 0; i < this.selectedDevicesForDevicesReport.length; i++) {
      reportDataForDevicesRequest.uidList.push(
        this.selectedDevicesForDevicesReport[i]
      );
    }

    // Set the state of the devices report generate report data button to loading.
    this.devicesReportGenerateReportLoading = true;

    // Call the web api service method.
    await PigRunService.getReportDataForDevices(reportDataForDevicesRequest)
      .then((response) => {
        console.log(response);

        // Set the private devicesReportDataArray class member value.
        this.devicesReportDataArray =
          response.data.reportDataResponseRecordList;

        // Set the pagination properties.
        this.devicesReportDataPagination = {
          descending: reportDataForDevicesRequest.descending,
          page: reportDataForDevicesRequest.page,
          rowsNumber: response.data.rowsNumber,
          rowsPerPage: reportDataForDevicesRequest.rowsPerPage,
          sortBy: reportDataForDevicesRequest.sortBy,
        };

        // Show the device(s) report data dialog.
        this.showDevicesReportDialog = true;

        // Set the state of the devices report generate report data button to not loading.
        this.devicesReportGenerateReportLoading = false;
      })
      .catch((exception) => {
        console.error(exception);

        // A web api service exception has occurred.
        //
        // If the exception.response.data property is not undefined, an exception handling id has been returned from the web api.
        if (exception.response.data != undefined) {
          // Display a web api service exception message with its associated handling instance id.
          this.validationDialogMessage =
            'A report manager service exception has occurred. LogId = [' +
            exception.response.data +
            ']';
          this.validationDialogTitle = 'Report Manager Service Exception';
          this.showValidationMessageDialog = true;
        }

        // Set the state of the devices report generate report data button to not loading.
        this.devicesReportGenerateReportLoading = false;

        return;
      });
  }

  // Component method.
  //
  // The devices report include verified passages only q-checkbox click/input handler.
  public onIncludeVerifiedPassagesOnlyDevicesReportInput() {
    console.log(
      'ReportManager.vue. onIncludeVerifiedPassagesOnlyDevicesReportInput.'
    );

    // Refresh the data rendered in the q-table.
    this.onGenerateReportDataForDevicesReportClick();
  }

  // Component method.
  //
  // The set parameter values dialog button click handler for the devices report.
  public async onSetParametersForDevicesReportClick() {
    console.log('ReportManager.vue. onSetParametersForDevicesReportClick.');

    // Validation: If the specified start date for the device report is not valid, display a report parameter validation error.
    if (!DateCommon.IsDateAsStringValid(this.startDateDevicesReport)) {
      this.validationDialogTitle = 'Invalid report parameter.';
      this.validationDialogMessage = 'The start date is not valid.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Validation: If the specified end date for the device report is not valid, display a report parameter validation error.
    if (!DateCommon.IsDateAsStringValid(this.endDateDevicesReport)) {
      this.validationDialogTitle = 'Invalid report parameter.';
      this.validationDialogMessage = 'The end date is not valid.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Convert the specified valid start date for the device report to a native Date object.
    this.startDateDevicesReportAsDate = DateCommon.ConvertDateAsStringToDate(
      this.startDateDevicesReport
    );

    // Convert the specified valid end date for the device report to a native Date object.
    this.endDateDevicesReportAsDate = DateCommon.ConvertDateAsStringToDate(
      this.endDateDevicesReport
    );

    // Validation: If the start date is not before the end date, display a report parameter validation error.
    if (
      this.startDateDevicesReportAsDate.getTime() >=
      this.endDateDevicesReportAsDate.getTime()
    ) {
      this.validationDialogTitle = 'Invalid report parameter.';
      this.validationDialogMessage =
        'The start date must be before the end date.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Validation: If there is not at least a single uid value selected, display a report parameter validation error.
    if (this.selectedDevicesForDevicesReport.length == 0) {
      this.validationDialogTitle = 'Invalid report parameter.';
      this.validationDialogMessage = 'At least one uid value must be selected.';
      this.showValidationMessageDialog = true;

      return;
    }

    // At this point all the report parameter validations have passed, close the report parameters dialog.
    this.showEnterParametersForDevicesReportDialog = false;
  }

  // Component method.
  //
  // The component method that toggles the display of the export options for the devices report.
  public onToggleExportOptionsForDevicesReport() {
    this.exportOptionsVisibleDevicesReport =
      !this.exportOptionsVisibleDevicesReport;
  }

  // Component method.
  //
  // The verify passage button click handler implementation for the device(s) report.
  public async onVerifyPassageForDevicesReport(propsrow: any) {
    console.log('ReportManager.vue. onVerifyPassageForDevicesReport.');
    console.log(
      'ReportManager.vue. onVerifyPassageForDevicesReport. ElfId = [' +
        propsrow.elfId +
        ']'
    );
    console.log(
      'ReportManager.vue. onVerifyPassageForDevicesReport. GeoId = [' +
        propsrow.geoId +
        ']'
    );
    console.log(
      'ReportManager.vue. onVerifyPassageForDevicesReport. MagId = [' +
        propsrow.magId +
        ']'
    );

    // Construct a PassageVerificationRequest object.
    //
    // Note: The propsrow.isVerified value is its current value, therefore, reverse the current boolean value here for the passage verification request.
    let passageVerificationRequest: PassageVerificationRequest =
      new PassageVerificationRequest(
        propsrow.elfId,
        propsrow.geoId,
        propsrow.magId,
        !propsrow.isVerified
      );

    // Call the web api service method.
    await PigRunService.setPassageVerification(passageVerificationRequest)
      .then((response) => {
        console.log(response);
      })
      .catch((exception) => {
        console.error(exception);

        // A web api service exception has occurred.
        //
        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          // Display a web api service exception message with its associated handling instance id.
          this.validationDialogMessage =
            'A report manager service exception has occurred. LogId = [' +
            exception.response.data +
            ']';
          this.validationDialogTitle = 'Report Manager Service Exception';
          this.showValidationMessageDialog = true;
        }

        return;
      });

    console.log(
      'ReportManager.vue. onVerifyPassageForDevicesReport. Before setting propsrow.isVerified.'
    );

    // Toggle the propsrow.isVerified state. However, do not refresh the q-table.
    propsrow.isVerified = !propsrow.isVerified;
  }

  // Computed property getter for the uid checkbox parameters of the devices report.
  get allDeviceOptionsForDevicesReport() {
    let allDeviceOptions: any = [];

    // Iterate each device.
    this.$store.state.devices.forEach((device: Device) => {
      allDeviceOptions.push({
        label: 'AGM UID ' + device.uid,
        value: device.uid,
      });
    });

    return allDeviceOptions;
  }

  // --------------------------------------------------------------------------------------------------------------------------------------------------------
  // Completed pig run report section.
  // --------------------------------------------------------------------------------------------------------------------------------------------------------

  // Property for the selected completed pig run in the q-select control.
  public selectedCompletedPigRun: any = '';

  // Property for the include verified passages only v-model for the q-checkbox control.
  public selectedPigRunIncludeVerifiedPassagesOnly = false;

  // Property for the completed pig run report dialog control.
  public showCompletedPigRunReportDialog = false;

  // Property corresponding to the underlying data array referenced by the q-table control (via the completedPigRunReportData getter).
  private completedPigRunReportDataArray: any[] = [];

  // Property corresponding to the pagination.sync binding of the q-table control.
  public completedPigRunReportDataPagination: Record<string, unknown> = {};

  // Property for displaying the export options for the completed pig run report.
  public exportOptionsVisibleCompletedPigRunReport = false;

  // Property for the completed pig run report loading.
  public completedPigRunReportGenerateReportLoading = false;

  // Computed property getter for the completed pig run report columns.
  get completedPigRunReportColumns(): any {
    // Note:
    // The name property is a unique identifier for a column, used by pagination.sortBy, and "body-cell-[name]" slot assignment, etc.
    let completedPigRunReportColumns = [
      {
        align: 'left',
        name: 'passageTimeStamp',
        label: 'Passage',
        field: 'passageTimeStamp',
        sortable: true,
        required: true,
      },
      {
        align: 'left',
        name: 'markerName',
        label: 'Marker Name',
        sortable: true,
      },
      { align: 'left', name: 'toolName', label: 'Tool Name', sortable: false },
      {
        align: 'left',
        name: 'coordinates',
        label: 'Coordinates - Lat, Lon, Altitude (m)',
        sortable: false,
      },
      {
        align: 'left',
        name: 'UId',
        label: 'UID',
        field: 'uId',
        sortable: true,
      },
      {
        align: 'left',
        name: 'detection-count',
        label: 'Count',
        sortable: false,
      },
      { align: 'left', name: 'passage-detected-frequency', label: 'Frequency' },
      { align: 'left', name: 'graph-data', label: 'Data' },
      { align: 'left', name: 'verify-passage', label: 'Verify' },
      {
        align: 'center',
        name: 'machine-learning-data',
        label: 'ML Verified',
      },
    ];

    return completedPigRunReportColumns;
  }

  // Computed property getter corresponding to the data binding of the q-table control.
  get completedPigRunReportData(): any {
    return this.completedPigRunReportDataArray;
  }

  // Computed property getter.
  get completedPigRuns() {
    return this.$store.state.pigRun.completedPigRuns;
  }

  // Computed property getter corresponding to the options for the completed pig run q-select control.
  get completedPigRunOptions() {
    return this.$store.state.pigRun.completedPigRuns.map((pigRun: PigRun) => {
      return {
        value: pigRun.runId,
        runData: pigRun,
        label:
          dayjs.default(pigRun.recieveTime).utc().format('YYYY-MM-DD HH:mm') +
          ' / ' +
          pigRun.pigPath.pigRunName,
      };
    });
  }

  // Component method used to get the completed pig run report data file name.
  public getCompletedPigRunReportDataFilename(
    pigRunName: string | undefined
  ): string {
    // Construct the completed pig run report file name used for downloading to the client.
    let now: Date = new Date();
    let completedPigRunReportDataFilename = '';

    // Year.
    completedPigRunReportDataFilename =
      completedPigRunReportDataFilename.concat(now.getUTCFullYear().toString());
    completedPigRunReportDataFilename =
      completedPigRunReportDataFilename.concat('-');

    // Month.
    if (now.getUTCMonth() + 1 < 10) {
      completedPigRunReportDataFilename =
        completedPigRunReportDataFilename.concat(
          '0' + (now.getUTCMonth() + 1).toString()
        );
    } else {
      completedPigRunReportDataFilename =
        completedPigRunReportDataFilename.concat(
          (now.getUTCMonth() + 1).toString()
        );
    }

    completedPigRunReportDataFilename =
      completedPigRunReportDataFilename.concat('-');

    // Day.
    if (now.getUTCDate() < 10) {
      completedPigRunReportDataFilename =
        completedPigRunReportDataFilename.concat(
          '0' + now.getUTCDate().toString()
        );
    } else {
      completedPigRunReportDataFilename =
        completedPigRunReportDataFilename.concat(now.getUTCDate().toString());
    }

    completedPigRunReportDataFilename =
      completedPigRunReportDataFilename.concat('-');
    completedPigRunReportDataFilename =
      completedPigRunReportDataFilename.concat('ReportData');

    if (pigRunName != undefined) {
      let pigRunNameToUse = pigRunName;

      // Replace all occurrences of a double quote character, with an empty string.
      // Replace all occurrences of a newline character, with an empty string.
      if (pigRunNameToUse.length > 0) {
        pigRunNameToUse = pigRunNameToUse.replaceAll('"', '');
        pigRunNameToUse = pigRunNameToUse.replaceAll('\n', '');
      }

      completedPigRunReportDataFilename =
        completedPigRunReportDataFilename.concat('-');
      completedPigRunReportDataFilename =
        completedPigRunReportDataFilename.concat(pigRunNameToUse);
      completedPigRunReportDataFilename =
        completedPigRunReportDataFilename.concat('.csv');
    } else {
      completedPigRunReportDataFilename =
        completedPigRunReportDataFilename.concat('-CompletedPigRun.csv');
    }

    return completedPigRunReportDataFilename;
  }

  // Component method.
  //
  // The click event handler for the completed pig run generate report q-btn control.
  public async onCompletedPigRunGenerateReportClick() {
    console.log('ReportManager.vue. onCompletedPigRunGenerateReportClick');

    // Validation: A completed pig run must be selected.
    if (this.selectedCompletedPigRun == '') {
      console.log(
        'ReportManager.vue. onCompletedPigRunGenerateReportClick. No completed pig run selected.'
      );

      this.validationDialogMessage = 'A completed pig run must be selected.';
      this.validationDialogTitle = 'Information Message';
      this.showValidationMessageDialog = true;

      return;
    }

    // Set the state of the completed pig run generate report data button to loading.
    this.completedPigRunReportGenerateReportLoading = true;

    // Construct a ReportDataForCompletedPigRunRequest object.
    let reportDataForCompletedPigRunRequest: ReportDataForCompletedPigRunRequest =
      new ReportDataForCompletedPigRunRequest(
        this.selectedCompletedPigRun.value
      );
    reportDataForCompletedPigRunRequest.page = 1;
    reportDataForCompletedPigRunRequest.rowsPerPage = 15;
    reportDataForCompletedPigRunRequest.sortBy = 'passageTimeStamp'; // Not currently used by the underlying web api.
    reportDataForCompletedPigRunRequest.descending = false; // Not currently used by the underlying web api.
    reportDataForCompletedPigRunRequest.includeVerifiedPassagesOnly =
      this.selectedPigRunIncludeVerifiedPassagesOnly;

    // Call the web api service method.
    await PigRunService.getReportDataForCompletedPigRun(
      reportDataForCompletedPigRunRequest
    )
      .then((response) => {
        console.log(response);

        // Set the private completedPigRunReportDataArray class member value.
        this.completedPigRunReportDataArray =
          response.data.reportDataResponseRecordList;

        // Set the pagination properties.
        this.completedPigRunReportDataPagination = {
          descending: reportDataForCompletedPigRunRequest.descending,
          page: reportDataForCompletedPigRunRequest.page,
          rowsNumber: response.data.rowsNumber,
          rowsPerPage: reportDataForCompletedPigRunRequest.rowsPerPage,
          sortBy: reportDataForCompletedPigRunRequest.sortBy,
        };

        // Show the completed pig run report dialog.
        this.showCompletedPigRunReportDialog = true;

        // Set the state of the completed pig run report data button to not loading.
        this.completedPigRunReportGenerateReportLoading = false;
      })
      .catch((exception) => {
        console.error(exception);

        // A web api service exception has occurred.
        //
        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          // Display a web api service exception message with its associated handling instance id.
          this.validationDialogMessage =
            'A report manager service exception has occurred. LogId = [' +
            exception.response.data +
            ']';
          this.validationDialogTitle = 'Report Manager Service Exception';
          this.showValidationMessageDialog = true;
        }

        // Set the state of the completed pig run report data button to not loading.
        this.completedPigRunReportGenerateReportLoading = false;

        return;
      });
  }

  // onCompletedPigRunReportRequest.
  //
  // The @request event handler implementation for the completed pig run report q-table control.
  //
  // Note:
  // If rowsPerPage is changed using the GUI, page gets reset to 1.
  //
  // Note:
  // The pagination properties must be reset each time this event handler is invoked.
  public async onCompletedPigRunReportRequest(props: any) {
    console.log(props.pagination.page);
    console.log(props.pagination.rowsPerPage);
    console.log(props.pagination.sortBy);
    console.log(props.pagination.descending);

    // Construct a ReportDataForCompletedPigRunRequest object.
    let reportDataForCompletedPigRunRequest: ReportDataForCompletedPigRunRequest =
      new ReportDataForCompletedPigRunRequest(
        this.selectedCompletedPigRun.value
      );
    reportDataForCompletedPigRunRequest.page = props.pagination.page;
    reportDataForCompletedPigRunRequest.rowsPerPage =
      props.pagination.rowsPerPage;
    reportDataForCompletedPigRunRequest.sortBy = props.pagination.sortBy;
    reportDataForCompletedPigRunRequest.descending =
      props.pagination.descending;
    reportDataForCompletedPigRunRequest.includeVerifiedPassagesOnly =
      this.selectedPigRunIncludeVerifiedPassagesOnly;

    // Call the web api service method.
    await PigRunService.getReportDataForCompletedPigRun(
      reportDataForCompletedPigRunRequest
    )
      .then((response) => {
        // Set the pagination properties.
        this.completedPigRunReportDataPagination = {
          descending: reportDataForCompletedPigRunRequest.descending,
          page: reportDataForCompletedPigRunRequest.page,
          rowsNumber: response.data.rowsNumber,
          rowsPerPage: reportDataForCompletedPigRunRequest.rowsPerPage,
          sortBy: reportDataForCompletedPigRunRequest.sortBy,
        };

        // Set the private completedPigRunReportDataArray class member value.
        this.completedPigRunReportDataArray =
          response.data.reportDataResponseRecordList;
      })
      .catch((exception) => {
        console.error(exception);

        // A web api service exception has occurred.
        //
        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          // Display a web api service exception message with its associated handling instance id.
          this.validationDialogMessage =
            'A report manager service exception has occurred. LogId = [' +
            exception.response.data +
            ']';
          this.validationDialogTitle = 'Report Manager Service Exception';
          this.showValidationMessageDialog = true;
        }

        return;
      });
  }

  // Component method input event handler for the completed pig run q-select control.
  public onCompletedPigRunSelect() {
    console.log('ReportManager.vue. onCompletedPigRunSelect');
    console.log(
      'ReportManager.vue. onCompletedPigRunSelect. PigRun.RunId = [' +
        this.selectedCompletedPigRun.value +
        ']'
    );
  }

  // Component method for the completed pig run download report data click handler.
  public async onDownloadCompletedPigRunReportData() {
    console.log(
      'ReportManager.vue. onDownloadCompletedPigRunReportData. Enter method.'
    );

    // If the selected report type is 'CompletedPigRun'.
    if (this.selectedReportType.value == 'CompletedPigRun') {
      console.log(
        'ReportManager.vue. onDownloadCompletedPigRunReportData. selectedReportType.value = [CompletedPigRun].'
      );

      let reportDataForCompletedPigRunResponse: any = undefined;

      // Construct a ReportDataForCompletedPigRunRequest object.
      let reportDataForCompletedPigRunRequest: ReportDataForCompletedPigRunRequest =
        new ReportDataForCompletedPigRunRequest(
          this.selectedCompletedPigRun.value
        );
      reportDataForCompletedPigRunRequest.page = 1;
      reportDataForCompletedPigRunRequest.rowsPerPage = 0;
      reportDataForCompletedPigRunRequest.includeVerifiedPassagesOnly =
        this.selectedPigRunIncludeVerifiedPassagesOnly;

      // Call the web api service method to get the report data.
      await PigRunService.getReportDataForCompletedPigRun(
        reportDataForCompletedPigRunRequest
      )
        .then((response) => {
          reportDataForCompletedPigRunResponse = response.data;
        })
        .catch((exception) => {
          console.error(exception);

          // A web api service exception has occurred.
          //
          // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
          if (exception.response.data != undefined) {
            // Display a web api service exception message with its associated handling instance id.
            this.validationDialogMessage =
              'A report manager service exception has occurred. LogId = [' +
              exception.response.data +
              ']';
            this.validationDialogTitle = 'Report Manager Service Exception';
            this.showValidationMessageDialog = true;
          }

          return;
        });

      // Log the return object (debug).
      console.log(reportDataForCompletedPigRunResponse);

      // If a valid response has been received from the web api.
      if (reportDataForCompletedPigRunResponse != undefined) {
        let reportContent = '';

        if (
          reportDataForCompletedPigRunResponse.reportDataResponseRecordList !=
            undefined &&
          reportDataForCompletedPigRunResponse.reportDataResponseRecordList
            .length > 0
        ) {
          reportContent = reportContent.concat(
            'AGM Survey Marker,AGM UID,AGM FW,Survey Marker Latitude,Survey Marker Longitude,AGM Latitude,AGM Longitude,AGM Altitude (m),Passage Type,Time Stamp (UTC),Notes,'
          );
          reportContent = reportContent.concat('\n');

          // For each array element in reportDataForCompletedPigRunResponse.reportDataResponseRecordList.
          for (
            let i = 0;
            i <
            reportDataForCompletedPigRunResponse.reportDataResponseRecordList
              .length;
            i++
          ) {
            // Marker name.
            //
            // May 31, 2023 - updated implementation for marker name. This version removes all the double quote characters.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].markerName != undefined
            ) {
              let markerName: string =
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].markerName;

              // Replace all occurrences of a double quote character, with an empty space character.
              // Replace all occurrences of a newline character, with an empty space character.
              if (markerName.length > 0) {
                markerName = markerName.replaceAll('"', '');
                markerName = markerName.replaceAll('\n', '');
              }

              reportContent = reportContent.concat(markerName);
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // uId.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].uId != undefined
            ) {
              reportContent = reportContent.concat(
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].uId
              );
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // Firmeware version.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].firmwareVersion != undefined
            ) {
              reportContent = reportContent.concat(
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].firmwareVersion
              );
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // Survey marker latitude.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].markerLatitude != undefined
            ) {
              reportContent = reportContent.concat(
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].markerLatitude
              );
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // Survey marker longitude.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].markerLongitude != undefined
            ) {
              reportContent = reportContent.concat(
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].markerLongitude
              );
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // AGM latitude.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].latitude != undefined
            ) {
              reportContent = reportContent.concat(
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].latitude
              );
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // AGM longitude.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].longitude != undefined
            ) {
              reportContent = reportContent.concat(
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].longitude
              );
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // AGM Altitude (m).
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].altitude != undefined
            ) {
              reportContent = reportContent.concat(
                this.formatAltitude(
                  reportDataForCompletedPigRunResponse
                    .reportDataResponseRecordList[i].altitude,
                  false
                )
              );
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // Passage type.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].passageType != undefined
            ) {
              reportContent = reportContent.concat(
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].passageType
              );
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // Passage time stamp.
            //
            // Note: An empty space character prefix has been applied here to prevent Microsoft Excel from applying its default date formatting to this field.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].passageTimeStamp != undefined
            ) {
              reportContent = reportContent.concat(' ');
              reportContent = reportContent.concat(
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].passageTimeStamp
              );
              reportContent = reportContent.concat(',');
            } else {
              reportContent = reportContent.concat(',');
            }

            // Marker note.
            //
            // May 31, 2023 - updated implementation for marker note. This version removes all the double quote characters.
            if (
              reportDataForCompletedPigRunResponse.reportDataResponseRecordList[
                i
              ].markerNote != undefined
            ) {
              let markerNote: string =
                reportDataForCompletedPigRunResponse
                  .reportDataResponseRecordList[i].markerNote;

              // Replace all occurrences of a double quote character, with an empty space character.
              // Replace all occurrences of a newline character, with an empty space character.
              if (markerNote.length > 0) {
                markerNote = markerNote.replaceAll('"', '');
                markerNote = markerNote.replaceAll('\n', '');
              }

              reportContent = reportContent.concat(markerNote);
            }

            // Trailing line feed.
            reportContent = reportContent.concat('\n');
          }
        }

        var anchorElement = document.createElement('a');
        var blob = new Blob([reportContent], {
          type: 'text/csv;charset=utf-8;',
        });
        var url = URL.createObjectURL(blob);

        // Get the completed pig run report data file name based on the pig run name.
        let completedPigRunReportDataFilename: string =
          this.getCompletedPigRunReportDataFilename(
            this.selectedCompletedPigRun.runData.pigPath.pigRunName
          );

        anchorElement.href = url;
        anchorElement.setAttribute(
          'download',
          completedPigRunReportDataFilename
        );
        anchorElement.click();
      }
    } // if (this.selectedReportType.value == 'CompletedPigRun')
  }

  // Component method.
  //
  // The completed pig run report include verified passages only q-checkbox click/input handler.
  public async onIncludeVerifiedPassagesOnlyCompletedPigRunReportInput() {
    console.log(
      'ReportManager.vue. onIncludeVerifiedPassagesOnlyCompletedPigRunReportInput.'
    );

    // Refresh the data rendered in the q-table.
    this.onCompletedPigRunGenerateReportClick();
  }

  // Component method.
  //
  // The component method that toggles the display of the export options for the completed pig run report.
  public async onToggleExportOptionsForCompletedPigRunReport() {
    console.log(
      'ReportManager.vue. onToggleExportOptionsForCompletedPigRunReport.'
    );

    this.exportOptionsVisibleCompletedPigRunReport =
      !this.exportOptionsVisibleCompletedPigRunReport;
  }

  // Component method.
  //
  // The verify passage button click handler implementation for the completed pig run report dialog.
  public async onVerifyPassageForCompletedPigRun(propsrow: any) {
    console.log(
      'ReportManager.vue. onVerifyPassageForCompletedPigRun. elfId = [' +
        propsrow.elfId +
        ']'
    );
    console.log(
      'ReportManager.vue. onVerifyPassageForCompletedPigRun. geoId = [' +
        propsrow.geoId +
        ']'
    );
    console.log(
      'ReportManager.vue. onVerifyPassageForCompletedPigRun. magId = [' +
        propsrow.magId +
        ']'
    );

    // If the elfId, geoId, and magId are all undefined, then the user is unverifying a manual passage. Unverifying a manual passage correctly translates into
    // deleting the manual passage.
    if (
      propsrow.elfId == undefined &&
      propsrow.geoId == undefined &&
      propsrow.magId == undefined
    ) {
      console.log(
        'ReportManager.vue. onVerifyPassageForCompletedPigRun. User has chosen to delete a manual passage.'
      );

      if (propsrow.passageTimeStamp != undefined) {
        // Strip-off the trailing '.000000' from the manual passage time stamp and construct a native Date object for the manual passage time stamp.
        let manualPassageTimeStamp: Date = DateCommon.ConvertDateAsStringToDate(
          propsrow.passageTimeStamp.replaceAll('.000000', '')
        );

        // Construct a DeleteManualPassageRequest object.
        let deleteManualPassageRequest: DeleteManualPassageRequest =
          new DeleteManualPassageRequest(
            this.selectedCompletedPigRun.runData.runId,
            manualPassageTimeStamp
          );

        // Call the web api service method.
        await PigRunService.deleteManualPassage(deleteManualPassageRequest)
          .then((response) => {
            console.log(response);
          })
          .catch((exception) => {
            console.error(exception);

            // A web api service exception has occurred.
            //
            // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
            if (exception.response.data != undefined) {
              // Display a web api service exception message with its associated handling instance id.
              this.validationDialogMessage =
                'A report manager service exception has occurred. LogId = [' +
                exception.response.data +
                ']';
              this.validationDialogTitle = 'Report Manager Service Exception';
              this.showValidationMessageDialog = true;
            }

            return;
          });

        // Refresh the q-table.
        this.onCompletedPigRunGenerateReportClick();

        return;
      }
    }

    // Construct a PassageVerificationRequest object.
    //
    // Note: The propsrow.isVerified value is its current value, therefore, reverse the current boolean value here for the passage verification request.
    let passageVerificationRequest: PassageVerificationRequest =
      new PassageVerificationRequest(
        propsrow.elfId,
        propsrow.geoId,
        propsrow.magId,
        !propsrow.isVerified
      );

    // Call the web api service method.
    await PigRunService.setPassageVerification(passageVerificationRequest)
      .then((response) => {
        console.log(response);
      })
      .catch((exception) => {
        console.error(exception);

        // A web api service exception has occurred.
        //
        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          // Display a web api service exception message with its associated handling instance id.
          this.validationDialogMessage =
            'A report manager service exception has occurred. LogId = [' +
            exception.response.data +
            ']';
          this.validationDialogTitle = 'Report Manager Service Exception';
          this.showValidationMessageDialog = true;

          return;
        }
      });

    // June 1, 2023 - previous implementation.
    //
    // Refresh the q-table.
    // this.onCompletedPigRunGenerateReportClick();

    console.log(
      'ReportManager.vue. onVerifyPassageForCompletedPigRun. Before setting propsrow.isVerified.'
    );

    // Toggle the propsrow.isVerified state. However, do not refresh the q-table.
    propsrow.isVerified = !propsrow.isVerified;
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------
  // Download recorded passage data section - start.
  // ----------------------------------------------------------------------------------------------------------------------------------------------

  // Property to display the applicable download date range.
  public downloadRecordedPassageDataDateRangeAsString = '';

  // Property to store the DownloadRecordedPassageDataSessionId value used for cancelling a download recorded passage data session.
  public downloadRecordedPassageDataSessionIdToCancel = -1;

  // Property to store the DownloadRecordedPassageDataSessionId value used for resuming a download recorded passage data session. Note that this member
  // is shared between the download by devices and by completed pig run code paths.
  public downloadRecordedPassageDataSessionIdToResume = -1;

  // Property for the firmware validation response for download recorded passage data.
  public firmwareValidationResponseDownloadRecordedPassageData: any;

  // Property to show the download recorded passage data dialog.
  public showDownloadRecordedPassageDataDialog = false;

  // Property to display the download recorded passage data validation dialog for completed pig run.
  public showDownloadRecordedPassageDataValidationDialogForCompletedPigRun =
    false;

  // Property to display the download recorded passage data validation dialog for devices.
  public showDownloadRecordedPassageDataValidationDialogForDevices = false;

  // Property for the download recorded passage data validation result for completed pig run.
  public validateDownloadRecordedPassageDataRequestResponseForCompletedPigRun: any;

  // Property for the download recorded passage data validation result for devices.
  public validateDownloadRecordedPassageDataRequestResponseForDevices: any;

  // Computed property getter for the download recorded passage data.
  //
  // Note: This references the Vuex state member downloadRecordedPassageDataProgressArray directly.
  get downloadRecordedPassageData(): any {
    return this.$store.state.downloadRecordedPassageDataProgressArray;
  }

  // Computed property getter for the download recorded passage data columns.
  get downloadRecordedPassageDataColumns(): any {
    let downloadRecordedPassageDataColumns = [
      {
        align: 'left',
        name: 'uid',
        label: 'UID',
        field: 'uid',
        sortable: false,
      },
      {
        align: 'left',
        name: 'downloadState',
        label: 'Download State',
        field: 'downloadState',
        sortable: false,
      },
      {
        align: 'left',
        name: 'packetsReceived',
        label: 'Packets Received',
        field: 'packetsReceived',
        sortable: false,
      },
      {
        align: 'left',
        name: 'latestPassageTimeStamp',
        label: 'Latest Passage Time Stamp',
        field: 'latestPassageTimeStamp',
        sortable: false,
      },
      {
        align: 'left',
        name: 'downloadCompleteTimeStamp',
        label: 'Download Completed At',
        field: 'downloadCompleteTimeStamp',
        sortable: false,
      },
    ];

    return downloadRecordedPassageDataColumns;
  }

  // Component method.
  //
  // The download recorded passage data validation dialog (both by completed pig run and devices).
  public async downloadRecordedPassageDataValidationDialogOnCancelClick() {
    console.log(
      'ReportManager.vue. downloadRecordedPassageDataValidationDialogOnCancelClick. Enter method.'
    );

    this.showDownloadRecordedPassageDataValidationDialogForCompletedPigRun =
      false;
    this.showDownloadRecordedPassageDataValidationDialogForDevices = false;
  }

  // The download recorded passage data validation dialog ok button click handler for completed pig run.
  //
  // This handler implementation is responsible for reading the latest download passage data request validation response, ending
  // an existing session, and then creating a new one.
  public async downloadRecordedPassageDataValidationDialogForCompletedPigRunOnOKClick() {
    console.log(
      'ReportManager.vue. downloadRecordedPassageDataValidationDialogForCompletedPigRunOnOKClick. Enter method.'
    );

    // At this point an existing download recorded passage data session must be ended prior to creating a new download recorded passage data session.
    console.log(
      this.validateDownloadRecordedPassageDataRequestResponseForCompletedPigRun
    );

    let webApiCallSuccess = false;
    let webApiCallExceptionHandlingInstanceId: any;

    // Call the web api service method to end an existing download recorded passage data session.
    await PigRunService.endDownloadRecordedPassageDataSession(
      this.validateDownloadRecordedPassageDataRequestResponseForCompletedPigRun
        .downloadRecordedPassageDataSessionId
    )
      .then((response) => {
        console.log(
          'ReportManager.vue. downloadRecordedPassageDataValidationDialogForCompletedPigRunOnOKClick. Before logging PigRunService.endDownloadRecordedPassageDataSession response.'
        );
        console.log(response);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. downloadRecordedPassageDataValidationDialogForCompletedPigRunOnOKClick. Before logging PigRunService.endDownloadRecordedPassageDataSession exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A report manager service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Report Manager Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // Dialog properties.
    this.showDownloadRecordedPassageDataValidationDialogForCompletedPigRun =
      false;

    // At this point the existing download recorded passage data session has been ended.
    this.onCompletedPigRunDownloadRecordedPassageDataClick();
  }

  // The download recorded passage data validation dialog ok button click handler for devices.
  public async downloadRecordedPassageDataValidationDialogForDevicesOnOKClick() {
    console.log(
      'ReportManager.vue. downloadRecordedPassageDataValidationDialogForDevicesOnOKClick. Enter method.'
    );

    // At this point the existing download recorded passage data session has been ended prior to creating a new download recorded passage data session.
    console.log(
      this.validateDownloadRecordedPassageDataRequestResponseForDevices
    );

    let webApiCallSuccess = false;
    let webApiCallExceptionHandlingInstanceId: any;

    // Call the web api service method to end an existing download recorded passage data session.
    await PigRunService.endDownloadRecordedPassageDataSession(
      this.validateDownloadRecordedPassageDataRequestResponseForDevices
        .downloadRecordedPassageDataSessionId
    )
      .then((response) => {
        console.log(
          'ReportManager.vue. downloadRecordedPassageDataValidationDialogForDevicesOnOKClick. Before logging PigRunService.endDownloadRecordedPassageDataSession response'
        );
        console.log(response);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. downloadRecordedPassageDataValidationDialogForDevicesOnOKClick. Before logging PigRunService.endDownloadRecordedPassageDataSession exception'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A report manager service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Report Manager Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // Dialog properties.
    this.showDownloadRecordedPassageDataValidationDialogForDevices = false;

    // At this point the existing download recorded passage data session has been ended.
    this.onDevicesDownloadRecordedPassageDataClick();
  }

  // Component method.
  //
  // The click event handler for the completed pig run download recorded passage data q-btn control.
  public async onCompletedPigRunDownloadRecordedPassageDataClick() {
    console.log(
      'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. Enter method.'
    );

    // Validation: A completed pig run must be selected.
    if (this.selectedCompletedPigRun == '') {
      console.log(
        'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. No completed pig run selected.'
      );

      this.validationDialogMessage = 'A completed pig run must be selected.';
      this.validationDialogTitle = 'Information Message';
      this.showValidationMessageDialog = true;

      return;
    }

    // Declare an array of type number and initialize it to an empty array. This array will contain all of the uid values that participated in the pig run.
    //
    // Note the definite assignment assertion in the variable declarations (!) for launchDate and receiveDate. All uses of launchDate and receiveDate will be initialized.
    let uidArray: number[] = [];
    let launchDate!: Date;
    let receiveDate!: Date;

    let webApiCallSuccess = false;
    let webApiCallExceptionHandlingInstanceId: any;

    // Call the web api service method to get pig run detail.
    await PigRunService.getPigRun(this.selectedCompletedPigRun.value)
      .then((response) => {
        console.log(
          'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. Before logging PigRunService.getPigRun response.'
        );
        console.log(response);

        // The JSON.parse() static method parses a JSON string, constructing the JavaScript object described by the string.
        let pigRunPathObject = JSON.parse(response.data.path);

        // Iterate over the agms array property and push each uid value into the uidArray.
        for (let i = 0; i < pigRunPathObject.agms.length; i++) {
          uidArray.push(pigRunPathObject.agms[i]);
        }

        // Capture the completed pig run launch date and receive date.
        //
        // Note: The response.data.launchTime and response.data.recieveTime (misspelled column name in the PigRun table) must be converted from 'any' type to a 'Date' type here in order for the TypeScript Date api to work correctly.
        launchDate = new Date(response.data.launchTime);
        receiveDate = new Date(response.data.recieveTime);

        // Airport hack.
        console.log('Before raw response.');
        console.log(response.data.launchTime);
        console.log(response.data.recieveTime);

        launchDate = new Date(launchDate.toUTCString());
        receiveDate = new Date(receiveDate.toUTCString());

        console.log('After utc conversion');
        console.log(launchDate);
        console.log(receiveDate);

        let getTimezoneOffsetInMinutes: number = launchDate.getTimezoneOffset();

        launchDate.setUTCMinutes(
          launchDate.getUTCMinutes() - getTimezoneOffsetInMinutes
        );
        receiveDate.setUTCMinutes(
          receiveDate.getUTCMinutes() - getTimezoneOffsetInMinutes
        );

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. Before logging PigRunService.getPigRun exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A report manager service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Report Manager Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // Construct a DownloadRecordedPassageDataRequest object.
    let downloadRecordedPassageDataRequest: DownloadRecordedPassageDataRequest =
      new DownloadRecordedPassageDataRequest(launchDate, receiveDate, uidArray);

    // Validation: Validate the download recorded passage data request. This validation will succeed if the request does not have a uid value specified
    //             that is currently participating in an open download session.
    webApiCallSuccess = false;

    await PigRunService.validateDownloadRecordedPassageDataRequest(
      downloadRecordedPassageDataRequest
    )
      .then((response) => {
        console.log(
          'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. Before logging PigRunService.validateDownloadRecordedPassageDataRequest response.'
        );
        console.log(response);

        // Set the download recorded passage data validation response for completed pig run.
        //
        // Note: This object is used in the downloadRecordedPassageDataValidationDialogForCompletedPigRunOnOKClick() method in the case of validation failure.
        this.validateDownloadRecordedPassageDataRequestResponseForCompletedPigRun =
          response.data;

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. Before logging PigRunService.validateDownloadRecordedPassageDataRequest exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A report manager service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Report Manager Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // At this point the validation response is known.
    //
    // If the validation of the request has returned an invalid result, display the download recorded passage data validation dialog with a meaningful information message.
    if (
      !this.validateDownloadRecordedPassageDataRequestResponseForCompletedPigRun
        .isValidRequest
    ) {
      // Construct the validation dialog message.
      let validationDialogMessage =
        'A download recorded passage data session was started by ';
      validationDialogMessage = validationDialogMessage.concat(
        this
          .validateDownloadRecordedPassageDataRequestResponseForCompletedPigRun
          .name
      );
      validationDialogMessage = validationDialogMessage.concat(' ');
      validationDialogMessage = validationDialogMessage.concat('on');
      validationDialogMessage = validationDialogMessage.concat(' ');

      let downloadRecordedPassageDataSessionCreatedDateAsDate: Date =
        DateCommon.ConvertDateAsStringToDate(
          this
            .validateDownloadRecordedPassageDataRequestResponseForCompletedPigRun
            .createdDate
        );
      let utcMonth: number =
        downloadRecordedPassageDataSessionCreatedDateAsDate.getUTCMonth();

      switch (utcMonth) {
        case 0:
          validationDialogMessage = validationDialogMessage.concat('Jan ');
          break;

        case 1:
          validationDialogMessage = validationDialogMessage.concat('Feb ');
          break;

        case 2:
          validationDialogMessage = validationDialogMessage.concat('Mar ');
          break;

        case 3:
          validationDialogMessage = validationDialogMessage.concat('Apr ');
          break;

        case 4:
          validationDialogMessage = validationDialogMessage.concat('May ');
          break;

        case 5:
          validationDialogMessage = validationDialogMessage.concat('Jun ');
          break;

        case 6:
          validationDialogMessage = validationDialogMessage.concat('Jul ');
          break;

        case 7:
          validationDialogMessage = validationDialogMessage.concat('Aug ');
          break;

        case 8:
          validationDialogMessage = validationDialogMessage.concat('Sep ');
          break;

        case 9:
          validationDialogMessage = validationDialogMessage.concat('Oct ');
          break;

        case 10:
          validationDialogMessage = validationDialogMessage.concat('Nov ');
          break;

        case 11:
          validationDialogMessage = validationDialogMessage.concat('Dec ');
          break;
      }

      validationDialogMessage = validationDialogMessage.concat(
        downloadRecordedPassageDataSessionCreatedDateAsDate
          .getUTCDate()
          .toString()
      );
      validationDialogMessage = validationDialogMessage.concat(', ');
      validationDialogMessage = validationDialogMessage.concat(
        downloadRecordedPassageDataSessionCreatedDateAsDate
          .getUTCFullYear()
          .toString()
      );
      validationDialogMessage = validationDialogMessage.concat(' ');
      validationDialogMessage =
        validationDialogMessage.concat('utilizing AGMs = [');

      for (
        let i = 0;
        i < downloadRecordedPassageDataRequest.uidList.length;
        i++
      ) {
        validationDialogMessage = validationDialogMessage.concat(
          downloadRecordedPassageDataRequest.uidList[i].toString(10)
        );
        validationDialogMessage = validationDialogMessage.concat(', ');
      }

      validationDialogMessage = validationDialogMessage.substring(
        0,
        validationDialogMessage.length - 2
      );
      validationDialogMessage = validationDialogMessage.concat('] ');
      validationDialogMessage = validationDialogMessage.concat(
        'that is still open. Do you wish to end the current session to continue?'
      );

      // Set the value of the downloadRecordedPassageDataSessionIdToResume member to be used for resuming the download (optional).
      this.downloadRecordedPassageDataSessionIdToResume =
        this.validateDownloadRecordedPassageDataRequestResponseForCompletedPigRun.downloadRecordedPassageDataSessionId;

      // Dialog properties.
      this.validationDialogTitle = 'Download Recorded Passage Data Validation';
      this.validationDialogMessage = validationDialogMessage;
      this.showDownloadRecordedPassageDataValidationDialogForCompletedPigRun =
        true;

      return;
    }

    // Construct a DownloadRecordedPassageDataFirmwareValidationRequest object.
    let downloadRecordedPassageDataFirmwareValidationRequest: DownloadRecordedPassageDataFirmwareValidationRequest =
      new DownloadRecordedPassageDataFirmwareValidationRequest(uidArray);

    webApiCallSuccess = false;

    // Validation: Verify that each uid in the download recorded passage data request has a firmware version that fully supports it.
    await PigRunService.validateDownloadRecordedPassageDataRequestFirmware(
      downloadRecordedPassageDataFirmwareValidationRequest
    )
      .then((response) => {
        console.log(
          'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. Before logging PigRunService.validateDownloadRecordedPassageDataRequestFirmware response.'
        );
        console.log(response);

        // Set the download recorded passage data firmware validation response for completed pig run.
        this.firmwareValidationResponseDownloadRecordedPassageData =
          response.data;

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. Before logging PigRunService.validateDownloadRecordedPassageDataRequestFirmware exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A report manager service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Report Manager Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // At this point the firmware validation response is known.
    //
    // If the firmware validation for any uid associated with the download recorded passage data request has failed, display a validation dialog
    // with an information message.
    if (
      !this.firmwareValidationResponseDownloadRecordedPassageData
        .isFirmwareValidForRequest
    ) {
      // Construct the validation dialog message.
      let validationDialogMessage = 'The firmware for the AGM(s) [';

      for (
        let i = 0;
        i <
        this.firmwareValidationResponseDownloadRecordedPassageData.uidList
          .length;
        i++
      ) {
        validationDialogMessage = validationDialogMessage.concat(
          this.firmwareValidationResponseDownloadRecordedPassageData.uidList[
            i
          ].toString(10)
        );
        validationDialogMessage = validationDialogMessage.concat(', ');
      }

      validationDialogMessage = validationDialogMessage.substring(
        0,
        validationDialogMessage.length - 2
      );
      validationDialogMessage = validationDialogMessage.concat(
        '] must be upgraded to download recorded passage data.'
      );

      // Dialog properties.
      this.validationDialogTitle = 'Download Recorded Passage Data Validation';
      this.validationDialogMessage = validationDialogMessage;
      this.showValidationMessageDialog = true;

      return;
    }

    // Call the web api service method to create a download recorded passage data request.
    webApiCallSuccess = false;

    await PigRunService.downloadRecordedPassageDataRequest(
      downloadRecordedPassageDataRequest
    )
      .then((response) => {
        console.log(
          'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. Before logging PigRunService.downloadRecordedPassageDataRequest response.'
        );
        console.log(response.data);

        // Set the downloadRecordedPassageDataSessionIdToCancel value that will be used to support cancellation if required.
        this.downloadRecordedPassageDataSessionIdToCancel = response.data;

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. onCompletedPigRunDownloadRecordedPassageDataClick. Before logging PigRunService.downloadRecordedPassageDataRequest exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A report manager service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Report Manager Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // Set the download recorded passage data date range string value, this is bound to the progress table dialog.
    this.downloadRecordedPassageDataDateRangeAsString =
      this.constructDownloadRecordedPassageDataDateRangeAsString(
        launchDate,
        receiveDate
      );

    // Construct an initializeDownloadRecordedPassageDataProgressArray payload object prior to dispatching the initializeDownloadRecordedPassageDataProgressArray action.
    let initializeDownloadRecordedPassageDataProgressArray = [];

    for (let i = 0; i < uidArray.length; i++) {
      let initializeDownloadRecordedPassageDataProgressArrayElement = {
        uid: uidArray[i],
        downloadState: 'Queued Command',
        packetsReceived: 0,
        latestPassageTimeStamp: undefined,
        downloadCompleteTimeStamp: undefined,
      };

      // Push the array element into the array.
      initializeDownloadRecordedPassageDataProgressArray.push(
        initializeDownloadRecordedPassageDataProgressArrayElement
      );
    }

    // Dispatch the initializeDownloadRecordedPassageDataProgressArray action.
    this.$store.dispatch(
      'initializeDownloadRecordedPassageDataProgressArray',
      initializeDownloadRecordedPassageDataProgressArray
    );

    // Show the download recorded passage data dialog.
    this.showDownloadRecordedPassageDataDialog = true;
  }

  // Component method.
  //
  // The click event handler for the devices download recorded passage data q-btn control.
  //
  // Note: This section re-uses the input parameter variables originally implemented for the devices report for selected uid values, start date, and end date.
  //       However, initiating a devices recorded passage data download session operates independently from generating a devices report.
  public async onDevicesDownloadRecordedPassageDataClick() {
    console.log(
      'ReportManager.vue. onDevicesDownloadRecordedPassageDataClick. Enter method.'
    );

    // Validation: If the specified start date is not valid, display a validation error.
    if (!DateCommon.IsDateAsStringValid(this.startDateDevicesReport)) {
      this.validationDialogTitle = 'Download Recorded Passage Data Validation';
      this.validationDialogMessage =
        'The start date specified in the enter report parameters section is not valid.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Validation: If the specified end date is not valid, display a validation error.
    if (!DateCommon.IsDateAsStringValid(this.endDateDevicesReport)) {
      this.validationDialogTitle = 'Download Recorded Passage Data Validation';
      this.validationDialogMessage =
        'The end date specified in the enter report parameters section is not valid.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Convert the specified valid start date for devices download recorded passage data to a native Date object.
    //
    // Note: Reuse the same class member variable as the devices report.
    this.startDateDevicesReportAsDate = DateCommon.ConvertDateAsStringToDate(
      this.startDateDevicesReport
    );

    // Convert the specified valid end date for devices download recorded passage data to a native Date object.
    this.endDateDevicesReportAsDate = DateCommon.ConvertDateAsStringToDate(
      this.endDateDevicesReport
    );

    // Validation: If the start date is not before the end date, display a validation error.
    if (
      this.startDateDevicesReportAsDate.getTime() >=
      this.endDateDevicesReportAsDate.getTime()
    ) {
      this.validationDialogTitle = 'Download Recorded Passage Data Validation';
      this.validationDialogMessage =
        'The start date specified in the enter report parameters section must be before the end date.';
      this.showValidationMessageDialog = true;

      return;
    }

    // Validation: If there is not at least a single uid value selected, display a validation error.
    if (this.selectedDevicesForDevicesReport.length == 0) {
      this.validationDialogTitle = 'Download Recorded Passage Data Validation';
      this.validationDialogMessage =
        'At least one uid value must be selected in the enter report parameters section.';
      this.showValidationMessageDialog = true;

      return;
    }

    let webApiCallSuccess = false;
    let webApiCallExceptionHandlingInstanceId: any;

    // Construct a DownloadRecordedPassageDataRequest object.
    let downloadRecordedPassageDataRequest: DownloadRecordedPassageDataRequest =
      new DownloadRecordedPassageDataRequest(
        this.startDateDevicesReportAsDate,
        this.endDateDevicesReportAsDate,
        this.selectedDevicesForDevicesReport
      );

    // Validation: Verify the download recorded passage data request. This validation will succeed if the request does not have a uid value specified
    //             that is currently participating in an open download session.
    await PigRunService.validateDownloadRecordedPassageDataRequest(
      downloadRecordedPassageDataRequest
    )
      .then((response) => {
        console.log(
          'ReportManager.vue. onDevicesDownloadRecordedPassageDataClick. Before logging PigRunService.validateDownloadRecordedPassageDataRequest response.'
        );
        console.log(response);

        // Set the download recorded passage data validation response for devices.
        this.validateDownloadRecordedPassageDataRequestResponseForDevices =
          response.data;

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. onDevicesDownloadRecordedPassageDataClick. Before logging PigRunService.validateDownloadRecordedPassageDataRequest exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A report manager service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Report Manager Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // At this point the validation response is known.
    //
    // If the validation of the request has returned an invalid result, display the download recorded passage data validation dialog with a meaningful
    // information message.
    if (
      !this.validateDownloadRecordedPassageDataRequestResponseForDevices
        .isValidRequest
    ) {
      // Construct the validation dialog message.
      let validationDialogMessage =
        'A download recorded passage data session was started by ';
      validationDialogMessage = validationDialogMessage.concat(
        this.validateDownloadRecordedPassageDataRequestResponseForDevices.name
      );
      validationDialogMessage = validationDialogMessage.concat(' ');
      validationDialogMessage = validationDialogMessage.concat('on');
      validationDialogMessage = validationDialogMessage.concat(' ');

      let downloadRecordedPassageDataSessionCreatedDateAsDate: Date =
        DateCommon.ConvertDateAsStringToDate(
          this.validateDownloadRecordedPassageDataRequestResponseForDevices
            .createdDate
        );
      let utcMonth: number =
        downloadRecordedPassageDataSessionCreatedDateAsDate.getUTCMonth();

      switch (utcMonth) {
        case 0:
          validationDialogMessage = validationDialogMessage.concat('Jan ');
          break;

        case 1:
          validationDialogMessage = validationDialogMessage.concat('Feb ');
          break;

        case 2:
          validationDialogMessage = validationDialogMessage.concat('Mar ');
          break;

        case 3:
          validationDialogMessage = validationDialogMessage.concat('Apr ');
          break;

        case 4:
          validationDialogMessage = validationDialogMessage.concat('May ');
          break;

        case 5:
          validationDialogMessage = validationDialogMessage.concat('Jun ');
          break;

        case 6:
          validationDialogMessage = validationDialogMessage.concat('Jul ');
          break;

        case 7:
          validationDialogMessage = validationDialogMessage.concat('Aug ');
          break;

        case 8:
          validationDialogMessage = validationDialogMessage.concat('Sep ');
          break;

        case 9:
          validationDialogMessage = validationDialogMessage.concat('Oct ');
          break;

        case 10:
          validationDialogMessage = validationDialogMessage.concat('Nov ');
          break;

        case 11:
          validationDialogMessage = validationDialogMessage.concat('Dec ');
          break;
      }

      validationDialogMessage = validationDialogMessage.concat(
        downloadRecordedPassageDataSessionCreatedDateAsDate
          .getUTCDate()
          .toString()
      );
      validationDialogMessage = validationDialogMessage.concat(', ');
      validationDialogMessage = validationDialogMessage.concat(
        downloadRecordedPassageDataSessionCreatedDateAsDate
          .getUTCFullYear()
          .toString()
      );
      validationDialogMessage = validationDialogMessage.concat(' ');
      validationDialogMessage =
        validationDialogMessage.concat('utilizing AGMs = [');

      for (
        let i = 0;
        i < downloadRecordedPassageDataRequest.uidList.length;
        i++
      ) {
        validationDialogMessage = validationDialogMessage.concat(
          downloadRecordedPassageDataRequest.uidList[i].toString(10)
        );
        validationDialogMessage = validationDialogMessage.concat(', ');
      }

      validationDialogMessage = validationDialogMessage.substring(
        0,
        validationDialogMessage.length - 2
      );
      validationDialogMessage = validationDialogMessage.concat('] ');
      validationDialogMessage = validationDialogMessage.concat(
        'that is still open. Do you wish to end the current session to continue?'
      );

      // Set the value of the downloadRecordedPassageDataSessionIdToResume member to be used for resuming the download (optional).
      this.downloadRecordedPassageDataSessionIdToResume =
        this.validateDownloadRecordedPassageDataRequestResponseForDevices.downloadRecordedPassageDataSessionId;

      // Dialog properties.
      this.validationDialogTitle = 'Download Recorded Passage Data Validation';
      this.validationDialogMessage = validationDialogMessage;
      this.showDownloadRecordedPassageDataValidationDialogForDevices = true;

      return;
    }

    // Construct a DownloadRecordedPassageDataFirmwareValidationRequest object.
    let downloadRecordedPassageDataFirmwareValidationRequest: DownloadRecordedPassageDataFirmwareValidationRequest =
      new DownloadRecordedPassageDataFirmwareValidationRequest(
        this.selectedDevicesForDevicesReport
      );

    webApiCallSuccess = false;

    // Validation: Verify that each uid in the download recorded passage data request has a firmware version that fully supports it.
    await PigRunService.validateDownloadRecordedPassageDataRequestFirmware(
      downloadRecordedPassageDataFirmwareValidationRequest
    )
      .then((response) => {
        console.log(
          'ReportManager.vue. onDevicesDownloadRecordedPassageDataClick. Before logging PigRunService.validateDownloadRecordedPassageDataRequestFirmware response.'
        );
        console.log(response);

        // Set the download recorded passage data firmware validation response for devices.
        this.firmwareValidationResponseDownloadRecordedPassageData =
          response.data;

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. onDevicesDownloadRecordedPassageDataClick. Before logging PigRunService.validateDownloadRecordedPassageDataRequestFirmware exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A report manager service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Report Manager Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // At this point the firmware validation response is known.
    //
    // If the firmware validation for any uid associated with the download recorded passage data request has failed, display a validation dialog
    // with a meaningful information message.
    if (
      !this.firmwareValidationResponseDownloadRecordedPassageData
        .isFirmwareValidForRequest
    ) {
      // Construct the validation dialog message.
      let validationDialogMessage = 'The firmware for the AGM(s) [';

      for (
        let i = 0;
        i <
        this.firmwareValidationResponseDownloadRecordedPassageData.uidList
          .length;
        i++
      ) {
        validationDialogMessage = validationDialogMessage.concat(
          this.firmwareValidationResponseDownloadRecordedPassageData.uidList[
            i
          ].toString(10)
        );
        validationDialogMessage = validationDialogMessage.concat(', ');
      }

      validationDialogMessage = validationDialogMessage.substring(
        0,
        validationDialogMessage.length - 2
      );
      validationDialogMessage = validationDialogMessage.concat(
        '] must be upgraded to download recorded passage data.'
      );

      // Dialog properties.
      this.validationDialogTitle = 'Download Recorded Passage Data Validation';
      this.validationDialogMessage = validationDialogMessage;
      this.showValidationMessageDialog = true;

      return;
    }

    // Call the web api service method to create a download recorded passage data request.
    webApiCallSuccess = false;

    await PigRunService.downloadRecordedPassageDataRequest(
      downloadRecordedPassageDataRequest
    )
      .then((response) => {
        console.log(
          'ReportManager.vue. onDevicesDownloadRecordedPassageDataClick. Before logging PigRunService.downloadRecordedPassageDataRequest response.'
        );
        console.log(response);

        // Set the downloadRecordedPassageDataSessionIdToCancel value that will be used to support cancellation if required.
        this.downloadRecordedPassageDataSessionIdToCancel = response.data;

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A report manager service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Report Manager Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // Set the download recorded passage data date range string value, this is bound to the progress table dialog.
    this.downloadRecordedPassageDataDateRangeAsString =
      this.constructDownloadRecordedPassageDataDateRangeAsString(
        this.startDateDevicesReportAsDate,
        this.endDateDevicesReportAsDate
      );

    // Construct an initializeDownloadRecordedPassageDataProgressArray payload object prior to dispatching the initializeDownloadRecordedPassageDataProgressArray action.
    let initializeDownloadRecordedPassageDataProgressArray = [];

    for (
      let i = 0;
      i < downloadRecordedPassageDataRequest.uidList.length;
      i++
    ) {
      let initializeDownloadRecordedPassageDataProgressArrayElement = {
        uid: downloadRecordedPassageDataRequest.uidList[i],
        downloadState: 'Queued Command',
        packetsReceived: 0,
        latestPassageTimeStamp: undefined,
        downloadCompleteTimeStamp: undefined,
      };

      // Push the array element into the array.
      initializeDownloadRecordedPassageDataProgressArray.push(
        initializeDownloadRecordedPassageDataProgressArrayElement
      );
    }

    // Dispatch the initializeDownloadRecordedPassageDataProgressArray action.
    this.$store.dispatch(
      'initializeDownloadRecordedPassageDataProgressArray',
      initializeDownloadRecordedPassageDataProgressArray
    );

    // Show the download recorded passage data dialog.
    this.showDownloadRecordedPassageDataDialog = true;
  }

  // Component method.
  //
  // The resume button click handler for the following dialog controls:
  //     - showDownloadRecordedPassageDataValidationDialogForCompletedPigRun
  //     - showDownloadRecordedPassageDataValidationDialogForDevices
  public async downloadRecordedPassageDataResumeSessionOnResumeClick() {
    console.log(
      'ReportManager.vue. downloadRecordedPassageDataResumeSessionOnResumeClick. Enter method.'
    );
    console.log(
      'ReportManager.vue. downloadRecordedPassageDataResumeSessionOnResumeClick. downloadRecordedPassageDataSessionId = [' +
        this.downloadRecordedPassageDataSessionIdToResume +
        ']'
    );

    // The web api response (response.data).
    let getDownloadRecordedPassageDataRequestDetailResponse!: any;

    let webApiCallSuccess = false;
    let webApiCallExceptionHandlingInstanceId: any;

    // Get the download recorded passage data request detail for the specified DownloadRecordedPassageDataSessionId value.
    await PigRunService.getDownloadRecordedPassageDataRequestDetail(
      this.downloadRecordedPassageDataSessionIdToResume
    )
      .then((response) => {
        console.log(
          'ReportManager.vue. downloadRecordedPassageDataResumeSessionOnResumeClick. Before logging PigRunService.getDownloadRecordedPassageDataRequestDetail response.'
        );
        console.log(response);

        getDownloadRecordedPassageDataRequestDetailResponse = response.data;

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. downloadRecordedPassageDataResumeSessionOnResumeClick. Before logging PigRunService.getDownloadRecordedPassageDataRequestDetail exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // Construct an initializeDownloadRecordedPassageDataProgressArray payload object prior to dispatching the initializeDownloadRecordedPassageDataProgressArray action.
    let initializeDownloadRecordedPassageDataProgressArray = [];

    for (
      let i = 0;
      i <
      getDownloadRecordedPassageDataRequestDetailResponse
        .downloadRecordedPassageDataSessionRequestList.length;
      i++
    ) {
      // Map the DownloadRecordedPassageDataSessionRequest.DownloadState code values to their corresponding descriptions using the following mapping:
      //     QC = Queued Command
      //     D  = Downloading
      //     DC = Download Complete
      let downloadStateCode: string =
        getDownloadRecordedPassageDataRequestDetailResponse
          .downloadRecordedPassageDataSessionRequestList[i].downloadState;
      let downloadStateDescription!: string;

      if (downloadStateCode == 'QC') {
        downloadStateDescription = 'Queued Command';
      }

      if (downloadStateCode == 'D') {
        downloadStateDescription = 'Downloading';
      }

      if (downloadStateCode == 'DC') {
        downloadStateDescription = 'Download Complete';
      }

      let initializeDownloadRecordedPassageDataProgressArrayElement = {
        uid: getDownloadRecordedPassageDataRequestDetailResponse
          .downloadRecordedPassageDataSessionRequestList[i].uId,
        downloadState: downloadStateDescription,
        packetsReceived:
          getDownloadRecordedPassageDataRequestDetailResponse
            .downloadRecordedPassageDataSessionRequestList[i].packetsReceived,
        latestPassageTimeStamp:
          getDownloadRecordedPassageDataRequestDetailResponse
            .downloadRecordedPassageDataSessionRequestList[i]
            .latestPassageTimeStamp,
        downloadCompleteTimeStamp:
          getDownloadRecordedPassageDataRequestDetailResponse
            .downloadRecordedPassageDataSessionRequestList[i]
            .downloadCompleteTimeStamp,
      };

      // Push the object into the array.
      initializeDownloadRecordedPassageDataProgressArray.push(
        initializeDownloadRecordedPassageDataProgressArrayElement
      );
    }

    // The getDownloadRecordedPassageDataRequestDetailResponse object contains the associated DownloadRecordedPassageDataSessionRequest records where each cid value is a foreign key to the related CommandQueue record. The
    // CommandQueue.FileNamesStartDate and CommandQueue.FileNamesEndDate values will be the same for each device participating in the download recorded passage request. Simply use the first value found to get the required
    // CommandQueue record values.
    //
    // Note: There will be at least one associated DownloadRecordedPassageDataSessionRequest record for a given session.
    let cid: number =
      getDownloadRecordedPassageDataRequestDetailResponse
        .downloadRecordedPassageDataSessionRequestList[0].cId;

    // The web api response (response.data).
    let getCommandQueueDetailResponse!: any;

    // Get the CommandQueue record detail.
    webApiCallSuccess = false;

    await PigRunService.getCommandQueueDetail(cid)
      .then((response) => {
        console.log(
          'ReportManager.vue. downloadRecordedPassageDataResumeSessionOnResumeClick. Before logging PigRunService.getCommandQueueDetail response.'
        );
        console.log(response);

        getCommandQueueDetailResponse = response.data;

        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. downloadRecordedPassageDataResumeSessionOnResumeClick. Before logging PigRunService.getCommandQueueDetail exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // Determine the original recorded passage data date range for the original request. These two values are in the getCommandQueueDetailResponse object.
    //
    // Note: These dates are being returned from the web api, versus input from the user via a gui control, and will have the format = [yyyy-mm-dd hh:mm:ss.000000], if not undefined.
    let downloadRecordedPassageDataRequestStartDateAsString:
      | string
      | undefined = getCommandQueueDetailResponse.fileNamesStartDate;
    let downloadRecordedPassageDataRequestEndDateAsString: string | undefined =
      getCommandQueueDetailResponse.fileNamesEndDate;

    let downloadRecordedPassageDataRequestStartDateAsDate!: Date;
    let downloadRecordedPassageDataRequestEndDateAsDate!: Date;

    // Strip off the trailing [.000000] characters.
    if (downloadRecordedPassageDataRequestStartDateAsString != undefined) {
      let periodIndex: number =
        downloadRecordedPassageDataRequestStartDateAsString.lastIndexOf('.');

      if (periodIndex != -1) {
        downloadRecordedPassageDataRequestStartDateAsDate =
          DateCommon.ConvertDateAsStringToDate(
            downloadRecordedPassageDataRequestStartDateAsString.substring(
              0,
              periodIndex
            )
          );
      }
    }

    // Strip off the trailing [.000000] characters.
    if (downloadRecordedPassageDataRequestEndDateAsString != undefined) {
      let periodIndex: number =
        downloadRecordedPassageDataRequestEndDateAsString.lastIndexOf('.');

      if (periodIndex != -1) {
        downloadRecordedPassageDataRequestEndDateAsDate =
          DateCommon.ConvertDateAsStringToDate(
            downloadRecordedPassageDataRequestEndDateAsString.substring(
              0,
              periodIndex
            )
          );
      }
    }

    console.log(
      'ReportManager.vue. downloadRecordedPassageDataResumeSessionOnResumeClick. Before logging cid value.'
    );
    console.log(cid);

    // Set the download recorded passage data date range string value, this is bound to the progress table dialog.
    if (
      downloadRecordedPassageDataRequestStartDateAsDate != undefined &&
      downloadRecordedPassageDataRequestEndDateAsDate != undefined
    ) {
      this.downloadRecordedPassageDataDateRangeAsString =
        this.constructDownloadRecordedPassageDataDateRangeAsString(
          downloadRecordedPassageDataRequestStartDateAsDate,
          downloadRecordedPassageDataRequestEndDateAsDate
        );
    }

    // Dispatch the initializeDownloadRecordedPassageDataProgressArray action.
    this.$store.dispatch(
      'initializeDownloadRecordedPassageDataProgressArray',
      initializeDownloadRecordedPassageDataProgressArray
    );

    // Ensure the following validation dialog controls are not visible:
    //     - showDownloadRecordedPassageDataValidationDialogForCompletedPigRun
    //     - showDownloadRecordedPassageDataValidationDialogForDevices
    this.showDownloadRecordedPassageDataValidationDialogForCompletedPigRun =
      false;
    this.showDownloadRecordedPassageDataValidationDialogForDevices = false;

    // Ensure the download recorded passage data dialog is visible.
    this.showDownloadRecordedPassageDataDialog = true;
  }

  // Method used to cancel an in progress download recorded passage data session.
  public async cancelDownloadRecordedPassageDataOnClick() {
    console.log(
      'ReportManager.vue. cancelDownloadRecordedPassageDataOnClick. Enter method.'
    );
    console.log(
      'ReportManager.vue. Before logging this.downloadRecordedPassageDataSessionIdToCancel'
    );
    console.log(this.downloadRecordedPassageDataSessionIdToCancel);

    // Cancel the in progress download recorded passage data request.
    let webApiCallSuccess = false;
    let webApiCallExceptionHandlingInstanceId: any;

    // Call the web api method to cancel an in progress download recorded passage data session.
    await PigRunService.cancelDownloadRecordedPassageDataRequest(
      this.downloadRecordedPassageDataSessionIdToCancel
    )
      .then((response) => {
        // Set the webApiCallSuccess value.
        webApiCallSuccess = true;
      })
      .catch((exception) => {
        console.log(
          'ReportManager.vue. cancelDownloadRecordedPassageDataOnClick. Before logging PigRunService.cancelDownloadRecordedPassageDataRequest exception.'
        );
        console.error(exception);

        // Set the webApiCallSuccess value.
        webApiCallSuccess = false;

        // If the exception.response.data property is not undefined, an exception handling instance id has been returned from the web api.
        if (exception.response.data != undefined) {
          webApiCallExceptionHandlingInstanceId = exception.response.data;
        }
      });

    // If a web api service exception has occurred.
    if (!webApiCallSuccess) {
      // Display a web api service exception message with its associated handling instance id.
      this.validationDialogMessage =
        'A service exception has occurred. LogId = [' +
        webApiCallExceptionHandlingInstanceId +
        ']';
      this.validationDialogTitle = 'Service Exception';
      this.showValidationMessageDialog = true;

      return;
    }

    // Close the download recorded passage data dialog.
    this.showDownloadRecordedPassageDataDialog = false;
  }

  // Method used to construct the date range string displayed on the download recorded passage data progress dialog in the following format:
  //     - Download Range (UTC): 2023-11-15 15:50:00 - 2023-11-15 15:58:00 (hard-coded example).
  public constructDownloadRecordedPassageDataDateRangeAsString(
    startDate: Date,
    endDate: Date
  ): string {
    let returnString = 'Download Range (UTC): ';

    // Start year.
    returnString = returnString.concat(startDate.getUTCFullYear().toString());
    returnString = returnString.concat('-');

    // Start month.
    if (startDate.getUTCMonth() + 1 < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(
        (startDate.getUTCMonth() + 1).toString() + '-'
      );
    } else {
      returnString = returnString.concat(
        (startDate.getUTCMonth() + 1).toString() + '-'
      );
    }

    // Start day.
    if (startDate.getUTCDate() < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(
        startDate.getUTCDate().toString() + ' '
      );
    } else {
      returnString = returnString.concat(
        startDate.getUTCDate().toString() + ' '
      );
    }

    // Start hour.
    if (startDate.getUTCHours() < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(
        startDate.getUTCHours().toString() + ':'
      );
    } else {
      returnString = returnString.concat(
        startDate.getUTCHours().toString() + ':'
      );
    }

    // Start minute.
    if (startDate.getUTCMinutes() < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(
        startDate.getUTCMinutes().toString() + ':'
      );
    } else {
      returnString = returnString.concat(
        startDate.getUTCMinutes().toString() + ':'
      );
    }

    // Start second.
    if (startDate.getUTCSeconds() < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(startDate.getUTCSeconds().toString());
    } else {
      returnString = returnString.concat(startDate.getUTCSeconds().toString());
    }

    returnString = returnString.concat(' - ');

    // End year.
    returnString = returnString.concat(endDate.getUTCFullYear().toString());
    returnString = returnString.concat('-');

    // End month.
    if (endDate.getUTCMonth() + 1 < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(
        (endDate.getUTCMonth() + 1).toString() + '-'
      );
    } else {
      returnString = returnString.concat(
        (endDate.getUTCMonth() + 1).toString() + '-'
      );
    }

    // End day.
    if (endDate.getUTCDate() < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(endDate.getUTCDate().toString() + ' ');
    } else {
      returnString = returnString.concat(endDate.getUTCDate().toString() + ' ');
    }

    // End hour.
    if (endDate.getUTCHours() < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(
        endDate.getUTCHours().toString() + ':'
      );
    } else {
      returnString = returnString.concat(
        endDate.getUTCHours().toString() + ':'
      );
    }

    // End minute.
    if (endDate.getUTCMinutes() < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(
        endDate.getUTCMinutes().toString() + ':'
      );
    } else {
      returnString = returnString.concat(
        endDate.getUTCMinutes().toString() + ':'
      );
    }

    // End second.
    if (endDate.getUTCSeconds() < 10) {
      returnString = returnString.concat('0');
      returnString = returnString.concat(endDate.getUTCSeconds().toString());
    } else {
      returnString = returnString.concat(endDate.getUTCSeconds().toString());
    }

    return returnString;
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------
  // Download recorded passage data section - end.
  // ----------------------------------------------------------------------------------------------------------------------------------------------
}
